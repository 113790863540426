import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import MasterSidebar from '@rd-web-markets/shared/dist/util/MasterSidebar';
import { companyListsService } from '@rd-web-markets/shared/dist/services/lists/company_list.service';
import claimGroupService from '@rd-web-markets/shared/dist/services/claim_group.service';
import myClaimsService from '@services/claim/my_claims.service';
import { myTasksService }from '@services/task_management/myTasks.service';
import { companiesService } from '@services/task_management/companies.service';
import useRecentVisits from '@rd-web-markets/shared/dist/hooks/useRecentVisits';
import RecentActivity from '@rd-web-markets/shared/dist/landing/RecentActivity';
import MyClaims from '@rd-web-markets/shared/dist/landing/MyClaims';
import MyCompanies from '@rd-web-markets/shared/dist/landing/MyCompanies';
import MyTasks from '@rd-web-markets/shared/dist/landing/MyTasks';
import CommonActions from '@rd-web-markets/shared/dist/landing/CommonActions';
import MyTasksBe from '@rd-web-markets/shared/dist/landing/MyTasksBE';

const LandingPage = ({ accountType }) => {
	const [loading, setLoading] = useState(false);
	const user = useSelector((state) => state.auth.user);
	const { recentClaimGroupIds, recentCompanyIds } = useRecentVisits();
	const [companies] = companyListsService.useGetAll('', setLoading);
	const [taskCompanies] = companiesService.useFetchAllCompanies(setLoading);
	const [claimGroups] = claimGroupService.useFetchAllClaimGroups(setLoading);
	const [claims] = myClaimsService.useFetchAllClaims(setLoading);
	const [tasks] = myTasksService.useFetchAllTasks(setLoading);
	const [userTasks, setUserTasks] = useState(user.claim_group_tasks_count);

	const isBelgium = () => {
		return process.env.PUBLIC_URL === '/be';
	}


	return (
		<>
			<MasterSidebar accountType={accountType} />
			<div className='p-3'>
				<h4 className='mb-3'>Welcome, {user?.first_name}</h4>

				<Row>
					<Col lg={8}>
						<RecentActivity
							claimGroups={claimGroups}
							companies={companies}
							recentClaimGroupIds={recentClaimGroupIds}
							recentCompanyIds={recentCompanyIds}
							accountType={accountType}
						/>
					</Col>
					<Col lg={4}>
						{isBelgium() ? <MyTasksBe tasks={userTasks} /> : <MyTasks tasks={tasks}/>}
					</Col>
				</Row>
				<Row className='mt-4'>
					<Col lg={4}>
						<MyClaims claims={claims} accountType={accountType} loading={loading} />
					</Col>
					<Col lg={4}>
						<MyCompanies companies={companies} taskCompanies={taskCompanies} claimGroups={claimGroups} tasks={tasks} />
					</Col>
					<Col lg={4}>
						<CommonActions accountType={accountType} />
					</Col>
				</Row>
			</div>
		</>
	);
}

export default LandingPage;
