import { CHARACTER_PUNCTUATION_SPACE, findCommonDomAncestor, getElementOrParentElementIfTextNode, surroundTextNodesWithCharacters } from '../util/domUtils';
const COMMENT_START_TAG_SYMBOL = '\u03A9';
const COMMENT_END_TAG_SYMBOL = '\u03A3';
const COMMENT_TAG_TYPE = 'span';

// Spans can work in all situations - given that they are placed as close to the text as possible.
const commentEndTag = function () {
  let tagType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : COMMENT_TAG_TYPE;
  return `</${tagType}>`;
};
// const UNIQUE_DOM_EL_DATA_ATTRIBUTE_NAME = 'data-aym-el-id'

const commentStartTag = function (newCommentSpanId) {
  let tagType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : COMMENT_TAG_TYPE;
  return `<${tagType} class="aym-inline-section" data-aym-comment-id="${newCommentSpanId}">`;
};

/**
 * Changes the space before a new comment span to different UTF-8 character that represents the same amount of whitespace.
 * This is because the regular space character is not visible in the browser and in the PDF.
 * TinyMCE makes it visible in the editor, but the browser hides it, and if we have 2 comment spans separated
 * by a regular space - they look like one comment span.
 * 
 * @param {*} content 
 * @param {*} openTagPos 
 * @returns 
 */
function fixSpaceBeforeCommentSpan(content, openTagPos) {
  if (content.substring(0, openTagPos) === ' ') {
    content = `${CHARACTER_PUNCTUATION_SPACE}${content.substring(openTagPos)}`;
  }
  return content;
}
const getCategoryContentWithCommentSpansWhenAnchorAndFocusTextNodesAreTheSame = (content, openTagPos, closeTagPos, newCommentSpanId) => {
  content = fixSpaceBeforeCommentSpan(content, openTagPos);
  content = `${content.substring(0, openTagPos)}${COMMENT_START_TAG_SYMBOL}${content.substring(openTagPos, closeTagPos)}${COMMENT_END_TAG_SYMBOL}${content.substring(closeTagPos)}`;
  return content;
};
const modifyHtmlWhenAnchorAndFocusAreTextNodesWithSameParent = (anchorNode, focusNode, focusElement, openTagPos, closeTagPos, newCommentSpanId) => {
  // Both start and end node are textnodes, which means that we can just simple node replacement
  // To use node replacement we need to make the text in the spans HTML.    
  let anchorText = anchorNode.textContent;
  let focusText = focusNode.textContent;
  const isFocusAfterAnchor = focusNode.compareDocumentPosition(anchorNode) === Node.DOCUMENT_POSITION_PRECEDING;
  if (anchorNode.isSameNode(focusNode)) {
    const newContent = getCategoryContentWithCommentSpansWhenAnchorAndFocusTextNodesAreTheSame(anchorNode.textContent, openTagPos, closeTagPos, newCommentSpanId);
    anchorNode.replaceWith(document.createTextNode(newContent));
  } else {
    let newAnchorText = '';
    let newFocusText = '';
    if (isFocusAfterAnchor) {
      anchorText = fixSpaceBeforeCommentSpan(anchorText, openTagPos);
      newAnchorText = `${anchorText.substring(0, openTagPos)}${COMMENT_START_TAG_SYMBOL}${anchorText.substring(openTagPos)}`;
      newFocusText = `${focusText.substring(0, closeTagPos)}${COMMENT_END_TAG_SYMBOL}${focusText.substring(closeTagPos)}`;
    } else {
      newAnchorText = `${anchorText.substring(0, closeTagPos)}${COMMENT_END_TAG_SYMBOL}${anchorText.substring(closeTagPos)}`;
      focusText = fixSpaceBeforeCommentSpan(focusText, openTagPos);
      newFocusText = `${focusText.substring(0, openTagPos)}${COMMENT_START_TAG_SYMBOL}${focusText.substring(openTagPos)}`;
    }
    focusElement.replaceChild(document.createTextNode(newAnchorText), anchorNode);
    focusElement.replaceChild(document.createTextNode(newFocusText), focusNode);
  }
};
const modifyHtmlWhenAnchorAndFocusElementsAreDifferent = (anchorNode, focusNode, selectionFirstElementPosition, selectionFinalElementPosition) => {
  const commonAncestor = findCommonDomAncestor(anchorNode, focusNode);
  surroundTextNodesWithCharacters(commonAncestor, anchorNode, selectionFirstElementPosition, focusNode, selectionFinalElementPosition, COMMENT_START_TAG_SYMBOL, COMMENT_END_TAG_SYMBOL);
};

/**
 * Receives an html dom element, whose innerHTML is the content of a e.g.: report template category or project report slice, etc.
 * The HTML needs to have comment location markers - e.g. <div>Some {COMMENT_START_TAG_SYMBOL} text {COMMENT_END_TAG_SYMBOL}</div>.
 * Those symbols will be replaced with comment tags - e.g. <div>Some <span comment stuff...> text </span></div>
 * This span will has unique id which is used to identify the comment thread. This span will have CSS applied to it - to make it obvious that the
 * content inside of it has comments.
 * 
 * @param {*} parentElement 
 * @param {*} commentThreadId 
 * @param {*} tagType 
 * @returns 
 */
const addCommentStartAndEndTags = (parentElement, commentThreadId, tagType) => {
  // The code below makes sure we add the tagType comment tag (section) unless it falls inside a paragraph - in which case we add a span.
  // We can't have sections in paragraphs.
  let finalContent = '';
  const newContent = parentElement.innerHTML;
  for (let i = 0; i < newContent.length; i++) {
    if (COMMENT_START_TAG_SYMBOL === newContent[i]) {
      finalContent += commentStartTag(commentThreadId, tagType);
    } else if (COMMENT_END_TAG_SYMBOL === newContent[i]) {
      finalContent += commentEndTag(tagType);
    } else {
      finalContent += newContent[i];
    }
  }
  return finalContent;
};

/**
 * Finds the places to add the spans and adds the spans
 * If anchorElement, focusElement and commonAncestor are the same, then it is ok to get the text content
 * of the ancestor and add an opening span at the start of the selection and a closing span at the end.
 * For example - || show where the user selection starts and ends:
 * 
 *  <p>asd||asd<span>asd</span>qwe||qwe</span>
 *
 * even though there could be DOM elements in-between the start and end of the selection, adding the opening
 * and closing tags for the comment won't break the HTML.
 * 
 * Returns the passed category object, but with updated HTML content.
 */
export const createCommentSpans = function (parentElement, commentTextSelectionData, commentThreadId) {
  let tagType = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : COMMENT_TAG_TYPE;
  const anchorNode = commentTextSelectionData.getRangeAt(0).startContainer;
  const focusNode = commentTextSelectionData.getRangeAt(0).endContainer;
  const anchorElement = getElementOrParentElementIfTextNode(anchorNode);
  const focusElement = getElementOrParentElementIfTextNode(focusNode);
  const openTagPos = commentTextSelectionData.getRangeAt(0).startOffset;
  const closeTagPos = commentTextSelectionData.getRangeAt(0).endOffset;
  if (anchorElement.isSameNode(focusElement)) {
    // modifies the htmlContent variable by modifying the htmlContent DOM children
    modifyHtmlWhenAnchorAndFocusAreTextNodesWithSameParent(anchorNode, focusNode, focusElement, openTagPos, closeTagPos, commentThreadId);
  } else {
    modifyHtmlWhenAnchorAndFocusElementsAreDifferent(anchorNode, focusNode, openTagPos, closeTagPos);
  }
  const finalHtmlContent = addCommentStartAndEndTags(parentElement, commentThreadId, tagType);
  return finalHtmlContent;
};