import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
const projectClaimTemplateCategoriesService = {
  update(projectId, category, categoryIndex, taskIndex) {
    return fetch(`/api/claim_project_reports/${projectId}/claim_template_categories/${categoryIndex}`, {
      ...request.put,
      body: JSON.stringify({
        category: category,
        task_index: taskIndex
      })
    });
  }
};
export default projectClaimTemplateCategoriesService;