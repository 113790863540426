import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
const projectBusinessUnitsService = {
  delete(reportId, businessUnitId) {
    return fetch(`/api/claim_project_reports/${reportId}/project_business_units/${businessUnitId}`, request.delete);
  },
  create(reportId, project_business_unit) {
    return fetch(`/api/claim_project_reports/${reportId}/project_business_units`, {
      ...request.post,
      body: JSON.stringify({
        project_business_unit
      })
    });
  }
};
export default projectBusinessUnitsService;