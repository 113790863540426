import React from 'react';
import { Card, Form, Row, Col } from 'react-bootstrap';
import { Button } from '@rd-web-markets/shared/dist/util/buttons';

const AffiliatedCompaniesForm = ({affiliatedCompany, handleChange, handleSubmit, loading, closeForm}) => {
  return (
    <Card>
      <Card.Body>
        <Form>
          <Form.Group className='mb-2' as={Row}>
            <Form.Label column="md" md={3}>
              Company's Name*
            </Form.Label>
            <Col>
              <Form.Control
                value={affiliatedCompany.name}
                name="name"
                onChange={handleChange}
                size="md"
                type="text"
                placeholder="Company Name"
              />
            </Col>
          </Form.Group>
          <Form.Group className='mb-2' as={Row}>
            <Form.Label column="md" md={3}>
              <span className="font-weight-bold">Legally billing address: </span>
            </Form.Label>
          </Form.Group>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label>Street</Form.Label>
              <Form.Control
                type="text"
                placeholder="Street"
                onChange={handleChange}
                name="street"
                value={affiliatedCompany.street}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label>House Number</Form.Label>
              <Form.Control
                type="text"
                onChange={handleChange}
                name="house_number"
                placeholder="House Number"
                value={affiliatedCompany.house_number}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label>Additional House Number Details</Form.Label>
              <Form.Control
                type="text"
                name="house_number_suppliment"
                onChange={handleChange}
                placeholder="Additional House Number Details"
                value={affiliatedCompany.house_number_suppliment}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label>Location</Form.Label>
              <Form.Control
                type="text"
                placeholder="Location"
                onChange={handleChange}
                name="location"
                value={affiliatedCompany.location}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label>Post Code</Form.Label>
              <Form.Control
                type="text"
                onChange={handleChange}
                name="postcode"
                placeholder="Postcode"
                value={affiliatedCompany.postcode}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label>Additional Address Details</Form.Label>
              <Form.Control
                type="text"
                name="address_supliment"
                onChange={handleChange}
                placeholder="Additional Address Details"
                value={affiliatedCompany.address_supliment}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} className="col-md-6" controlId="formGridEmail">
              <Form.Label>Country</Form.Label>
              <Form.Control
                as="select"
                value={affiliatedCompany.country}
                name="country"
                onChange={handleChange}
                size="md"
              >
                <option value={null}></option>
                <option value="germany">Germany</option>
                <option value="italy">Italy</option>
                <option value="usa">USA</option>
                <option value="mexcico">Mexico</option>
                <option value="russia">Russia</option>
              </Form.Control>
            </Form.Group>

            { affiliatedCompany.country === 'germany' &&
              <Form.Group as={Col} controlId="formGridPassword">
                <Form.Label>Federal State</Form.Label>
                <Form.Control
                  as="select"
                  value={affiliatedCompany.federal_state}
                  name="federal_state"
                  onChange={handleChange}
                  size="md"
                >
                    <option value={null}></option>
                    <option value="baden_wuerttemberg">Baden-Wuerttemberg</option>
                    <option value="bayern">Bayern</option>
                    <option value="berlin">Berlin</option>
                    <option value="brandenburg">Brandenburg</option>
                    <option value="bremen">Bremen</option>
                    <option value="hamuburg">Hamburg</option>
                    <option value="hesse">Hesse</option>
                    <option value="mecklenburg_vorpommern">Mecklenburg-Vorpommern</option>
                    <option value="lower_saxony">Lower Saxony</option>
                    <option value="north_rhine_westphalia">North Rhine-Westphalia</option>
                    <option value="rheinland_pfalz">Rheinland-Pfalz</option>
                    <option value="saarland">Saarland</option>
                    <option value="saxony">Saxony</option>
                    <option value="saxony_anhalt">Saxony-Anhalt</option>
                    <option value="schleswig_holstein">Schleswig-Holstein</option>
                    <option value="thuringia">Thuringia</option>
                </Form.Control>
              </Form.Group>
            }
          </Row>
          { affiliatedCompany.country === 'germany' &&
            <Row className="mb-3">
              <Form.Group as={Col} md={6} controlId="formGridEmail">
                <Form.Label>Legal Form</Form.Label>
                <Form.Control
                  as="select"
                  value={affiliatedCompany.legal_form}
                  name="legal_form"
                  onChange={handleChange}
                  size="md"
                >
                  <option value={null}></option>
                  <option value="aktiengesellschaft">Aktiengesellschaft (AG)</option>
                  <option value="aktiengesellschaft_kg">Aktiengesellschaft und Co. KG (AG u. Co. KG)</option>
                  <option value="aktiengesellschaft_ohg">Aktiengesellschaft und Co. OHG (AG & Co. OHG)</option>
                  <option value="kapitalgesellschaft">Ausländische Rechtsform die einer Kapitalgesellschaft entspricht</option>
                  <option value="personengesellschaft">Ausländische Rechtsform die einer Personengesellschaft entspricht</option>
                  <option value="eingetragene_gennosenchaft">Eingetragene Genossenschaft (eG)</option>
                  <option value="eingetragener_verein">Eingetragener Verein (e.V.)</option>
                  <option value="einzelunternehmer">Einzelunternehmer</option>
                  <option value="juristische_person">Juristische Person des Öffentlichen Rechts</option>
                  <option value="europäische_gesellschaft">Europäische Gesellschaft (SE)</option>
                  <option value="europäische_wirtschaftliche">Europäische Wirtschaftliche Interessenvereinigung (EWIV)</option>
                  <option value="gesellschaft_haftung">Gesellschaft mit beschränkter Haftung (GmbH)</option>
                  <option value="gesellschaft_haftung_kg">Gesellschaft mit beschränkter Haftung und Co. KG (GmbH & Co. KG)</option>
                  <option value="gesellschaft_haftung_ohg">Gesellschaft mit beschränkter Haftung und Co. OHG (GmbH & Co. OHG)</option>
                  <option value="gbr">Gesellschaft des bürgerlichen Rechts (GbR)</option>
                  <option value="kommanditgesellschaft">Kommanditgesellschaft (KG)</option>
                  <option value="kommanditgesellschaft_afu_aktien">Kommanditgesellschaft auf Aktien (KGaA)</option>
                  <option value="ohg">Offene Handelsgesellschaft (OHG)</option>
                  <option value="partnerschaft">Partnerschaft</option>
                  <option value="sonstige_ausländische_rechtsform">Sonstige ausländische Rechtsform</option>
                  <option value="sonstige_personengesellschaft">Sonstige Personengesellschaft</option>
                </Form.Control>
              </Form.Group>
            </Row>
          }
          <Form.Group className='mb-2' as={Row}>
            <Form.Label column="md" md={10}>
              If the affiliated company also submits applications according to 6 FZulG, the tax number must be entered here. Would you like to provide a tax number ?
            </Form.Label>
            <Col md={2}>
              <Form.Control
                as="select"
                value={affiliatedCompany.has_tax_number}
                name="has_tax_number"
                onChange={handleChange}
                size="md"
              >
                <option value={null}></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Form.Control>
            </Col>
          </Form.Group>
          {affiliatedCompany.has_tax_number?.toString() === 'true' && 
            <Form.Group className='mb-2' as={Row}>
              <Form.Label column="md" md={3}>
                State of the Tax Office
              </Form.Label>
              <Col>
                <Form.Control
                  as="select"
                  value={affiliatedCompany.state_of_tax_office}
                  name="state_of_tax_office"
                  onChange={handleChange}
                  size="md"
                >
                    <option value={null}></option>
                    <option value="baden_wuerttemberg">Baden-Wuerttemberg</option>
                    <option value="bayern">Bayern</option>
                    <option value="berlin">Berlin</option>
                    <option value="brandenburg">Brandenburg</option>
                    <option value="bremen">Bremen</option>
                    <option value="hamuburg">Hamburg</option>
                    <option value="hesse">Hesse</option>
                    <option value="mecklenburg_vorpommern">Mecklenburg-Vorpommern</option>
                    <option value="lower_saxony">Lower Saxony</option>
                    <option value="north_rhine_westphalia">North Rhine-Westphalia</option>
                    <option value="rheinland_pfalz">Rheinland-Pfalz</option>
                    <option value="saarland">Saarland</option>
                    <option value="saxony">Saxony</option>
                    <option value="saxony_anhalt">Saxony-Anhalt</option>
                    <option value="schleswig_holstein">Schleswig-Holstein</option>
                    <option value="thuringia">Thuringia</option>
                </Form.Control>
              </Col>
            </Form.Group>
          }
        </Form>
      </Card.Body>
      <Card.Footer>
        <Row className="justify-content-end">
          <Button
            onClick={() => handleSubmit()}
            icon='save'
            iconPosition={'left'}
            loading={loading}
            variant="primary">
            Save
          </Button>
          <Button
            className="ms-2"
            onClick={() => closeForm()}
            loading={loading}
            variant="light">
            Close Form
          </Button>
        </Row>
      </Card.Footer>
    </Card>
  )
}

export default AffiliatedCompaniesForm
