export function disabledBecauseOfClaimState(user, object) {
  // if there is an active change set
  const activeChangeSet = object?.active_change_set || object?.claim?.active_change_set;
  if (activeChangeSet) {
    // if it's finalized - disable element!
    if (activeChangeSet.finalized_at) {
      return true;
    } else if (
    /// ,.. or if the user is not the assigned company admin and not an admin account type - disable element!
    user.id !== object?.company?.admin?.id && user.id !== object?.claim?.company?.admin?.id && user.account_type !== 'admin') {
      return true;
    }
  }
}
;