import React, { useState, useEffect } from 'react'
import { Card, Tab, Tabs, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Button, CancelButton } from '@rd-web-markets/shared/dist/util/buttons';
import { UserService } from '@rd-web-markets/shared/dist/services/user.service';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';

const TaskLists = ({ task, setTask }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [key, setKey] = useState('comments');
  const [users, setUsers] = useState();
  const user = useSelector((state) => state.auth.user);
  const [comment, setComment] = useState('');

  const dateTimeOptions = {
    hour: 'numeric',
    minute: 'numeric',
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  };

  useEffect(() => {
    (async () => { // fetch users
      try {
        const response = await UserService.all('admin');
        setUsers(response.users);
      } catch(e) {
        dispatch(handleError(e))
      }
    })();
  }, [dispatch]);

  const addComment = () => {
    const id = task.comments[task.comments.length - 1].id + 1;
    task.comments.push({ id, created_at: new Date(), author: user.name, text: comment });
    setTask({ ...task, comments: task.comments });
    setComment('');
  };

  const addAttachment = () => {
    const id = task.attachments[task.attachments.length - 1].id + 1;
    task.attachments.push({ id, created_at: new Date(), author: user.name, link: 'link_to_attachment' + id });
    setTask({ ...task, attachments: task.attachments });
  };

  return (
    <Card.Body>
      <Tabs onSelect={(e) => setKey(e)} activeKey={key} defaultActiveKey={'comments'} transition={false} className='mb-3'>
        <Tab eventKey={'comments'} title={'comments'}>
          {task.comments.map((comment) => {
            return (
              <div key={comment.id}>
                <Form.Group as={Row} style={{marginBottom: 0}}>
                  <Form.Label column='md'>
                    <span className='material-icons'>account_circle</span>{users.find((user) => user.id === comment.user).name}
                  </Form.Label>
                </Form.Group>
                <Form.Group className='mb-2' as={Row}>
                  <Form.Label column='md' md={1}>
                    {Intl.DateTimeFormat(window.AYMING_MARKET_LOCALE, dateTimeOptions).format(new Date(comment.created_at))}
                  </Form.Label>
                  <Form.Label column='md' md={10}>
                    {comment.text}
                  </Form.Label>
                </Form.Group>
              </div>
            );
          })}
          <Form.Group className='mb-2' as={Row}>
            <Form.Label column='md'>
              <span className='material-icons'>account_circle</span>{user.name}
            </Form.Label>
          </Form.Group>
          <Form.Group as={Row} className='col-md-8'>
            <Form.Control
              as='textarea'
              onChange={(event) => setComment(event.target.value)}
              size='md'
              type='text'
              value={comment}
              style={{ resize: 'none' }}
              rows='5'
            />
          </Form.Group>
          <Form.Group as={Row} className='col-md-8' style={{justifyContent: 'flex-end'}}>
            <Button className='me-2' onClick={addComment} icon='save'>
              {t('save_comment')}
            </Button>
            <CancelButton onClick={() => setComment('')}/>
          </Form.Group>
        </Tab>
        <Tab eventKey={'attachments'} title={'attachments'}>
          {task.attachments.map((attachment) => {
            return (
              <div key={attachment.id}>
                <Form.Group as={Row} style={{marginBottom: 0}}>
                  <Form.Label column='md'>
                    <span className='material-icons'>account_circle</span>{attachment.user}
                  </Form.Label>
                </Form.Group>
                <Form.Group className='mb-2' as={Row}>
                  <Form.Label column='md' md={1}>
                    {Intl.DateTimeFormat(window.AYMING_MARKET_LOCALE, dateTimeOptions).format(new Date(attachment.created_at))}
                  </Form.Label>
                  <Form.Label column='md' md={10}>
                    {attachment.link}
                  </Form.Label>
                </Form.Group>
              </div>
            );
          })}
          <Form.Group as={Row} className='col-md-8' style={{justifyContent: 'flex-end'}}>
            <Button className='me-2' onClick={addAttachment} icon='add'>
              {t('add_attachment')}
            </Button>
          </Form.Group>
        </Tab>
        <Tab eventKey={'logs'} title={'work_logs'}>
          {task.logs.map((log) => {
            return (
              <div key={log.id}>
                <Form.Group as={Row} style={{marginBottom: 0}}>
                  <Form.Label column='md'>
                    <span className='material-icons'>account_circle</span>{log.user}
                  </Form.Label>
                </Form.Group>
                <Form.Group className='mb-2' as={Row}>
                  <Form.Label column='md' md={2}>
                    Date: {Intl.DateTimeFormat(window.AYMING_MARKET_LOCALE, dateTimeOptions).format(new Date(log.date))}
                  </Form.Label>
                  <Form.Label column='md' md={2}>
                    {log.action}
                  </Form.Label>
                </Form.Group>
              </div>
            );
          })}
        </Tab>
      </Tabs>
    </Card.Body>
  )
}

export default TaskLists;
