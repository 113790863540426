import { Loading } from '@rd-web-markets/shared/dist/util';
import { Table } from '@rd-web-markets/shared/dist/util';
import { Card, Row, Col, Breadcrumb, Form } from 'react-bootstrap';
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import rndReportService from '@rd-web-markets/shared/dist/services/report_templates/rnd_report.service';
import { Button, DeleteButton } from '@rd-web-markets/shared/dist/util/buttons';
import { Link, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import CompanyReportPreview from '@rd-web-markets/shared/dist/reportPreview/CompanyReportPreview';
import { Loader } from '@rd-web-markets/shared/dist/util/Loader';
import SelectProjectsPopup from '@components/shared/rnd_report/SelectProjectsPopup';
import EditDynamicFieldsModal from '@components/shared/rnd_report/EditDynamicFieldsModal';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import SidebarPortal from '@rd-web-markets/shared/dist/util/SidebarPortal';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import { createTimezonedDate } from '@rd-web-markets/shared/dist/util/date';
import { CLIENT_RND_EDIT_DYNAMIC_FIELD, CLIENT_RND_SELECT_PROJECT_POPOVER, SIDEBAR_NAV_RND_REPORT_PAGE_CLIENT_LINKS } from '@rd-web-markets/market/dist/constants';
import changeSetsService from '@rd-web-markets/shared/dist/services/change_sets.service';

import claimProjectReportService from '@rd-web-markets/shared/dist/services/project_report/claim_project_report.service';
import EditProjectReportSliceModal from '@rd-web-markets/market/dist/reportPreview/EditProjectReportSliceModal';
import reportTemplateService from '@rd-web-markets/shared/dist/services/report_template.service';

const ClientRndReportsPage = ({handleToaster, accountType}) => {
  const { report_template_id } = useParams();
  const dispatch = useDispatch();
  const [reportTemplate, setReportTemplate] = useState(null);
  const [allReports, setAllReports] = claimProjectReportService.useFetchAll(reportTemplate?.claim_group?.id)
  // const [rnd_reports, setRndReports] = useState(null);
  const [aReportGenerationIsPending, setAReportGenerationIsPending] = useState(false);
  const [pollIntervalId, setPollIntervalId] = useState(null);
  const [showEditDynamicFieldsModal, setShowEditDynamicFieldsModal] = useState(false);
  const [isUnderReview, setIsUnderReview] = useState(false)
  const { search } = useLocation(); //Required for Italy
  const reportType = new URLSearchParams(search).get('report_type');
  const [shouldShowComments, setShouldShowComments] = useState(true);

  const anyProjectInDossier = () => {    
    if (process.env.PUBLIC_URL !== '/it') return false;
    
    const italyReportType = {
      inexpert_declaration_dossier: 'own',
      expert_declaration_dossier: 'expert',
    };

    const projects = allReports.filter((p) => reportType !== 'technical' ? p.certification_method === italyReportType[reportType] && p['include_in_' + reportType + '_report']: {} )
    return projects?.length === 0
  }

  const previewRndReportFinalVersion = rndReportService.usePreviewRndReportFinalVersion({ reportTemplateId: report_template_id, claimId: null })
  const previewRndReportHighlightsVersion = rndReportService.usePreviewRndReportHighlightsVersion({
    reportTemplateId: report_template_id,
    claimId: null,
    shouldShowComments: isUnderReview && shouldShowComments
  })
  
  
  const isPdfGenerationPending = useCallback(report => 
    ['pending', 'not_started'].includes(report.pdf_generation_status),
    []
  )

  const fetchRnDReportTemplate = useErrorHandling(useCallback(async () => {
    const template = await reportTemplateService.get(report_template_id);
    const rnd_reports_per_customer = await rndReportService.all(template.id);
    template.rnd_reports = rnd_reports_per_customer;
    setIsUnderReview(!!template.claim_group.active_change_set)
  
    const atLeastOneIsPending = template.rnd_reports.some(
      report => isPdfGenerationPending(report)
    );

    setAReportGenerationIsPending(atLeastOneIsPending);
    setReportTemplate(template);
  }, [isPdfGenerationPending, report_template_id]))

  const onReportPreviewCompleted = changeSetsService.useFinalizeChangeSet(
    () => window.location.reload()
  )

  useEffect(() => {
    if (!aReportGenerationIsPending) {
      fetchRnDReportTemplate();
      if (pollIntervalId) {
        // clear if any interval previously started
        window.clearInterval(pollIntervalId);
        setPollIntervalId(null);
        handleToaster('Report PDF generation finished.');
      }
    } else {
      if (!pollIntervalId) {
        const intervalId = window.setInterval(() => {
          fetchRnDReportTemplate();
        }, 2000);
  
        setPollIntervalId(intervalId);
      }
    }

    // clear any left interval on component dismount
    return () => window.clearInterval(pollIntervalId);
  }, [fetchRnDReportTemplate, pollIntervalId, aReportGenerationIsPending, handleToaster, report_template_id]);

  const deleteReport = useCallback(
    async (report_template_id, id) => {
      if (window.confirm('Are you sure you wish to delete this report?')) {
        try {
          await rndReportService.delete(report_template_id, id);
          await fetchRnDReportTemplate();
        } catch (error) {
          dispatch(handleError(error));
        }
      }
    },
    [dispatch, fetchRnDReportTemplate]
  );

  const generateReport = async (report_version) => {
    try {
      const showComments = report_version !== 'final' ? shouldShowComments : false
      await rndReportService.create(reportTemplate.id, report_version, reportType, showComments);
      setAReportGenerationIsPending(true);
    } catch(error) {
      dispatch(handleError(error));
    }
  }

  if(!reportTemplate) return <Loading />

  const renderDownloadLink = report => {
    return (
      <small>
        <a 
          disabled={isPdfGenerationPending(report)}
          href={`/api/report_templates/${report_template_id}/rnd_reports/${report.id}`}
          target="_blank"
          rel="noreferrer"
          download
        >
          <span className="fa fa-download"></span> Download report
        </a>
      </small>
    );
  }

  const rows = reportTemplate.rnd_reports
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    .map(r => ({
      key: r.id,
      columns: [
        createTimezonedDate(new Date(r.created_at)).toCustomLocaleTimeString(),
        r.author_name,
        r.pdf_generation_status,
        isPdfGenerationPending(r) ? <Loader /> : renderDownloadLink(r),
        <DeleteButton 
          disabled={isPdfGenerationPending(r)}
          onClick={isPdfGenerationPending(r) ? 
              () => {} :
              () => deleteReport(report_template_id, r.id)
          }
        />
      ]
    })
  );

  const reportsTableStyle = {
    maxHeight: '200px',
    overflowY: 'auto'
  };

  return (
    <>
      <SidebarPortal headerItem={SIDEBAR_NAV_RND_REPORT_PAGE_CLIENT_LINKS} />
      <BreadcrumbsPortal>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/customer' }}>Home</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} active>{reportTemplate.name}</Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbsPortal>

      <h1 className="text-primary mb-4">Reports</h1>

      <Row>
        <Col md={{ span: 2 }}>
          <Button
            disabled={aReportGenerationIsPending || anyProjectInDossier()}
            loading={aReportGenerationIsPending}
            onClick={() => generateReport('final')}
            variant={(isUnderReview && reportTemplate?.claim_group?.active_change_set?.review_type === 'client_review') ? 'dark' : 'primary'}
          >
            {(isUnderReview && reportTemplate?.claim_group?.active_change_set?.review_type === 'client_review')? 'PREVIEW PDF REPORT WITH ACCEPTED CHANGES' : 'GENERATE PDF REPORT'}
          </Button>
        </Col>
        {
          accountType === 'customer' && reportTemplate?.claim_group?.active_change_set?.review_type === 'client_review' &&
          <> 
            <Col md={{ span: 4, offset: 4 }}>
                { reportTemplate.claim_group.active_change_set && !reportTemplate.claim_group.active_change_set.finalized_at &&
                  'Please click this button once you or/and colleague(s) have all finished giving your feedback on the R&D report.'
                }
            </Col>
            <Col md={{ span: 2, offset: 0 }}>
                { reportTemplate.claim_group.active_change_set && !reportTemplate.claim_group.active_change_set.finalized_at &&
                  <Button
                    onClick={() => onReportPreviewCompleted(reportTemplate.claim_group)}
                    variant={'success'}
                  >
                    Set R&D Report Review Completed
                  </Button>
                }

                { reportTemplate.claim_group.active_change_set?.finalized_at &&
                  <Button
                    disabled={true}
                    variant={'dark'}
                  >
                    R&D Report Review Was Completed
                  </Button>
                }
            </Col>
          </>
        }

        

      </Row>
      <Row className='mt-3'>

        {isUnderReview && reportTemplate?.claim_group?.active_change_set?.review_type === 'client_review' && 
          <Col md={{ span: 3 }}>
            <Button
              disabled={aReportGenerationIsPending || anyProjectInDossier()}
              loading={aReportGenerationIsPending}
              onClick={() => generateReport('current')}
              variant={'dark'}
            >
              PREVIEW PDF WITH HIGLIGHTS
            </Button>

          </Col>
        }
      </Row>
      { isUnderReview && reportTemplate?.claim_group?.active_change_set?.review_type === 'client_review' &&
        <Row className='mt-3'>
          <Col md={6}>
            <Form.Check
              checked={shouldShowComments}
              label="Show comments in report?"
              onChange={(e) => setShouldShowComments(e.target.checked)}
            />
          </Col>
        </Row>
      }
      <Row style={reportsTableStyle} className="pt-4">
        <Col md={12} className="text-center">
          <Table headers={[]} rows={rows} showControls={false} />
        </Col>
      </Row>

      <Row className="text-center mt-5">
        <Card className="border-0 ms-auto me-auto">
          <Card.Header className="border-0 bg-secondary bg-gradient">
            <h4 className="text-center text-secondary">Preview</h4>
          </Card.Header>
          <Card.Body>
            <CompanyReportPreview
              changeSetContainingObject={reportTemplate.claim_group}
              reportTemplateId={report_template_id}
              claimGroup={reportTemplate.claim_group}
              shouldShowComments={isUnderReview && shouldShowComments}
              previewRndReportFinalVersion={previewRndReportFinalVersion}
              previewRndReportHighlightsVersion={previewRndReportHighlightsVersion}
              EditProjectReportSliceModal={EditProjectReportSliceModal}
            />
          </Card.Body>
        </Card>
      </Row>
      { showEditDynamicFieldsModal &&
        <EditDynamicFieldsModal
          accountType={accountType}
          claimGroup={reportTemplate.claim_group}
          show={showEditDynamicFieldsModal}
          onHide={() => setShowEditDynamicFieldsModal(false)}
        />
      }
    </>
  )
}

export default ClientRndReportsPage
