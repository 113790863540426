import { Loading } from '@rd-web-markets/shared/dist/util';
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom'
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import ClaimsSelection from '@components/admin/companies/kimble/ClaimsSelection';
import { Breadcrumb, Card } from 'react-bootstrap';
import { claimTemplateService } from '@services/claim_template.service';
import questionnairesService from '@services/questionnaires.service';
import { COMPANY_REQUIRES_COMPANIES_HOUSE_INFO } from '@rd-web-markets/market/dist/constants';
import { CLAIM_SELECTION_ALLOWS_SLICING_MULTI_YEAR_CLAIMS } from '@rd-web-markets/market/dist/constants';
import NewClaimListingModal from '@rd-web-markets/shared/dist/claim/NewClaimListingModal';
import { fetchAndSetAdmins, fetchAndSetConsultants } from 'src/effects/user.effects';
import { CardErrorMessage } from '@rd-web-markets/shared/dist/util';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import SidebarPortal from '@rd-web-markets/shared/dist/util/SidebarPortal';
import { useSelector } from 'react-redux';
import { kimbleCompanyService } from '@rd-web-markets/shared/dist/services/kimble_company.service';
import CompanyEditForm from '@rd-web-markets/shared/dist/company/CompanyEditForm';
import CompanyMarketRelatedFields from '@rd-web-markets/market/dist/company/CompanyMarketRelatedFields';

const NewCompanySelectClaimsPage = ({handleToaster}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id_in_provider_system_and_companies_house_number } = useParams();
  const [claimToSplit, setClaimToSplit] = useState(null);
  const [showClaimSplitModal, setShowClaimSplitModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState(null);
  const [claims, setClaims] = useState([]);
  const [claimGroup, setClaimGroup] = useState(null);
  const [consultants, setConsultants] = useState(null);
  const [admins, setAdmins] = useState(null);
  const [finalizeCompany, setFinalizeCompany] = useState(false);
  const [claimTemplates, setClaimTemplates] = useState([]);
  const user = useSelector((state) => state.auth.user);

  const [questionnaires] = questionnairesService.useQuestionnaires();

  useEffect(() => {
    fetchAndSetConsultants(setConsultants,dispatch, handleError)
    fetchAndSetAdmins(setAdmins, dispatch, handleError)
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [kimbleId, companiesHouseNumber] = id_in_provider_system_and_companies_house_number.split('--')
        const claims = await kimbleCompanyService.get(kimbleId);

        const company = 
          companiesHouseNumber !== 'null' && COMPANY_REQUIRES_COMPANIES_HOUSE_INFO ?
          await kimbleCompanyService.getCompanyDetails({number: companiesHouseNumber}) :
          {};

        setCompany(company)

        const claimTemplates = await claimTemplateService.get();  
        setClaimTemplates(claimTemplates);
        if (claims.length) {
          setCompany({
            admin_id: '',
            consultant_id: '',
            has_made_claims_before: false,
            number: companiesHouseNumber,
            id_in_provider_system: kimbleId,
            name: claims[0].kimble_company_name,
            sic_codes: claims[0].kimble_sic_code?.replace(/\D/g, ''),
            accounts_period_changed: false,
            accounts_period: company.accounts_period,
            company_users: [],
            claims: [],
            claim_group: process.env.PUBLIC_URL === '/uk'
              ? {
                name: '', 
                project_methodology: 'project_basis',
                consultants_attributes: [], 
                accounts_provided: false,
                tax_computations_provided: false,
                development_costs_capitalised: false,
                contracted_by_third_party: false,
                received_grants_or_subsidies: false,
                reimburses_staff_expenses: false,
                purchased_company_cars: false,
                has_patents: false,
                rnd_collaborate_contacts_attributes: []
              }
              : {
                name: '', 
                consultants_attributes: [], 
                has_overdue_taxes: false,
                has_staff_involved_not_on_payroll: false,
                accounts_provided: false,
                tax_computations_provided: false,
                development_costs_capitalised: false,
                contracted_by_third_party: false,
                received_grants_or_subsidies: false,
                reimburses_staff_expenses: false,
                purchased_company_cars: false,
                has_patents: false
              },
          });
        }

        setClaims(claims.map(claim => {
          return {...claim, include: false};
        }));
      } catch (error) {
        dispatch(handleError(error));
      }
    };
    fetchData();
  }, [id_in_provider_system_and_companies_house_number, dispatch]);

  const toggleInclude = (eventOrClaim, index) => {
    if (!CLAIM_SELECTION_ALLOWS_SLICING_MULTI_YEAR_CLAIMS) {
      claims[index]['include'] = eventOrClaim.target.checked;
      setClaims([...claims]);
    } else {
      // At the moment we select a single claim only for US, where we also have to split
      // multi-year claims from kimble into single-year claims.
      setClaimToSplit(eventOrClaim);
      setShowClaimSplitModal(true);
    }
  }

  const toggleForm = (newClaims = undefined) => {
    if(!finalizeCompany) {
      const companyClaims = (newClaims || claims).filter(claim => claim.include === true).map(claim => {
        return {...claim,
          from_date: claim.from_date,
          to_date: claim.to_date,
          label: new Date(claim.to_date).getFullYear(),
          name: claim.name || claim.kimble_name,
          project_methodology: 'employee_basis'}
      })

      if (process.env.PUBLIC_URL === '/de') {
        setCompany({...company,
          claims: companyClaims,
          country: 'germany'
        });
      } else {
        setCompany({...company,
          claims: companyClaims,
        });
      }

      setFinalizeCompany(true);
    } else {
      setCompany({...company, claims: []});
      setFinalizeCompany(false);
    }
  }

  const handleSubmit = async newCompany => {
    setLoading(true);
    newCompany.claim_groups_attributes = [{
        ...newCompany.claim_group,
        claims_attributes: newCompany.claims
      }
    ]
    if (!newCompany.claim_group.consultants_attributes.find(consultant => consultant.role === 'claim_group_access_lead_consultant')) { 
      handleToaster('Please add lead consultant to claim group', 'warning');
      setLoading(false);
      return;
    }
    try {
      const { id } = await kimbleCompanyService.create(newCompany);
      setLoading(false);
      if(process.env.PUBLIC_URL === '/it'){ // in Italy history should be replaced differently(after image upload)
        setCompany({...company, id: id})
        return
      }else{
        history.replace(`/${user.account_type}/companies/${id}/master`);
      }

    } catch(error) {
      setLoading(false);
      dispatch(handleError(error));
    }
  }

  const removeConsultantFromClaim = consultant => {
    const claimGroup = company.claim_group;
    claimGroup.consultants_attributes = claimGroup.consultants_attributes.filter(c => {
      return c.id !== consultant.id;
    })
    setClaimGroup(
      {
        ...company,
        claim_group: { ...claimGroup }
      }
    )
  }

  const compareDates = (from, to) => {
    const dateParts = from.split('/');
    const fromDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]); 

    const toDate = new Date(to);

    if(fromDate.getTime() > toDate.getTime()) {
      return false;
    } else {
      return true;
    }
  }

  const setFromDate = (date, claim) => {
    if(date) { 
      const claimIndex = claims.findIndex(c => c.kimble_name === claim.kimble_name);
      const newClaims = [...claims];
      if(compareDates(date, newClaims[claimIndex].to_date)){
        newClaims[claimIndex] = {...claim, from_date: date};
        setClaims(newClaims);
      } else { 
        handleToaster('From date can not be after to date','warning');
      }
    }
  }



  if(!company || !claims || !questionnaires) return <Loading />;

  return (
    <>
      <SidebarPortal headerItem={{link: 'companies', text: 'Companies'}} />
      <BreadcrumbsPortal>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${user.account_type}`}}>Home</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${user.account_type}/companies`}}>Companies</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${user.account_type}/companies/new`}}>New</Breadcrumb.Item>
          <Breadcrumb.Item active>Select</Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbsPortal> 

      { claims && !claims.length && <CardErrorMessage errorMessage={'This company has no claims in Kimble.'} /> }

      { !!claimToSplit && showClaimSplitModal && claims?.length &&
        <NewClaimListingModal
          singleOrMultiYearClaim={claimToSplit}
          show={true}
          onSubmit={(newClaims) => {
            setShowClaimSplitModal(false)
            newClaims.forEach(c => {
              const toYear = c.to_date.getFullYear()
              c.include = true
              c.kimble_company_name = claimToSplit.kimble_company_name
              c.kimble_name = claimToSplit.kimble_name.replace((new Date(claimToSplit.to_date)).getFullYear(), toYear)
              c.id_in_provider_system = claimToSplit.id_in_provider_system
              c.kimble_sic_code = claimToSplit.kimble_sic_code
              c.to_date = `${toYear}-${c.to_date.getMonth() + 1}-${c.to_date.getDate()}`
              c.from_date = `${c.from_date.getFullYear()}-${c.from_date.getMonth() + 1}-${c.from_date.getDate()}`
              c.name = `${claimToSplit.kimble_company_name} - ${toYear}`
            })

          toggleForm(newClaims)
        }}
        onHide={() => setShowClaimSplitModal(false)}
      />
    }
    { (!finalizeCompany && claims?.length) ?
      <ClaimsSelection
        toggleForm={toggleForm}
        setFromDate={setFromDate}
        toggleInclude={toggleInclude}
        claims={claims}
        setClaims={setClaims}
        includeSingleClaim={CLAIM_SELECTION_ALLOWS_SLICING_MULTI_YEAR_CLAIMS}
      /> 
      :
      <></>
    }
    {
      finalizeCompany &&
      admins &&
      consultants &&
      <Card>
        <Card.Header className='bg-primary bg-gradient text-white'>New Company - Finalise</Card.Header>
        <Card.Body>
          <CompanyEditForm
            removeConsultantFromClaim={removeConsultantFromClaim}
            toggleForm={toggleForm}
            claimTemplates={claimTemplates}
            selectedCompany={company}
            consultants={consultants}
            admins={admins}
            loading={loading}
            handleSubmit={handleSubmit}
            handleToaster={handleToaster}
            questionnaires={questionnaires}
            CompanyMarketRelatedFields={CompanyMarketRelatedFields}
          />
        </Card.Body>
      </Card>
    }
    </>
  )
}

export default NewCompanySelectClaimsPage
