import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { Form, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AddButton, DeleteButton, EditButton } from '@rd-web-markets/shared/dist/util/buttons';

const ClaimTaskForm = ({
  tasks,
  removeTask,
  handleToaster,
}) => {
  const { claimGroupId } = useParams();
  const { t } = useTranslation();
  return (
    <>
      <Form.Group className='mb-2' as={Row}>
        <Form.Label column='md' md={12}>
          <strong>{t('list_of_claim_tasks')}</strong>
        </Form.Label>
      </Form.Group>
      <Form.Group className='mb-2' as={Row}>
        <Form.Label column='md' md={1}>
          {t('id')}
        </Form.Label>
        <Form.Label column='md' md={4}>
          {t('task_title')}
        </Form.Label>
        <Form.Label column='md' md={3}>
          {t('task_type')}
        </Form.Label>
        <Form.Label column='md' md={3}>
          {t('task_status')}
        </Form.Label>
      </Form.Group>
      {tasks
        .map((task) => {
          return (
            <div key={task.id}>
              <Form.Group className='mb-2' as={Row}>
                <Form.Label column='md' md={1}>
                  {task.id}
                </Form.Label>
                <Form.Label column='md' md={4}>
                  {task.name}
                </Form.Label>
                <Form.Label column='md' md={3}>
                  {task.type}
                </Form.Label>
                <Form.Label column='md' md={3}>
                  {task.status}
                </Form.Label>
                <Col md={1}>
                  <EditButton
                    as={Link}
                    to={`/admin/claim_groups/${claimGroupId}/edit/task/${task.id}`}
                  />
                  <DeleteButton
                    className='text-danger'
                    onClick={(e) => removeTask(task.id)}
                  />
                </Col>
              </Form.Group>
            </div>
          );
        })
      }
      <Form.Group className='mb-2' as={Row}>
        <Col column='md' md={{ span: 10, offset: 2 }}>
          <AddButton
            variant='info'
            as={Link}
            to={`/admin/claim_groups/${claimGroupId}/add/task`}
            text={t('add_task')}
          />
        </Col>
      </Form.Group>
    </>
  );
};

export default ClaimTaskForm;
