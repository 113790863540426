import React, { useRef } from 'react';
import { Form, Container, Row, Col, Card } from 'react-bootstrap';
import { Button } from '@rd-web-markets/shared/dist/util/buttons';
import { useEffect, useState } from 'react';
import { login } from '@rd-web-markets/shared/dist/store/features/authSlice';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import logo from '@assets/images/logo.png';
import ErrorHandler from '@components/util/ErrorHandler';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import { UserService } from '@rd-web-markets/shared/dist/services/user.service';
import * as QueryString from 'query-string';
import BackgroundImage from '@assets/BackgroundImage';

export default function LoginPage() {
  const [hasSetSessionCookie, setHasSessionCookie] = useState(false)
  const [loading, setLoading] = useState(false);
  const [authorizePath, setAuthorizePath] = useState();
  const [authenticityToken, setAuthenticityToken] = useState();
  const [queryParams, setQueryParams] = useState(
    window.location.search ? QueryString.parse(window.location.search) : { invitation: false }
  )

  const PORTAL_URL = process.env.PORTAL_URL
  const ORIGIN = location.origin
  const RETURN_ENDPOINT = '/api/users/auth/keycloakopenid/callback'
  const FULL_PORTAL_URL = `${PORTAL_URL}?returnUrl=${ORIGIN}${RETURN_ENDPOINT}`

  const form = useRef();

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const fetchCookieAndCSRFToken = async () => {
      try {
        // There is a call in App.js to UserService.getCurrent that sets a session cookie on the browser.
        // getCurrent returns a response from the backend that tells the browser to set a session cookie.
        // There is a bug where if you close your browser while logged in, the session cookie remains saved.
        // If you then for some reason go back to the login page, and click the login button, you face an invalid
        // authenticity token error. This seems to be because the page was cached by the browser, and the request in
        // App.js is not repeated the 2nd time you opened the login page. However the session cookie may be old.
        // The bug is easily reproduced by deleting the session cookie or changing its value.
        // So the implemented solution is to fetch a new session cookie.
        // It does not matter if the request succeeds or if it returns 401, etc. The important thing is that
        // the response sets a session cookie.
        await UserService.getCurrent()
        setHasSessionCookie(true)
      } catch (error) {
        setHasSessionCookie(true)
      }

      try {
        const response = await UserService.getAuthorizationPath()
        setAuthorizePath(response.path);
        setAuthenticityToken(response.authenticity_token);

        /**
         * queryParams.redirect_provider is set to true when the login button fails to send the user to the correct keycloak login form.
         * In that case the user is redirected to the backend "new_session_path" which is set to redirect the user to the login page with redirect_provider=true.
         * This is because of a log in bug, where for some reason the user's cookie or csrf token are wrong. Therefore when clicking the log in button, instead of being
         * redirected to keycloak, the backend throws an ActionController::InvalidAuthenticityToken exception.
         * Its not obvious why this is happening but its almost certainly related to caching, because it can be fixed by the user himself,
         * by simply refreshing the page. But in order to not show an ugly error, and have the user figure this out himself, we redirect him to
         * the login page again from the backend, which fetches a new cookie and a new token, and then automatically tries to send the user to the keycloak
         * login form by submitting this login button form - a.k.a. pressing the button.
         */
        if (form && form.current && queryParams.redirect_provider === 'true') {
          form.current.submit();
        }
      }
      catch (error) {
        dispatch(handleError(error))
        setLoading(false);
      }
    }
    
    fetchCookieAndCSRFToken()
  }, [dispatch, queryParams.redirect_provider])

  return (
    <ErrorHandler alertsContainerId='alerts-container' alertClassName='w-100 mw-100 mb-3'>
      <Container>
        <BackgroundImage />
        <Row className="justify-content-center">
          <img src={logo} alt="Logo" className="m-5" style={{ maxWidth: '60%'}}/>
        </Row>
        { hasSetSessionCookie && 
          <Row className="justify-content-center">
            <Col xs={8}>
              <Card>
                  <div id="alerts-container"></div>
                  <Form action={`${authorizePath}`} method="POST" ref={ref => form.current = ref}>
                    <input type="hidden" name="authenticity_token" value={authenticityToken || ''} />
                    <input type="hidden" name="prompt" value={'login'} />
                    
                    { !process.env.SHOULD_USE_PORTAL &&
                      <Button size="lg" className='d-block' type="submit" variant="primary" disabled={!authorizePath || !authenticityToken || loading}>Please Sign in to Continue</Button>
                    }
                  </Form>

                  <div>
                    { process.env.SHOULD_USE_PORTAL && 
                      <Button href={FULL_PORTAL_URL} size="lg" className='d-block' type="link" variant="primary" disabled={!authorizePath || !authenticityToken || loading}>Please Sign in to Continue</Button>
                    }
                  </div>
                  
              </Card>

              { queryParams.invitation === 'true' &&
                <Card>
                  <div className='p-3'>
                    <h1 className="mb-4 mt-4 text-center">Welcome to Ayming Advance!</h1>
                      
                      <h5>If you have already set up your Ayming account, please click on the login button to continue.</h5>
                      <h5>If you haven't set up your account - please click on the login button and then on the forgotten password button, to activate your email and set up a new password.</h5>
                  </div>
                </Card>
              }
            </Col>
          </Row>
        }
      </Container>
    </ErrorHandler>
  );
}
