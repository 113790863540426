import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import ClaimScheduleSetTimes from '../claim_schedule/ClaimScheduleSetTimes';
import ClaimScheduleSetTimesItaly from '@rd-web-markets/it/dist/claim_schedule/ClaimScheduleSetTimesItaly';
import { Col, Form, Row } from 'react-bootstrap';
import { DatePicker } from '@rd-web-markets/shared/dist/util/date';

const ClaimGroupClaimScheduleSection = ({
  company,
  setCompany,
  updateCompany,
  claimGroup,
  handleClaimSchedule,
  loading,
  handleProccessDate,
  handleToaster
}) => {
  const { t } = useTranslation();

  // only relevant for BE
  const [kickOfMeetingDate, setKickOfMeetingDate] = useState(company.kick_off_meeting_date)

  return (
    <>
      <h2 className="text-primary mb-4">{t('claim_schedule')}</h2>

      {process.env.PUBLIC_URL === '/be' && (
        <Form.Group className='mb-2' as={Row}>
          <Form.Label column="md" md={3}>
            {t('kick_off_meeting_date')}
          </Form.Label>
          <Col md={{ span: 5, offset: 4 }}>
            <DatePicker
              dateFormat="dd.MM.yyyy"
              onChange={async (date) => {
                await updateCompany({...company, kick_off_meeting_date: date });
              }}
              selected={company.kick_off_meeting_date}
            />
            {/* <Form.Control
              onChange={e => setCompany({
                ...company,
                kick_off_meeting_date: e.target.value,
              })}
              onBlur={() => updateCompany(company)}
              disabled={true}
              name="kick_off_meeting_date"
              value={company.kick_off_meeting_date}
              size="md"
              type="text"
            ></Form.Control> */}
          </Col>
        </Form.Group>
      )}

      {process.env.PUBLIC_URL === '/it' ? (
        <ClaimScheduleSetTimesItaly
          claimSchedule={claimGroup.claim_schedule}
          handleClaimSchedule={handleClaimSchedule}
          loading={loading}
          handleProccessDate={handleProccessDate}
          handleToaster={handleToaster}
        />
      ) : (
        <ClaimScheduleSetTimes
          company={company}
          claimGroup={claimGroup}
          claimSchedule={claimGroup.claim_schedule}
          handleClaimSchedule={handleClaimSchedule}
          loading={loading}
          handleProccessDate={handleProccessDate}
        />
      )}
    </>
  )
}

export default ClaimGroupClaimScheduleSection
