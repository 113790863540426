import { isEmptySelection, stopEvent, surroundSelectionTextNodesWithDom, userIsCuttingContent } from "@rd-web-markets/shared/dist/util/domUtils";
import DeletionHighlight from "../models/DeletionHighlight";
import DynamicField from "../models/DynamicField";
import TrackChangesHighlight from "../models/TrackChangesHighlight";
import { setCursorPositionToStartOfElement } from "../cursorUtils";

/**
 * Cutting is handled in onCut.
 * In case we are deleting or we are pasting content or we are pressing enter - we surround the selected text with a deletion highlight.
 * In case we are deleting or pressing Enter - we move the cursor to end of the text node which is on the right of the highlited part.
 * In case we are pasting content - we prepare for pasting by adding an addition highlight dom element to the right and putting the cursor in it.
 * In case we are adding new text - we add a new highlight dom element at the cursor position and put the cursor in that element.
 * @param {*} event 
 * @param {*} editor 
 * @param {*} contentNode 
 * @param {*} cursorSelection 
 * @param {*} predictedInputValueOrNull 
 */
function highlightAdditionAndDeletionChangesWhenSelectionIsNotEmpty(event, editor, contentNode, cursorSelection, predictedInputValueOrNull) {
  // cutting | pasting | event key enter
  if (contentNode.nodeName === 'IMG') {
    contentNode.classList.add(...DeletionHighlight.createDomElementHighlightClasses().split(' '));
    return;
  }
  if (!!predictedInputValueOrNull?.full && contentNode.textContent !== predictedInputValueOrNull?.full || userIsPastingContent(event) || event.key === 'Enter') {
    surroundSelectionTextNodesWithDom(editor.getDoc(), cursorSelection, DeletionHighlight.createHighlightedElement, DeletionHighlight.createDomElementHighlightClasses, 'TrackChanges-Highlight-Addition', 'TrackChanges-Highlight-Deletion');
  }
}
export function isCutting(event) {
  return userIsCuttingContent(event);
}
export function doCut(event, editor, cursorSelection, contentNode, predictedInputValueOrNul) {
  if (!isEmptySelection(cursorSelection)) {
    highlightAdditionAndDeletionChangesWhenSelectionIsNotEmpty(event, editor, contentNode, cursorSelection, predictedInputValueOrNull);
  }
}
export function onCutEvent(event, activeEditorInstance) {
  try {
    stopEvent(event);
    const cursorSelection = activeEditorInstance.selection.getSel();
    const contentNode = activeEditorInstance.selection.getNode();
    if (DynamicField.isEditingPartOfADynamicField(contentNode, cursorSelection)) {
      return;
    } else if (!isEmptySelection(cursorSelection) && TrackChangesHighlight.isAnySelectionNodeInTrackChangesHighlight(cursorSelection, DeletionHighlight.highlightType)) {
      return;
    } else if (cursorSelection.anchorNode.nodeName === 'DIV' && cursorSelection.anchorNode.parentElement.nodeName === 'BODY' || cursorSelection.focusNode.nodeName === 'DIV' && cursorSelection.focusNode.parentElement.nodeName === 'BODY') {
      // this seems to happen when we select a deletion highlight which has a contenteditable=false
      return;
    }
    const cutData = cursorSelection.toString();
    event.clipboardData.setData('text/plain', cutData);
    const [leftSideTextNode, rightSideTextNode] = surroundSelectionTextNodesWithDom(activeEditorInstance.getDoc(), cursorSelection, DeletionHighlight.createHighlightedElement, DeletionHighlight.createDomElementHighlightClasses, 'TrackChanges-Highlight-Addition', 'TrackChanges-Highlight-Deletion');
    setCursorPositionToStartOfElement(activeEditorInstance, rightSideTextNode);
  } catch (error) {
    console.error(error);
  }
}