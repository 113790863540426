import React, { useCallback } from 'react'
import { Form, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@rd-web-markets/shared/dist/util/date';
import FileUpload from '@rd-web-markets/shared/dist/util/FileUpload';
import { useFileUpload } from '@rd-web-markets/shared/dist/hooks';
import trackingTaskFilesService from '@services/task_management/tracking_tasks/tracking_task_files.service';
import { DeleteButton } from '@rd-web-markets/shared/dist/util/buttons';

const FileRow = ({ claimProjectReportId, taskId, id, file, handleFileData, handleDelete }) => {
  const { t } = useTranslation();

  const handleResponse = useCallback((response) => {
    setUploadedFile(null);
    handleFileData(response.id, id, 'id');
    handleFileData(response.link, id, 'link');
  }, [handleFileData, id]);

  const uploadFile = trackingTaskFilesService.useUploadFile(
    claimProjectReportId,
    taskId,
    file,
    handleResponse
  );
  const deleteFile = trackingTaskFilesService.useDeleteFile(
    claimProjectReportId,
    taskId,
    file,
    () => handleDelete(id)
  );

  const [uploadedFile, isUploadedFileLoading, setUploadedFile] = useFileUpload(uploadFile, 'file');

  const handleClick = (hiddenFileInput) => {
    if (file.link) {
      setUploadedFile({});
    } else {
      hiddenFileInput.current.click();
    }
  }

  return (
    <Form.Group as={Row} key={id}>
      <Form.Group as={Row} column='md' md={4} className='mb-0 ms-0'>
        <Form.Label column='md' md={2}>
          {id + 1}
        </Form.Label>
        <Form.Label column='md' md={3}>
          {t('due_date')}:
        </Form.Label>
        <Form.Group as={Col} md={6} className='mb-0'>
          <DatePicker
            column='md'
            md={8}
            selected={file.due_date}
            onChange={(date) => handleFileData(date, id, 'due_date')}
          />
        </Form.Group>
      </Form.Group>
      <Form.Group as={Row} column='md' md={4} className='mb-0'>
        <Form.Label column='md' md={4}>
          {t('file_name')}:
        </Form.Label>
        <Form.Control
          className='col-md-8'
          value={file.file_name}
          onChange={(event) => handleFileData(event.target.value, id, 'file_name')}
          placeholder={t('enter_file_name')}
        />
      </Form.Group>
      <Form.Group as={Col} column='md' md={6} style={{marginLeft: '50px'}} className='mb-0'>
        <Form.Group as={Row} column='md' md={12} className='mb-0'>
          <FileUpload
            loading={isUploadedFileLoading}
            title={t('upload_file')}
            file={uploadedFile}
            onSelectFile={setUploadedFile}
            customHandleClick={handleClick}
            disabled={!!file.id}
          />
          <Form.Label style={{paddingLeft: '15px', paddingRight: '15px', paddingTop: 'calc(0.375rem + 1px)'}}>
            {t('or')}
          </Form.Label>
          <Form.Group as={Col} md={8} className='mb-0'>
            <Form.Control
              as='input'
              onChange={(event) => handleFileData(event.target.value, id, 'link')}
              size='md'
              type='text'
              value={file.link}
              placeholder={t('upload_file_or_enter_link_to_file')}
            />
          </Form.Group>
          <Form.Group>
            <DeleteButton onClick={deleteFile} />
          </Form.Group>
        </Form.Group>
      </Form.Group>
    </Form.Group>
  );
}

export default FileRow;