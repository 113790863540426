import { createSelectionDataFromArray, isNodeBeforeAnotherInDom } from "../../../domUtils";
import { findAdjacentTrackChangesElementssOfTheSameType } from "./highlighintUtils";
import TrackChangesHighlight from "./models/TrackChangesHighlight";
export function findNextPreviousNonAdjacentChanges(editor, changeElementsOfCurrentPopover) {
  const allChangeElements = Array.from(editor.getBody().querySelectorAll('.TrackChanges-Highlight'));
  const elementsParentNotInPopover = element => !changeElementsOfCurrentPopover.some(changeElement => changeElement.contains(element));
  const nonAdjacentChangeElements = allChangeElements.filter(changeElement => {
    return !changeElementsOfCurrentPopover.includes(changeElement) && elementsParentNotInPopover(changeElement);
  });
  let nextPopoverChangeElement = nonAdjacentChangeElements[0];
  let previousPopoverChangeElement = nonAdjacentChangeElements[0];
  if (changeElementsOfCurrentPopover?.length) {
    nextPopoverChangeElement = nonAdjacentChangeElements.find(node => isNodeBeforeAnotherInDom(node, changeElementsOfCurrentPopover[changeElementsOfCurrentPopover.length - 1]));
    const allPreviousChangeElements = nonAdjacentChangeElements.filter(changeElement => isNodeBeforeAnotherInDom(changeElementsOfCurrentPopover[0], changeElement));
    previousPopoverChangeElement = allPreviousChangeElements[allPreviousChangeElements.length - 1];
  }
  return {
    nextPopoverChangeElement,
    previousPopoverChangeElement
  };
}
export function createEditorSelectionWithAllRelatedTrackChangesElements(editor, trackChangeElement) {
  const doc = editor.getDoc();
  const nodeTrackChangesTypeClass = TrackChangesHighlight.getNodeTrackChangesType(trackChangeElement);
  const relatedNodesAndContentNode = findAdjacentTrackChangesElementssOfTheSameType(trackChangeElement, nodeTrackChangesTypeClass);
  const selection = createSelectionDataFromArray(doc, relatedNodesAndContentNode);
  return selection;
}