import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
import { ApiError } from '@rd-web-markets/shared/dist/services/ApiError';
const commentsService = {
  async all(object_id, object_type, span_id, field) {
    try {
      // we can fetch comments even without a span id
      let query = `/api/comments?object_type=${object_type}&object_id=${object_id}&field=${field}`;
      if (span_id) {
        query = `${query}&span_id=${span_id}`;
      }
      return await fetch(query, request.get);
    } catch (error) {
      if (error.message.includes('Change set must exist')) {
        throw new ApiError('Change set does not exist. Make sure the claim group is under manager QA review to use the comments functionality.');
      }
    }
  },
  create(object_id, object_type, comment) {
    return fetch('/api/comments', {
      ...request.post,
      body: JSON.stringify({
        object_id,
        object_type,
        comment
      })
    });
  }
};
export default commentsService;