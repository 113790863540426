import React from 'react'
import { Form, Row, Col, Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { DeleteButton } from '@rd-web-markets/shared/dist/util/buttons';

const Question = ({ question: q, index, questionnaire, setQuestionField, removeQuestion }) => {
  const { t } = useTranslation();
  const questionTypes = ['open_question', 'single_selection_answer', 'multiple_selection_answer'];

  return (
    <>
      <Form.Group className='mb-2' as={Row}>
        <Form.Label className='col-form-label-lg' column='md' md={2} data-testid='question-number'>
          {t('question')} {index + 1}
        </Form.Label>
        <Form.Group as={Col} md={1} style={{paddingTop: 'calc(0.5rem + 1px)'}}>
          <DeleteButton
            variant='danger'
            className='mx-0'
            data-testid='question-delete-button'
            onClick={() => removeQuestion(index)}
            disabled={questionnaire.questions.length === 1}
          />
        </Form.Group>
      </Form.Group>
      <Form.Group className='mb-2' as={Row}>
        <Form.Label column='md' md={2}>
          {t('question_type')}
        </Form.Label>
        <Form.Group as={Col} md={4}>
          <Form.Control
            as='select'
            data-testid='question-type-control'
            onChange={(event) => setQuestionField(index, event.target.value, 'question_type')}
            size='md'
            type='text'
            value={q.question_type}
          >
            {questionTypes.map((type) => <option value={type} key={type}>{t(type)}</option>)}
          </Form.Control>
        </Form.Group>
      </Form.Group>
      <Form.Group className='mb-2' as={Row}>
        <Form.Label column='md' md={2}>
          {t('question')}
        </Form.Label>
        <Form.Group as={Col} md={8}>
          <Form.Control
            as='textarea'
            data-testid='question-field'
            onChange={(event) => setQuestionField(index, event.target.value, 'body')}
            size='md'
            type='text'
            value={q.body}
            style={{ resize: 'none' }}
            rows='2'
            placeholder={t('enter_question')}
          />
        </Form.Group>
      </Form.Group>
      <Form.Group className='mb-2' as={Row}>
        <Form.Label column='md' md={2}>
          {t('description')}
        </Form.Label>
        <Form.Group as={Col} md={8}>
          <Form.Control
            as='textarea'
            data-testid='description-field'
            onChange={(event) => setQuestionField(index, event.target.value, 'description')}
            size='md'
            type='text'
            value={q.description}
            style={{ resize: 'none' }}
            rows='3'
          />
        </Form.Group>
      </Form.Group>
      <Dropdown.Divider />
    </>
  );
}

export default Question;
