import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
import { useCallback, useEffect, useState } from 'react';
import { useErrorHandling } from '../hooks/useErrorHandling';
const reportTemplateService = {
  useGet(reportTemplateId) {
    const [rndReportTemplate, setRndReportTemplate] = useState(null);
    const fetchRndReportTemplate = useErrorHandling(useCallback(async () => {
      const template = await reportTemplateService.get(reportTemplateId);
      setRndReportTemplate(template);
    }));
    useEffect(async () => {
      await fetchRndReportTemplate();
    }, [reportTemplateId]);
    return [rndReportTemplate, setRndReportTemplate];
  },
  all() {
    let search = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    //search is used only for Italy at the moment
    return fetch(`/api/report_templates${search}`, request.get);
  },
  get(templateId) {
    return fetch(`/api/report_templates/${templateId}`, request.get);
  },
  create(report_template, templateType) {
    report_template = templateType ? {
      ...report_template,
      template_type: templateType
    } : report_template; // Italy
    return fetch('/api/report_templates/', {
      ...request.post,
      body: JSON.stringify({
        report_template
      })
    });
  },
  update(id, report_template) {
    return fetch(`/api/report_templates/${id}`, {
      ...request.put,
      body: JSON.stringify({
        report_template
      })
    });
  },
  updateWithCategories(id, report_template) {
    report_template.report_template_categories_attributes ||= report_template.report_template_categories;
    return reportTemplateService.update(id, report_template);
  },
  delete(id) {
    return fetch(`/api/report_templates/${id}`, {
      ...request.delete
    });
  }
};
export default reportTemplateService;