import { useEffect, useState } from 'react';
import { handleError } from "../store/features/alertSlice";
import { useDispatch } from "react-redux";
import technicalTemplateImportStatusesService from '@rd-web-markets/shared/dist/services/claim/technical_template_import_statuses.service';
import claimGroupService from '../services/claim_group.service';
export const useTechnicalTemplateImportStatus = (claimGroup, setClaimGroup) => {
  const [technicalTemplateImportStatus, setTechnicalTemplateImportStatus] = useState(null);
  const [isTechnicalTemplatePending, setIsTechnicalTemplatePending] = useState(false);
  const dispatch = useDispatch();
  const changePendingStatus = () => {
    setIsTechnicalTemplatePending(prevStatus => !prevStatus);
  };
  useEffect(() => {
    const fetchTechnicalTemplateImportStatus = async () => {
      const technicalTemplateImportStatus = await technicalTemplateImportStatusesService.get(claimGroup.id);
      setTechnicalTemplateImportStatus(technicalTemplateImportStatus);
    };
    fetchTechnicalTemplateImportStatus();
  }, [claimGroup, isTechnicalTemplatePending]);
  useEffect(() => {
    if (isTechnicalTemplatePending) {
      setTechnicalTemplateImportStatus({
        ...technicalTemplateImportStatus,
        import_status: 'pending'
      });
      const intervalId = window.setInterval(() => {
        technicalTemplateImportStatusesService.get(claimGroup.id).then(importStatus => {
          if (importStatus.import_status !== 'pending') {
            setIsTechnicalTemplatePending(false);
            claimGroupService.get(claimGroup.id).then(setClaimGroup);
          }
        }).catch(err => {
          dispatch(handleError(err.description));
        });
      }, 5000);
      return () => window.clearInterval(intervalId);
    }
  }, [isTechnicalTemplatePending, claimGroup, dispatch]);
  return [technicalTemplateImportStatus, isTechnicalTemplatePending, changePendingStatus];
};