import { api } from '@rd-web-markets/shared/dist/services/service';
const generalRouteService = {
  get: (route, id) => api.get(`/${route}/${id}`),
  getAll: function (route) {
    let queries = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    return api.get(`/${route}${queries}`);
  },
  update: (route, id, payload) => api.update(`/${route}/${id}`, payload),
  delete: (route, id) => api.delete(`/${route}/${id}`),
  create: function (route) {
    let payload = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return api.create(`/${route}`, payload);
  },
  postFormData: (route, payload) => api.postFormData(`/${route}`, payload)
};
export default generalRouteService;