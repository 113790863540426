import { ChangeHighlighter, FormattedNumberInput, Loading } from '@rd-web-markets/shared/dist/util';
import React, { useState, useCallback, useEffect } from 'react'
import { useParams, useHistory, Link } from 'react-router-dom';
import { Card, Form, Row, Col, Breadcrumb } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import claimGroupService from '@rd-web-markets/shared/dist/services/claim_group.service';
import { trackingTasksService } from 'src/services/task_management/claim_project_reports/tracking_tasks.service';
import { SaveButton, CancelButton, AddButton } from '@rd-web-markets/shared/dist/util/buttons';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import MasterSidebar from '@rd-web-markets/shared/dist/util/MasterSidebar';
import { UserService } from '@rd-web-markets/shared/dist/services/user.service';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import { trackingTaskTagsService } from '@services/task_management/tracking_tasks/trackingTaskTags.service';
import TaskLists from './TaskLists';
import FileRow from './FileRow';

import ChipTag from '@rd-web-markets/shared/dist/util/ChipTag';

const TimeTrackingTaskFormPage = ({handleToaster, accountType}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { claimProjectReportId, taskId } = useParams();
  let { claimGroupId } = useParams();
  const [claimGroup, setClaimGroup, resetClaimGroup] = claimGroupService.useGetClaimGroup(claimGroupId);
  const [users, setUsers] = useState();
  const user = useSelector((state) => state.auth.user);
  const [tag, setTag] = useState({name: ''});

  const completion_statuses = ['not_started', 'in_progress', 'done'];
  const classifications = ['task', 'file_request'];
  const sub_classifications = [
    'kick_off',
    'meetings',
    'development',
    'bugs',
    'research',
    'travel',
    'planning',
    'admin',
    'communication',
    'task',
    'technical'
  ];
  const [task, setTask] = useState({
    title: '',
    classification: '',
    sub_classification: '',
    completion_status: completion_statuses[0],
    effort: 0,
    worked: 0,
    description: '',
    tracking_task_tags: [],
    user_id: user.id,
    assignee_id: null,
    tracking_task_comments: [],
    attachments: [],
    logs: [],
    tracking_task_files: [],
  });

  useEffect(() => {
    (async () => { // fetch users
      try {
        const response = await UserService.all('admin');
        setUsers(response.users);
        if (taskId) {
          const task = await trackingTasksService.get(claimProjectReportId, taskId);
          setTask(task);
          if (!claimGroupId) {
            resetClaimGroup(task.claim_project_report.claim_group.id);
          }
        }
      } catch(e) {
        dispatch(handleError(e))
      }
    })();
  }, [dispatch, claimGroupId, claimProjectReportId, resetClaimGroup, taskId]);

  const goBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const saveTask = useErrorHandling(useCallback(async (e) => {
    e.preventDefault();
    task.tracking_task_comments_attributes = task.tracking_task_comments;
    task.tracking_task_tags_attributes = task.tracking_task_tags;
    if (taskId) {
      await trackingTasksService.update(claimProjectReportId, taskId, task);
    } else {
      await trackingTasksService.create(claimProjectReportId, task);
    }
    goBack();
  }, [claimProjectReportId, task, goBack, taskId]));

  const addFile = () => {
    if (taskId) {
      const today = new Date(); // TODO refactor it to US format
      task.tracking_task_files.push({
        due_date: `${today.getDate()}/${('0' + (today.getMonth() + 1)).slice(-2)}/${String(today.getFullYear())}`,
        file_name: '',
        link: ''
      });
      setTask({ ...task, tracking_task_files: task.tracking_task_files });
    } else {
      handleToaster('To attach files, you must first save the new task.', 'warning');
    }
  };

  const handleFileData = (data, id, fieldName) => {
    const file = task.tracking_task_files[id];
    file[fieldName] = data;
    setTask({ ...task, tracking_task_files: task.tracking_task_files });
  };

  const handleDeleteFile = (id) => {
    task.tracking_task_files.splice(id, 1);
    setTask({ ...task, tracking_task_files: task.tracking_task_files });
  }

  const addTag = useErrorHandling(useCallback(async () => {
    if(taskId) {
      const taskTags = await trackingTaskTagsService.create(claimProjectReportId, taskId, tag);
      setTask({ ...task, tracking_task_tags: [...task.tracking_task_tags, taskTags] });
    } else {
      setTask({ ...task, tracking_task_tags: [...task.tracking_task_tags, { name: tag.name }] });
    }
    
    setTag({name: ''});
  }, [claimProjectReportId, taskId, tag, task]));

  const removeTag = useErrorHandling(useCallback(async (tagId) => {
    if (taskId) {
      await trackingTaskTagsService.delete(claimProjectReportId, taskId, tagId);
    }
    const taskTags = task.tracking_task_tags.filter((tag) => tag.id !== tagId);
    
    setTask({ ...task, tracking_task_tags: [...taskTags] });
  }, [claimProjectReportId, taskId, task]));

  if (!claimGroup || !users) return <Loading/>;

  return (
    <>
      <MasterSidebar accountType={accountType} currentPage='time_tracking/my_tasks' />
      <BreadcrumbsPortal>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${user.account_type}`}}>{t('home')}</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${user.account_type}/list_of_companies`}}>{t('companies')}</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${user.account_type}/time_tracking/companies/${claimGroup.company.id}/claim_groups`}}>{claimGroup.company.name}</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${user.account_type}/time_tracking/claim_groups/${claimGroup.id}/claim_project_reports`}}>{claimGroup.name}</Breadcrumb.Item>
          <Breadcrumb.Item active>{t(taskId ? 'edit_task' : 'add_task')}</Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbsPortal>
      <Card>
        <Form onSubmit={saveTask}>
          <Card.Body>
            <Form.Group className='mb-2' as={Row}>
              <Form.Group as={Col} md={8}>
                <Form.Control
                  as='input'
                  onChange={(event) => setTask({ ...task, title: event.target.value })}
                  size='md'
                  type='text'
                  value={task.title}
                  placeholder={t('enter_task_title')}
                />
              </Form.Group>
              <Form.Group as={Col} md={{span: 3, offset: 1}}>
                <Form.Control
                  as='select'
                  onChange={(event) => setTask({ ...task, assignee_id: event.target.value })}
                  size='md'
                  type='text'
                  value={task.assignee_id || 0}
                >
                  <option value={0} disabled>{t('choose_assignee')}</option>
                  {users.map((user) => (
                    <option
                      value={user.id}
                      key={user.id}
                    >
                      {user.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form.Group>
            <Form.Group className='mb-2' as={Row}>
              <Form.Label column='md' md={2}>
                {t('task_classification')}
              </Form.Label>
              <Form.Control
                as='select'
                className='col-md-1'
                onChange={(event) => setTask({ ...task, classification: event.target.value })}
                size='md'
                type='text'
                value={task.classification || ''}
              >
                <option value={''} disabled>{t('select_task_classification')}</option>
                {classifications.map((classification) => <option value={classification} key={classification}>{t(classification)}</option>)}
              </Form.Control>
              <Form.Group as={Col} md={{span: 4, offset: 1}} className='mb-0'>
                <Form.Group as={Row} className='mb-0' style={{paddingRight: '15px'}}>
                  <Form.Group as={Col} column='md' md={9} className='mb-0'>
                    {task.tracking_task_tags.map((tag, index) => (
                      <ChipTag
                        onDelete={() => removeTag(tag.id)}
                        text={tag.name}
                        key={index}
                        variant='primary'
                      />    
                    ))}
                  </Form.Group>
                  {task.tracking_task_tags.length < 5 &&
                    <>
                      <Form.Group as={Col} md={3} className='mb-0'>
                        <Form.Group as={Row} className='mb-0'>
                          <Form.Control
                            as='input'
                            value={tag.name}
                            onChange={e => setTag({name: e.target.value, tracking_task_id: task.id})}
                            placeholder={t('enter_new_tag')}
                          />
                        </Form.Group>
                        <Form.Group as={Row} className='mb-0 mt-1'>
                          <AddButton
                            variant='info'
                            onClick={addTag}
                            text={t('add_tag')}
                            style={{width: '100%'}}
                          />
                        </Form.Group>
                      </Form.Group>
                    </>
                  }
                </Form.Group>
              </Form.Group>
              <Form.Group as={Col} md={{span: 3, offset: 1}} className='mb-0'>
                <Form.Label>
                  {t('creator')}: <span className='material-icons'>account_circle</span>{users.find((user) => user.id === task.user_id).name}
                </Form.Label>
              </Form.Group>
            </Form.Group>
            {task.classification === 'task' && <Form.Group className='mb-2' as={Row}>
              <Form.Label column='md' md={2}>
                {t('sub_classification')}
              </Form.Label>
              <Form.Control
                as='select'
                className='col-md-1'
                onChange={(event) => setTask({ ...task, sub_classification: event.target.value })}
                size='md'
                type='text'
                value={task.sub_classification || ''}
              >
                <option value={''} disabled>{t('select_sub_classification')}</option>
                {sub_classifications.map((sub_classification) => <option value={sub_classification} key={sub_classification}>{t(sub_classification)}</option>)}
              </Form.Control>
            </Form.Group>}
            <Form.Group className='mb-2' as={Row}>
              <Form.Label column='md' md={2}>
                {t('completion_status')}
              </Form.Label>
              <Form.Control
                as='select'
                className='col-md-1'
                onChange={(event) => setTask({ ...task, completion_status: event.target.value })}
                size='md'
                type='text'
                value={task.completion_status || completion_statuses[0]}
              >
                {completion_statuses.map((completion_status) => <option value={completion_status} key={completion_status}>{t(completion_status)}</option>)}
              </Form.Control>
            </Form.Group>
            {task.classification === 'file_request' && <>
              <Form.Group className='mb-2' as={Row}>
                <Form.Label column='md'>
                  {t('list_of_requested_files')}
                </Form.Label>
              </Form.Group>
              {task.tracking_task_files.map((file, id) => {
                return (
                  <FileRow
                    file={file}
                    key={id}
                    claimProjectReportId={claimProjectReportId}
                    taskId={taskId}
                    id={id}
                    handleFileData={handleFileData}
                    handleDelete={handleDeleteFile}
                  />
                );
              })}
              <Form.Group className='mb-2' as={Row}>
                <AddButton
                  variant='info'
                  onClick={addFile}
                  text={t('add_file')}
                  style={{marginLeft: '15px'}}
                />
              </Form.Group>
            </>}
            <Form.Group className='mb-2' as={Row}>
              <Form.Label column='md' md={2}>
                {t('effort')}
              </Form.Label>
              <ChangeHighlighter 
                as={FormattedNumberInput} 
                type='text' 
                onChange={(event) => setTask({ ...task, effort: event.target.value })} 
                object={task} 
                name={'effort'} 
                decimalScale={1}
                onlyPositiveNumbers={true}
              />
            </Form.Group>
            <Form.Group className='mb-2' as={Row}>
              <Form.Label column='md' md={2}>
                {t('worked')}
              </Form.Label>
              <ChangeHighlighter 
                as={FormattedNumberInput} 
                type='text' 
                onChange={(event) => setTask({ ...task, worked: event.target.value })} 
                object={task} 
                name={'worked'} 
                decimalScale={1}
                onlyPositiveNumbers={true}
              />
            </Form.Group>
            {task.classification === 'task' && <>
              <Form.Group className='mb-2' as={Row}>
                <Form.Label column='md' md={1}>
                  {t('description')}
                </Form.Label>
              </Form.Group>
              <Form.Group className='mb-2' as={Row}>
                <Form.Group as={Col} className='col-md-8'>
                  <Form.Control
                    as='textarea'
                    onChange={(event) => setTask({ ...task, description: event.target.value })}
                    size='md'
                    type='text'
                    value={task.description}
                    style={{ resize: 'none' }}
                    rows='10'
                  />
                </Form.Group>
              </Form.Group>
            </>}
            <Form.Group as={Row} className='col-md-8' style={{justifyContent: 'flex-end'}}>
              <SaveButton className='me-2'/>
              <CancelButton onClick={goBack}/>
            </Form.Group>
            <Form.Group className='mb-2' as={Row}>
              <TaskLists task={task} setTask={setTask} handleToaster={handleToaster} className='col-md-8'/>
            </Form.Group>
          </Card.Body>
        </Form>
      </Card>
    </>
  )
}

export default TimeTrackingTaskFormPage;
