import exportCostTemplateService from '@services/claim/export_cost_template.service'
import React from 'react'

export default function ClaimCostTemplateLink({ claim }) {
  return (
    <div
        title='The email includes the cost template file' 
        className='d-flex align-items-center p-2 cursor-pointer' 
        style={{border: '1px solid lightgrey'}}
        onClick={() => exportCostTemplateService.get(claim.id)}
      >
        <span className='me-2'> Cost Template {claim.label} </span>
        <span className="material-icons" style={{width: '2rem', height: '2rem', color: 'green'}}>description</span>
      </div>
  )
}
