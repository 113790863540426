import React from 'react'
import { Row, Col, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const CompanyGroupEditForm = ({
  companyGroup,
  handleChange
}) => {
  const user = useSelector((state) => state.auth.user);
  const { t } = useTranslation();
  // for IT market we don't show section CompanyClaimGroupEditFormFields, where is placed questionnaires select
  // but we need it, so we render it there if needed
  return (
    <Form>
      <Form.Group className='mb-2' as={Row}>
        <Form.Label column="md" md={3}>
          Company Group Name
        </Form.Label>
        <Col md={6}>
          <Form.Control
            value={companyGroup.name || ''}
            name="name"
            onChange={handleChange}
            size="md"
            type="text"
          />
        </Col>
      </Form.Group>
      <Form.Group className="mt-4" as={Row}>
        <Form.Label column="md" md={3}>{t('Notes')}</Form.Label>
        <Col md={6}>
          <Form.Control
            as='textarea'
            rows={3}
            name='notes'
            value={companyGroup.notes}
            onChange={handleChange}
          />
        </Col>
      </Form.Group>
    </Form>
  );
};

export default CompanyGroupEditForm
