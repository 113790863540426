import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
const themesService = {
  create(companyId, theme) {
    return fetch(`/api/companies/${companyId}/themes`, {
      ...request.post,
      body: JSON.stringify({
        theme
      })
    });
  }
};
export default themesService;