import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Tab, Tabs, Card } from 'react-bootstrap';
import { Button } from '@rd-web-markets/shared/dist/util/buttons';
import PastSentEmailList from '@components/shared/claim/emailPreview/PastSentEmailList';
import ClaimClientCostTemplateConfigure from '@components/shared/claim/templates/ClaimClientCostTemplateConfigure';
import Toast from '@components/util/Toast';
import SelectConsultants from '@rd-web-markets/shared/dist/company/SelectConsultants';
import EmailPreviewModalPreview from '@components/shared/claim/emailPreview/EmailPreviewModalPreview';
import claimScheduleService from '@rd-web-markets/shared/dist/services/claim_schedule.service';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import { fetchAndSetAdmins, fetchAndSetConsultants } from 'src/effects/user.effects';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import EmailPreviewModalRecipients from '@components/shared/claim/emailPreview/EmailPreviewModalRecipients';

const ClientCostEmailPage = ({
  claim,
  claimGroup,
  setClaimGroup,
  resetCompanyAndClaimGroup,
  handleToaster
}) => {
  const [showTemplateSavedToast, setShowTemplateSavedToast] = useState(false);
  const [showTemplateRemovedToast, setShowTemplateRemovedToast] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [consultants, setConsultants] = useState(null)
  const [admins, setAdmins] = useState(null)
  const [claimSchedule, setClaimSchedule] = useState(null);
  const [template, setTemplate] = useState(null);
  const [showConsultantsSelect, setShowConsultantsSelect] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    resetCompanyAndClaimGroup()
  }, [resetCompanyAndClaimGroup])

  const toggleShowConsultantSelect = useCallback(() => {
    setShowConsultantsSelect(!showConsultantsSelect)
  }, [showConsultantsSelect])

  const renderEditComponent = onTemplateUpdate =>
    <ClaimClientCostTemplateConfigure
      claimId={claim.id}
      claimGroup={claimGroup}
      onTemplateSave={() => {
        onTemplateUpdate()
        setShowTemplateSavedToast(true)
      }}
      onTemplateAttachmentChange={() => {
        resetCompanyAndClaimGroup();
      }}
      onTemplateRemove={() => {
        onTemplateUpdate()
        setShowTemplateRemovedToast(true)
      }}
    />

  const fetchClaimSchedule = useErrorHandling(useCallback(async () => {
    // allows us to dynamically render the updated template and contacts even if
    // the parent component does not know about the claim schedule
    const claimScheduleResult = await claimScheduleService.get(claimGroup.id, claimGroup.claim_schedule.id);
    setClaimSchedule({ ...claimScheduleResult })
    setTemplate(claimScheduleResult.active_client_cost_template)
  }, [claimGroup]));

  useEffect(() => {
    claimGroup && fetchClaimSchedule()
  }, [claimGroup, fetchClaimSchedule])

  const sendClientCostEmail = useErrorHandling(async () => {
    setIsLoading(true);
    await claimScheduleService.sendClientCostMail(claimSchedule.id);
    handleToaster('Email Successfully Sent');
  }, ()=> setIsLoading(false));

  useEffect(() => {
    fetchAndSetConsultants(setConsultants, dispatch, handleError)
    fetchAndSetAdmins(setAdmins, dispatch, handleError)
  }, [claimGroup, dispatch]);

  return (<>
    <Toast show={showTemplateSavedToast} message={'Template saved!'} onClose={() => setShowTemplateSavedToast(false)} />
    <Toast show={showTemplateRemovedToast} message={'Template removed!'} onClose={() => setShowTemplateRemovedToast(false)} />

    <h1 className='text-primary mb-4'>Client Cost Email</h1>

    <Tabs defaultActiveKey="configure" className="mb-3">
      <Tab eventKey="configure" title='Configure Client cost email'>
        {claimGroup && renderEditComponent(fetchClaimSchedule)}
      </Tab>
      <Tab eventKey="preview" title="Preview">
        {!template && <h2 className='text-primary text-center'>Please configure Client cost email template.</h2>}
        {!!template && claimGroup &&
          <>
            <Row className='mb-3 ps-3 '>
              {showConsultantsSelect &&
                <div className="mb-5">
                  <SelectConsultants
                    size="lg"
                    claimGroup={claimGroup}
                    consultantsAndAdmins={[...consultants, ...admins]}
                    onClaimGroupConsultantRolesChange={resetCompanyAndClaimGroup}
                  />
                </div>
              }
            </Row>
            <Card>
              <Card.Header>
                <EmailPreviewModalRecipients
                  setClaimGroup={setClaimGroup}
                  claimGroup={claimGroup}
                  emailType={'cost'}
                  claimSchedule={claimGroup.claim_schedule}
                  onClaimGroupRolesupdate={resetCompanyAndClaimGroup}                       
                />
              </Card.Header>
              <Card.Body>
                <EmailPreviewModalPreview claimGroup={claimGroup} template={template} />
                {claimSchedule && <PastSentEmailList style={{ width: '100%' }} claimGroup={claimGroup} pastSentTemplatesList={claimGroup.claim_schedule.client_cost_templates} />}
                <Button className='float-end' variant="success" icon='mail' disabled={!claimSchedule} onClick={sendClientCostEmail} loading={isLoading}>Send Cost Email</Button>
              </Card.Body>
            </Card>
          </>
        }
      </Tab>
    </Tabs>
  </>)
};

export default ClientCostEmailPage;
