import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
const financialNotesService = {
  delete(claimId, noteId) {
    return fetch(`/api/claims/${claimId}/financial_notes/${noteId}`, request.delete);
  },
  create(claim_id, financial_note) {
    return fetch(`/api/claims/${claim_id}/financial_notes`, {
      ...request.post,
      body: JSON.stringify({
        financial_note
      })
    });
  },
  edit(claim_id, financial_note) {
    return fetch(`/api/claims/${claim_id}/financial_notes/${financial_note.id}`, {
      ...request.put,
      body: JSON.stringify({
        financial_note
      })
    });
  }
};
export default financialNotesService;