import React from 'react'
import { Nav, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SHOW_ADD_PROJECTS_BUTTON_ON_MASTER_PAGE } from '@rd-web-markets/market/dist/constants';

const ClaimGroupNavigation = ({company, claimGroups, claimGroupId, accountType, claim_group_id}) => {
  const user = useSelector((state) => state.auth.user);
  const { t } = useTranslation();

  const boldClaimGroupName = (claimGroup, index) => {
    if ((claimGroup.id == claim_group_id || (!claim_group_id && index === 0))) {
      return (
        <b>{claimGroup.name}</b>
      )
    } else { 
      return (
        <>{claimGroup.name}</>
      )
    }
  }

  if (process.env.PUBLIC_URL === '/be') {
    const currentYear = (new Date()).getFullYear().toString()
    const currentClaimGroup = claimGroups.find(cg => cg.name.includes(currentYear))
    if (currentClaimGroup) {
      const claimGroupsExceptCurrent = claimGroups.filter(cg => cg.id !== currentClaimGroup?.id)
      claimGroupsExceptCurrent.sort((cg1, cg2) => cg1.name <= cg2.name ? -1 : 1)
      claimGroups = claimGroupsExceptCurrent
      claimGroups.unshift(currentClaimGroup) 
    }
  }

  return (
    <Nav
      variant="tabs"
      defaultActiveKey={Number(claimGroupId)}
    >
      {claimGroups.map((claimGroup, index) => {
        return (
          <Nav.Item key={claimGroup.id}>
            <Nav.Link as={Link} to={`/${accountType}/companies/${company.id}/${claimGroup.id}/master`} eventKey={claimGroup.id}>
              { boldClaimGroupName(claimGroup, index) }
              { claimGroup.is_locked ?
                <OverlayTrigger
                  placement='top'
                  overlay={
                    <Tooltip>
                      {claimGroup.is_locked && 'Claim Group is locked'}
                    </Tooltip>
                  }
                >
                  <span className="material-icons">lock</span>
                </OverlayTrigger> : null
              }
            </Nav.Link>
          </Nav.Item>
        );
      })}
      {SHOW_ADD_PROJECTS_BUTTON_ON_MASTER_PAGE && <Link className='h5 ms-auto my-auto' to={`/${user.account_type}/claim_groups/${claimGroupId}/project_overview`}>+ {t('project')}</Link>}
    </Nav>
  )
}

export default ClaimGroupNavigation
