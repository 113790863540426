const editorMenuUtils = {
  addToggleButton(_ref) {
    let {
      editor,
      moduleInstance,
      toggleStatePropertyName,
      buttonName,
      textIfToggled,
      textIfNotToggled,
      onToggleCallback
    } = _ref;
    const button = {
      text: moduleInstance[toggleStatePropertyName] ? textIfToggled : textIfNotToggled,
      title: 'some_title',
      onAction: function (_) {
        button.toggleState();
        onToggleCallback(moduleInstance[toggleStatePropertyName]);
      },
      toggleState: function (_) {
        // Check this page and NOTE the "." in the expression: https://developer.mozilla.org/en-US/docs/Web/API/Document/evaluate
        const xpath = moduleInstance[toggleStatePropertyName] ? `.//*[text()='${textIfToggled}']` : `.//*[text()='${textIfNotToggled}']`;
        const buttonInstance = document.evaluate(xpath, editor.getContainer(), null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
        moduleInstance[toggleStatePropertyName] = !moduleInstance[toggleStatePropertyName];
        buttonInstance.innerHTML = moduleInstance[toggleStatePropertyName] ? textIfToggled : textIfNotToggled;
      }
    };
    editor.ui.registry.addButton(buttonName, button);
  }
};
export default editorMenuUtils;