import { Loading } from '@rd-web-markets/shared/dist/util';
import { Table } from '@rd-web-markets/shared/dist/util';
import { Card, Row, Col, Breadcrumb, Form } from 'react-bootstrap';
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import rndReportService from '@rd-web-markets/shared/dist/services/report_templates/rnd_report.service';
import { Button, DeleteButton } from '@rd-web-markets/shared/dist/util/buttons';
import { Link, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import CompanyReportPreview from '@rd-web-markets/shared/dist/reportPreview/CompanyReportPreview';
import { Loader } from '@rd-web-markets/shared/dist/util/Loader';
import SelectProjectsPopup from '@components/shared/rnd_report/SelectProjectsPopup';
import EditDynamicFieldsModal from '@components/shared/rnd_report/EditDynamicFieldsModal';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import { createTimezonedDate } from '@rd-web-markets/shared/dist/util/date';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import SidebarPortal from '@rd-web-markets/shared/dist/util/SidebarPortal';
import { storeMultipleClaimProjectReports } from '@rd-web-markets/shared/dist/store/features/claimProjectReportsSlice';
import ReportPreviewControls from '@rd-web-markets/shared/dist/reportPreview/ReportPreviewControls';
import EditProjectReportSliceModal from '@rd-web-markets/market/dist/reportPreview/EditProjectReportSliceModal';
import reportTemplateService from '@rd-web-markets/shared/dist/services/report_template.service';

const RndReportsPage = ({handleToaster, accountType}) => {
  const { report_template_id } = useParams();
  const dispatch = useDispatch();
  const [reportTemplate, setReportTemplate] = useState(null);
  const [aReportGenerationIsPending, setAReportGenerationIsPending] = useState(false);
  const [pollIntervalId, setPollIntervalId] = useState(null);
  
  const [isUnderReview, setIsUnderReview] = useState(false);
  const [claimId, setClaimId] = useState(null)
  const { search } = useLocation(); //Required for Italy and UK
  const reportType = new URLSearchParams(search).get('report_type');
  const [shouldShowComments, setShouldShowComments] = useState(true)
  
  const previewRndReportFinalVersion = rndReportService.usePreviewRndReportFinalVersion({ reportTemplateId: report_template_id, claimId })
  const previewRndReportHighlightsVersion = rndReportService.usePreviewRndReportHighlightsVersion({
    reportTemplateId: report_template_id,
    claimId,
    shouldShowComments: isUnderReview && shouldShowComments
  })

  const anyProjectInDossier = () => {    
    if (process.env.PUBLIC_URL !== '/it') return false;
    
    const italyReportType = {
      inexpert_declaration_dossier: 'own',
      expert_declaration_dossier: 'expert',
    };

    const projects = reportTemplate.claim_group.claim_project_reports.filter((p) => reportType !== 'technical' ? p.certification_method === italyReportType[reportType] && p['include_in_' + reportType + '_report']: {} )
    return projects?.length === 0
  }

  
  const isPdfGenerationPending = useCallback(report => 
    ['pending', 'not_started'].includes(report.pdf_generation_status),
    []
  )

  const fetchRnDReportTemplate = useErrorHandling(useCallback(async () => {
    const template = await reportTemplateService.get(report_template_id);
    setIsUnderReview(!!template.claim_group.active_change_set)
  
    const atLeastOneIsPending = template.non_rnd_reports_snapshots.some(
      report => isPdfGenerationPending(report)
    );

    console.log('store: ', template.claim_group.claim_project_reports)
    dispatch(storeMultipleClaimProjectReports(template.claim_group.claim_project_reports))

    setAReportGenerationIsPending(atLeastOneIsPending);
    setReportTemplate(template);
  }, [isPdfGenerationPending, report_template_id]))

  useEffect(() => {
    if (!aReportGenerationIsPending) {
      fetchRnDReportTemplate();
      if (pollIntervalId) {
        // clear if any interval previously started
        window.clearInterval(pollIntervalId);
        setPollIntervalId(null);
        handleToaster('Report PDF generation finished.');
      }
    } else {
      if (!pollIntervalId) {
        const intervalId = window.setInterval(() => {
          fetchRnDReportTemplate();
        }, 2000);
  
        setPollIntervalId(intervalId);
      }
    }

    // clear any left interval on component dismount
    return () => window.clearInterval(pollIntervalId);
  }, [fetchRnDReportTemplate, pollIntervalId, aReportGenerationIsPending, handleToaster, report_template_id]);

  const deleteReport = useCallback(
    async (report_template_id, id) => {
      if (window.confirm('Are you sure you wish to delete this report?')) {
        try {
          await rndReportService.delete(report_template_id, id);
          await fetchRnDReportTemplate();
        } catch (error) {
          dispatch(handleError(error));
        }
      }
    },
    [dispatch, fetchRnDReportTemplate]
  );

  const generateReport = async (report_version) => {
    try {
      const showComments = report_version !== 'final' ? shouldShowComments : false
      await rndReportService.create(reportTemplate.id, report_version, reportType, claimId, showComments);
      const template = await reportTemplateService.get(report_template_id);
      setReportTemplate(template);
      setAReportGenerationIsPending(true);
    } catch(error) {
      dispatch(handleError(error));
    }
  }

  const changeClaimId = async (event) => {
    setClaimId(event.target.value)
  }

  if(!reportTemplate) return <Loading />

  return (
    <>
      
      <SidebarPortal headerItem={{link: `companies/${reportTemplate.company.id}/${reportTemplate.claim_group.id}/report_template`, text: reportTemplate.company.name}} />
      <BreadcrumbsPortal>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${accountType}`}}>Home</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${accountType}/companies/${reportTemplate.company.id}/master`}}>
            {reportTemplate.company.name}
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} active>{reportTemplate.name}</Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbsPortal>
      
      <ReportPreviewControls
        isGenerateButtonDisabled={aReportGenerationIsPending || anyProjectInDossier()}
        aReportGenerationIsPending={aReportGenerationIsPending}
        generateReport={generateReport}
        isUnderReview={isUnderReview}
        claimGroupRndReportTemplate={reportTemplate}
        accountType={accountType}
        nonRndReportSnapshots={reportTemplate.non_rnd_reports_snapshots}
        reportType={reportType}
        getRndReportDownloadLink={(rndReport) => `/api/report_templates/${report_template_id}/rnd_reports/${rndReport.id}`}
        deleteRndReport={rndReport => deleteReport(report_template_id, rndReport.id)}
      />
      <Row className="text-center mt-5">
        <Card className="border-0 ms-auto me-auto">
          <Card.Header className="border-0 bg-secondary bg-gradient">
            <h4 className="text-center text-secondary">Preview</h4>
          </Card.Header>
          <Card.Body>
            <CompanyReportPreview
              changeSetContainingObject={reportTemplate.claim_group}
              reportTemplateId={report_template_id}
              claimId={claimId}
              changeClaimId={changeClaimId}
              claimGroup={reportTemplate.claim_group}
              shouldShowComments={isUnderReview && shouldShowComments}
              previewRndReportFinalVersion={previewRndReportFinalVersion}
              previewRndReportHighlightsVersion={previewRndReportHighlightsVersion}
              EditProjectReportSliceModal={EditProjectReportSliceModal}
            /> 
          </Card.Body>
        </Card>
      </Row>
    </>
  )
}

export default RndReportsPage
