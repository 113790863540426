import { useCallback, useEffect, useState } from 'react';
import orderBy from 'lodash.orderby';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { buildService } from '@rd-web-markets/shared/dist/services/service';
const api = buildService('/claim_project_reports/{id}/select_competent_professionals', {
  only: ['all', 'create']
});
const competentProfessionalSelect = {
  useCompetentProfessionalSelect(show) {
    const [data, setData] = useState([]);
    const {
      report_id
    } = useParams();
    const getNonProjectCompetentProfessionals = useErrorHandling(useCallback(async () => {
      competentProfessionalSelect.useGetCompetentProfessionals(setData, report_id);
    }, [report_id]));
    const selectCompetentProfessional = useErrorHandling(useCallback(async (id, data, setTableData, tableData) => {
      const response = await this.post(report_id, {
        id: id
      });
      const newData = data.filter(d => d.id !== Number(id));
      setData([...newData]);
      setTableData([...tableData, response]);
    }, [report_id]));
    useEffect(() => {
      if (show) {
        getNonProjectCompetentProfessionals();
      }
    }, [getNonProjectCompetentProfessionals, show]);
    return [data, selectCompetentProfessional, setData, report_id];
  },
  async useGetCompetentProfessionals(setData, selectedProjectId, searchQuery) {
    const searchParams = {};
    if (searchQuery) {
      const searchQueryTrimmed = searchQuery?.trim();
      if (!searchQueryTrimmed?.length) return;
      searchParams.search = searchQueryTrimmed;
    }
    const response = await competentProfessionalSelect.get(selectedProjectId, searchParams);
    const responseOrderedById = orderBy(response, ['name'], ['asc']);
    setData([...responseOrderedById]);
  },
  async get(selectedProjectId, searchParams) {
    const allNonProjectCompetentProfessionals = await api.all(selectedProjectId, searchParams);
    return allNonProjectCompetentProfessionals;
  },
  async post(selectedProjectId, body) {
    return await api.create(selectedProjectId, body);
  }
};
export default competentProfessionalSelect;