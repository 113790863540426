import React, { useState } from 'react'
import { Accordion, Card, Col, Row, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import CompleteAndSignOff from './CompleteAndSignOff'
import LockUnlockClaim from './LockUnlockClaim'
import { SHOW_SEND_CLAIM_BUTTON } from '@rd-web-markets/market/dist/constants'
import ClaimSelect from '@rd-web-markets/market/dist/claim/ClaimSelect'

export default function CompleteClaim({ claimGroup }) {
  const user = useSelector((state) => state.auth.user);
  const [showSendClaimToGovUk, setShowSendClaimToGovUk] = useState(false)
  const { t } = useTranslation();

  const claimList = claimGroup.claims.map(({ id, name }) => ({ id, name }));

  const showSubmittedToHMRC = () => {
    return (process.env.PUBLIC_URL !== '/it' && process.env.PUBLIC_URL !== '/us' && process.env.PUBLIC_URL !== '/ca')
  }

  const showLockUnlockButton = !!(user.account_type === 'admin' && claimGroup.report_completion && !claimGroup.active_change_set);

  return (
    <>
      <h2 className="text-primary mb-4">{t('complete_claim')}</h2>
      <Card className='shadow border-0'>
       
          <Card.Body>
            <Row>
              <Col md={3} className="text-center pb-2">
                <CompleteAndSignOff claimGroup={claimGroup} />
              </Col>

              { showLockUnlockButton &&
                <Col md={2} className="text-center pb-2">
                  <LockUnlockClaim claimGroup={claimGroup} />
                </Col>
              }
              { showSubmittedToHMRC() && (
                <Col md={showLockUnlockButton ? 2 : 4} className="text-center pb-5">
                  <Button
                    as={Link}
                    to={`/${user.account_type}/claim_groups/${claimGroup.id}/claim_submissions`}
                    variant="info"
                    className='mb-3 pb-3 pt-3 ps-3 pe-3'
                    size="md"
                  >
                    <span className="material-icons md-18">currency_pound</span> {' '} Submitted to HMRC
                  </Button>
                </Col>
              )}
              <Col md={3} className="text-center pb-2">
                <Button
                  as={Link}
                  to={`/${user.account_type}/claim_groups/${claimGroup.id}/kimble_invoices`}
                  variant="info"
                  className='mb-3 pb-3 pt-3 ps-3 pe-3'
                  size="md"
                >
                  <span className="material-icons md-18">add</span> Update Kimble Ready to Invoice
                </Button>
              </Col>

              {SHOW_SEND_CLAIM_BUTTON && (
                <Col md={2} className="text-center pb-2">
                  <Button
                    variant="info"
                    className='mb-3 pb-3 pt-3 ps-3 pe-3'
                    size="md"
                    onClick={() => setShowSendClaimToGovUk(!showSendClaimToGovUk)}
                  >
                    Send claim to Gov.uk
                  </Button>
                </Col>
              )}

            </Row>
            
            {showSendClaimToGovUk && <ClaimSelect claimList={claimList}/>}
          </Card.Body>

      </Card>
    </>
  )
}
