import React, { useCallback, useEffect, useState } from 'react'
import { Link, useParams, useLocation } from 'react-router-dom';
import { Breadcrumb, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Loading } from '@rd-web-markets/shared/dist/util';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import { useDispatch } from 'react-redux';
import NoClaimsPage from '@rd-web-markets/shared/dist/claim_group/NoClaimsPage';
import claimScheduleService from '@rd-web-markets/shared/dist/services/claim_schedule.service';
import claimGroupService from '@rd-web-markets/shared/dist/services/claim_group.service';
import ClaimGroupEngagementTeam from '@components/shared/claim_group/ClaimGroupEngagementTeam';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import ClaimGroupSidebar from '@rd-web-markets/shared/dist/pages/setup/ClaimGroupSidebar';
import { useTranslation } from 'react-i18next';
import { SHOW_SIDE_BAR_IN_CLAIM_GROUP_OVERVIEW } from '@rd-web-markets/market/dist/constants';
import { CompanyService } from '@rd-web-markets/shared/dist/services/company.service';

const ClientClaimGroupEngagementPage = ({ handleToaster, accountType }) => {
  const { search } = useLocation();
  const clientContact = new URLSearchParams(search).get('client-contact-section');
  const currentClaimCostId = new URLSearchParams(search).get('current-claim-cost-id');
  const { company_id, claim_group_id, claim_group_step } = useParams();
  const [company, setCompany, claimGroup, setClaimGroup, resetCompanyAndClaimGroup, updateCompany] = CompanyService.useGetCompanyAndClaimGroup(company_id, claim_group_id);
  const [loading, setLoading] = useState(false);
  const [isPending, setisPending] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(true)
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (clientContact) {
      setHasScrolled(false);
    }
  }, [clientContact, setHasScrolled]);

  const handleAymingContactChange = async (event) => {
    const value = event.target.checked;
    const claimScheduleCopy = { ...claimGroup.claim_schedule }
    claimScheduleCopy[event.target.name] = value
    try {
      const updatedClaimSchedule = await claimScheduleService.update(claimGroup.id, claimScheduleCopy);
      setClaimGroup({...claimGroup, claim_schedule: updatedClaimSchedule});
    } catch (err) {
      dispatch(handleError(err));
    }
  };

  const clientContactRef = element => {
    if(!hasScrolled){
      window.location.hash = '';
      window.location.hash = '#client-contact-engagement-page';
      setHasScrolled(true);
    }
  }

  if(!company || !claimGroup) return <Loading />;

  if(company && !claimGroup) return <NoClaimsPage company={company} accountType={accountType}/>;

  return (
    <>
      <ClaimGroupSidebar
        showMenuItems={SHOW_SIDE_BAR_IN_CLAIM_GROUP_OVERVIEW}
        claimGroup={claimGroup}
        highlightedMenuItem={claim_group_step}
        company={company}
        claims={company.claims}
        accountType={accountType}
      />
      <BreadcrumbsPortal>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/${accountType}` }}>
            {t('home')}
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/${accountType}/companies/` }}>
            {t('companies')}
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/${accountType}/companies/${company.id}/${claimGroup.id}/master` }}>{company.name}</Breadcrumb.Item>
          <Breadcrumb.Item active>{claimGroup?.name?.replace(claimGroup.company.name, '').trim()}</Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbsPortal>

      <div className='d-flex'>
        <h1 className="text-info mb-4 font-weight-bold">{company.name}</h1>
        { company.risk == 'high' &&
          <div className='ms-2' size='lg'>
            <OverlayTrigger placement="right" overlay={<Tooltip>{t('company_sic_code_is_high_risk')}</Tooltip>}>
              <span className='material-icons' style={{color: '#DCA90E'}}>warning</span>
            </OverlayTrigger>
          </div>
        }
      </div>

      <div ref={clientContactRef} id="client-contact-engagement-page">
				<ClaimGroupEngagementTeam
					company={company}
					claimGroup={claimGroup}
					handleAymingContactChange={handleAymingContactChange}
					setClaimGroup={setClaimGroup}
					onClaimScheduleClientContactParticipationChange={resetCompanyAndClaimGroup}
					loading={loading}
					customTitle={'Contacts & Kick-Off Meeting Organization'}
				/>
			</div>
    </>
  )
}

export default ClientClaimGroupEngagementPage
