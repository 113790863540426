import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
export const alertSlice = createSlice({
  name: 'alert',
  initialState: {
    // array of type [{ id: 'some-id-1', message: 'some message' }, { id: 'some-id-2', message: 'some message 2' }]
    alerts: []
  },
  reducers: {
    clearAlerts: state => {
      state.alerts = [];
    },
    setAlerts: (state, action) => {
      state.alerts = action.payload;
    },
    /**
     * This reducer is supposed to be used to handle errors comming from the backend.
     * Sometimes the backend returns a string error message, sometimes an array of error messages.
     * In any case if we catch and throw an ApiError object, we get an array for consistency.
     * However to be able to display separate alerts and clear them, we need to split that array
     * and assign an id to each error message. This id comes from the ApiError id.
     */
    handleError: {
      reducer: (state, action) => {
        try {
          if (action.payload) {
            const {
              id: errorId
            } = action.payload;
            const {
              userFriendlyMessages
            } = action.payload;
            const newAlerts = userFriendlyMessages.map((m, i) => ({
              id: `${errorId}+${i}`,
              message: m
            }));
            state.alerts = [...state.alerts, ...newAlerts];
          }
        } catch (error) {
          const errorId = parseInt(Math.random() * 1000);
          state.alerts = [...state.alerts, ...[{
            id: errorId,
            message: error.message
          }]];
        }
      },
      prepare: error => {
        console.log(error);
        const errorDescription = {
          id: uuidv4(),
          userFriendlyMessages: error.userFriendlyMessages || [error.message]
        };
        return {
          payload: errorDescription
        };
      }
    },
    removeAlert(state, action) {
      const removeId = action.payload;
      const filtered = state.alerts.filter(a => a.id !== removeId);
      state.alerts = [...filtered];
    }
  }
});
export const {
  clearAlerts,
  setAlerts,
  handleError,
  removeAlert
} = alertSlice.actions;
export default alertSlice.reducer;