export { userIsCuttingContent, userIsPastingContent, userIsCuttingOrPasting, userIsPressingCtrlOrMetaOrShiftOnly } from "./domUtils/userActions";
export const ZERO_WIDTH_NO_BREAK_SPACE = '\uFEFF';
export const CHARACTER_WORD_JOINER = '⁠';
export const CHARACTER_NO_BREAK_SPACE = ' ';
export const SIBLING_DIRECTION_PREV = 'previous';
export const SIBLING_DIRECTION_NEXT = 'next';
function parents(node) {
  const nodes = [node];
  for (; node; node = node.parentNode) {
    nodes.unshift(node);
  }
  return nodes;
}
function getOrderedNodesAndOffsets(node1, offset1, node2, offset2) {
  const [firstNode, firstNodePos, secondNode, secondNodePos] = isNodeBeforeAnotherInDom(node1, node2) ? [node2, offset2, node1, offset1] : [node1, offset1, node2, offset2];
  return {
    firstNode,
    firstNodePos,
    secondNode,
    secondNodePos
  };
}
function nodeOrChildTextNodeIfNodeIsANonImageElement(node, offset) {
  // Also in case the first child node is not a text node, we are probably in a case like <div><img /></div>,
  // and we are selecting the whole image. In TinyMCE when we select an image like that, the anchor and focus nodes
  // are the div node, and the anchorOffset is 0, and the focusOffset is 1.
  if (isTextNode(node) || isImageNode(node) || !isTextNode(node?.childNodes[0])) {
    return node;
  }

  // in cases like <div><img /></div> - we wont have any text nodes, so textNode will be null
  const textNode = getTextNodeByCharIndex(node, offset);
  return textNode || node;
}

/**
 * When using a Range object coming from a Selection object, that was not created by our code, but by the browser,
 * the range will always have the startContainer be first in the dom tree, and the endContainer be second (after it).
 * Regardless of the initial direction of the selection. However if we manually set the end container of a range to a node
 * that is above the startContainer in the dom tree, the range will collapse and whaterver is the end container will also become
 * the start container. That is why to make the created range the same as what the browser would create, we need to check which node
 * comes first in the dom tree, and then set the correct start and end nodes.
 * 
 * In addition if we set start or end to be a dom element, the unit tests will throw an "IndexSizeError: Offset out of bound".
 * So the start and end need to be set to text nodes.
 * @param {*} doc 
 * @param {*} startNode for ease of use position in the dom tree does not matter
 * @param {*} startPos for ease of use position in the dom tree does not matter
 * @param {*} endNode 
 * @param {*} endPos 
 * @returns 
 */
function createRange(doc, startNode, startPos, endNode, endPos) {
  const range = doc.createRange();
  const {
    firstNode,
    firstNodePos,
    secondNode,
    secondNodePos
  } = getOrderedNodesAndOffsets(startNode, startPos, endNode, endPos);
  range.setStart(nodeOrChildTextNodeIfNodeIsANonImageElement(firstNode, firstNodePos), firstNodePos);
  range.setEnd(nodeOrChildTextNodeIfNodeIsANonImageElement(secondNode, secondNodePos), secondNodePos);
  return range;
}
export function createSelection(doc, startNode, startPos, endNode, endPos) {
  const range = createRange(doc, startNode, startPos, endNode, endPos);
  const selection = window.getSelection();
  selection.removeAllRanges();
  selection.addRange(range);
  return selection;
}
export const CHARACTER_PUNCTUATION_SPACE = '\u2008';
export function isFirstNodeChildOfSecond(firstNode, secondNode) {
  return secondNode.contains(firstNode) && !secondNode.isSameNode(firstNode);
}
export function getTextNodesInRange(range) {
  const textNodes = [];
  const treeWalker = document.createTreeWalker(range.commonAncestorContainer, NodeFilter.SHOW_TEXT, null, false);
  let currentNode = treeWalker.currentNode;
  while (currentNode) {
    if (range.intersectsNode(currentNode)) {
      textNodes.push(currentNode);
    }
    currentNode = treeWalker.nextNode();
  }
  return textNodes;
}
function createRangeFromArray(doc, array) {
  // makes sure the start node is the first one in the DOM
  let startNode = !isNodeBeforeAnotherInDom(array[0], array[array.length - 1]) ? array[0] : array[array.length - 1];
  let endNode = array[0].isSameNode(startNode) ? array[array.length - 1] : array[0];
  if (!isTextNode(startNode)) {
    const textNode = findFirstNonEmptyTextNode(startNode);
    startNode = textNode || startNode;
  }
  if (!isTextNode(endNode)) {
    const textNode = findLastNonEmptyTextNode(endNode);
    endNode = textNode || endNode;
  }
  return createRange(doc, startNode, 0, endNode, endNode.textContent.length);
}
export function createSelectionDataFromArray(doc, array) {
  const range = createRangeFromArray(doc, array);
  const selectionData = {
    anchorNode: range.startContainer,
    anchorOffset: range.startOffset,
    focusNode: range.endContainer,
    focusOffset: range.endOffset,
    getRangeAt: index => index === 0 ? range : null
  };
  return selectionData;
}
export function matchNodeOrAnyParentElement(node, matchFn) {
  const nodeAndParents = parents(node);
  let currentNode = nodeAndParents.pop();
  while (currentNode) {
    if (matchFn(currentNode)) {
      return currentNode;
    }
    currentNode = nodeAndParents.pop();
  }
  return null;
}
export function selectionContainsElementsWithClassname(selection, className) {
  if (selection.rangeCount === 0) {
    return false;
  }
  const range = selection.getRangeAt(0);
  const elements = range.cloneContents().querySelectorAll(`[class*="${className}"]`);
  return elements.length > 0;
}

/**
 * 
 * @param {*} nodeOrElement 
 * @param {*} direction 'right' | 'previous'
 * @returns 
 */
export function findNextOrPreviousTextNode(nodeOrElement, direction) {
  let nextOrPreviousNode = findAnyNodeOrElementSibling(nodeOrElement, direction);
  if (isTextNode(nextOrPreviousNode)) {
    return nextOrPreviousNode;
  }

  // if the node itself is not a text node, look inside it to find a text node
  return direction === 'next' ? findFirstNonEmptyTextNode(nextOrPreviousNode) : findLastNonEmptyTextNode(nextOrPreviousNode);
}

/**
 * Sometmies a node does not have a previous sibling, but its grandgrandparent does.
 * Tries to loop through the next/previous sibling. If such does not exist - tries the next/previous Element Sibling.
 * If those do not exist as well - goes to the parent node.
 * @param {*} node 
 */
export function findAnyNodeOrElementSibling(node, order) {
  const siblingAttribute = order === 'previous' ? 'previousSibling' : 'nextSibling';
  const elementSiblingAttribute = order === 'previous' ? 'previousElementSibling' : 'nextElementSibling';
  const nodeAndParents = parents(node);
  let currentNode = nodeAndParents.pop();

  // if the node is empty then we cant text edit inside of it
  // there are some cases where the browser just adds empty nodes
  let firstNonEmptySibling = null;
  while (!firstNonEmptySibling) {
    if (!currentNode || currentNode.nodeName === 'BODY') {
      return null;
    }

    // find the first possible non empty sibling
    // if the sibling is empty - then we get its sibling, until there are no more siblings, or one is not empty
    let sibling = currentNode[siblingAttribute] || currentNode[elementSiblingAttribute];
    while (!!sibling && sibling.textContent === '' && sibling.nodeName !== 'IMG' && !findAllChildNodes(sibling, () => true).some(c => c.nodeName === 'IMG')) {
      sibling = sibling[siblingAttribute] || sibling[elementSiblingAttribute];
    }

    // if we don't have a sibling then take the parent
    if (!sibling) {
      currentNode = nodeAndParents.pop();
    } else {
      firstNonEmptySibling = sibling;
    }
  }
  return firstNonEmptySibling;
}

/**
 * Returns a div. The innerHTML of that div is the htmlString.
 * That is because sometimes the string can be pure text without a single DOM element.
 * @param {*} htmlString 
 * @returns 
 */
export function stringToDom(htmlString) {
  if (!htmlString) {
    return null;
  }
  const div = document.createElement('div');
  div.innerHTML = htmlString;
  return div;
}
export function isEmptySelection(selection) {
  return selection.anchorNode.isSameNode(selection.focusNode) && selection.anchorOffset === selection.focusOffset || selection.isCollapsed;
}
export function hasClassOrParentWithClass(node, classToCheck) {
  let currentNode = node;
  while (currentNode) {
    if (currentNode.classList?.contains(classToCheck)) {
      return true;
    }
    currentNode = currentNode.parentNode;
  }
  return false;
}
export function findParent(node, partialParentClass, partialParentStopClass) {
  let currentNode = node.parentNode;

  // checks wether currentNode is defined and not the document node
  while (currentNode?.classList) {
    const classListArray = Array.from(currentNode.classList);
    const matchingClassName = classListArray.find(x => x.includes(partialParentClass));
    const containsStopClass = classListArray.some(c => c.includes(partialParentStopClass));
    if (matchingClassName) {
      return currentNode;
    } else if (containsStopClass) {
      return false;
    }
    currentNode = currentNode.parentNode;
  }
  return false;
}
export function removeElements(node, selector) {
  const matchingElements = Array.from(node.querySelectorAll(selector));
  matchingElements.forEach(e => e.remove());
}
export function findCommonDomAncestor(node1, node2) {
  if (!node1 || !node2) {
    throw new Error('node1 or node2 is not defined!');
  }
  const parents1 = parents(node1);
  const parents2 = parents(node2);

  /**
   * The 0 parent is the document, and the last one is the node itself.
   */
  if (parents1[0] !== parents2[0]) {
    throw new Error('No common ancestor!');
  }
  for (let i = 0; i < parents1.length; i++) {
    if (parents1[i] !== parents2[i]) return parents1[i - 1];
  }

  // if we come here then node1 and node2 are the same
  return node1.parentNode;
}
export function isTextNode(node) {
  return node?.nodeType === Node.TEXT_NODE;
}
export function isImageNode(node) {
  return node.nodeName.toUpperCase() === 'IMG';
}
export function isAnyElementNode(node) {
  return node.nodeType === Node.ELEMENT_NODE;
}

/**
 * Pefrorms a DFS on the childNodes
 * The last possible childNode - the deepest rightmost one is first and everyone else
 * is added in backwards order.
 * @param {*} node 
 * @param {*} onChildNode 
 */
export function loopChildNodes(node, onChildNode) {
  for (var i = 0; i < node.childNodes.length; i++) {
    var child = node.childNodes[i];
    loopChildNodes(child, onChildNode);
    onChildNode(child);
  }
}
export function findAllChildNodes(node, conditionFn) {
  const textNodes = [];
  loopChildNodes(node, childNode => {
    if (conditionFn(childNode)) {
      textNodes.push(childNode);
    }
  });
  return textNodes;
}

/**
 * Finds either the deepeest child node on the left side, or the deepest rightmost child node.
 * By default looks for child nodes that have some non-empty text content.
 * 
 * Examples:
 * <div><p><span>deepest leftmost text node</span></p><span>center</span><p><span>deepest rightmost text node</span></p></div>
 * <div>deepest leftmost<span>center</span>deepest rightmost</div>
 * <div><span class="leftmost"></span>center text node<span class="rightmost"></span></div>
 * 
 * @param {*} node 
 * @param {*} direction 
 * @param {*} conditionFn 
 * @returns 
 */
function findDeepestChildNodeInDirection(node, direction) {
  let conditionFn = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : c => !!c.textContent;
  const childNodes = findAllChildNodes(node, () => true);
  const childNodesArray = [...childNodes];
  // it can have childNodes, but it cannot have children
  // Since findAllChildNodes returns the nodes in backwards oreder (DFS), then based on the direction we
  // need either the first matching node or the last.
  // right: last matching node
  // left: first matching node 
  if (direction === 'right') {
    childNodesArray.reverse();
  }
  return childNodesArray.find(c => !c.children?.length && conditionFn(c));
}
export function findLDeepestRightmostChildTextNodeElement(node) {
  let conditionFn = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : c => !!c.textContent;
  if (isTextNode(node)) {
    return node;
  }
  const childElements = findAllChildNodes(node, n => isTextNode(n));
  return childElements.reverse().find(c => !c.childNodes?.length && conditionFn(c));
}
export function findAllChildTextNodes(node) {
  const textNodes = [];
  loopChildNodes(node, childNode => {
    if (isTextNode(childNode)) {
      textNodes.push(childNode);
    }
  });
  return textNodes;
}
export function findLastNonEmptyTextNode(node) {
  const textNodes = findAllChildTextNodes(node);
  let currentNode = textNodes.pop();
  while (currentNode && !currentNode.textContent) {
    currentNode = textNodes.pop();
  }
  return currentNode;
}
export function findFirstNonEmptyTextNode(node) {
  const textNodes = findAllChildTextNodes(node);
  let currentNode = textNodes.shift();
  while (currentNode && !currentNode.textContent) {
    currentNode = textNodes.shift();
  }
  return currentNode;
}
export function getObjectBoundingClientRect(object) {
  if (isTextNode(object)) {
    const range = document.createRange();
    range.selectNodeContents(object);
    const rects = range.getClientRects();
    return rects[0];
  } else if (object.anchorNode) {
    return object.getRangeAt(0).getBoundingClientRect();
  } else {
    return object.getBoundingClientRect();
  }
}
export function getElementOrParentElementIfTextNode(domNode) {
  if (domNode.nodeType === Node.TEXT_NODE) {
    return domNode.parentElement;
  }
  return domNode;
}
function isInsideSelection(index, selection) {
  return selection.anchorOffset < index && index <= selection.focusOffset || selection.focusOffset < index && index <= selection.anchorOffset;
}

/**
 * When pressing BACKSPACE the selection offset indexes are on the right of the character that will be deleted.
 * If the content ends at index 30, but the offset is at index 31 - then pressing backspace will delete the char at index 30.
 * @param {Integer} contentIndexStart 
 * @param {Integer} contentIndexEnd 
 * @param {Integer} selection 
 * @returns
 */
function isAnySelectionIndexInsideContent(contentIndexStart, contentIndexEnd, selection, isAddingContent) {
  const {
    anchorOffset,
    focusOffset
  } = selection;
  const anchorEnd = isAddingContent ? anchorOffset : anchorOffset - 1;
  const focusEnd = isAddingContent ? focusOffset : focusOffset - 1;
  const anchorOffsetIsInsideContent = contentIndexStart < anchorOffset && anchorEnd <= contentIndexEnd || contentIndexEnd < anchorOffset && anchorEnd <= contentIndexStart;
  const focusOffsetIsInsideContent = contentIndexStart < focusOffset && focusEnd <= contentIndexEnd || contentIndexEnd < focusOffset && focusEnd <= contentIndexStart;
  return anchorOffsetIsInsideContent || focusOffsetIsInsideContent;
}
export function isContentOverlappingSelection(contentIndexStart, contentIndexEnd, selection, isAddingContent) {
  // if the selection is inside the content
  const selectionIndexFallsInsideContent = isAnySelectionIndexInsideContent(contentIndexStart, contentIndexEnd, selection, isAddingContent);
  // or if any part of the content is inside the selection
  const selectionSurroundsContent = isInsideSelection(contentIndexStart, selection) || isInsideSelection(contentIndexEnd, selection);
  return selectionIndexFallsInsideContent || selectionSurroundsContent;
}

/**
 * Checks whether node1 precedes node2 in the DOM tree.
 * 
 * True if the second node precedes the first one in the DOM tree.
 * False if the first node is before the second in the DO Mtree..
 * @param {*} node1 
 * @param {*} node2 
 * @returns 
 */
export function isNodeBeforeAnotherInDom(node1, node2) {
  return node1.compareDocumentPosition(node2) === Node.DOCUMENT_POSITION_PRECEDING;
}

/**
 * Surrounds all text nodes that fall between the startNode:startPos and endNode:endPos,
 * including partially surrounding the startNode and endNode if they are only partially
 * in that range. This function surrounds the text with the given characters.
 * E.g. if the text is <div>Hel{startPos}lo</div><div> wor{endPos}ld</div>" and the startNode is "Hello" and the endNode is "world" then
 * the text will be surrounded with the given characters (e.g. > <) to become <div>Hel>lo</div><div> wor<ld</div>.">Hello world<".
 * The function does not return anything, but instead it modifies the commonAncestor's innerHTML.
 * @param {*} commonAncestor 
 * @param {*} startNode 
 * @param {*} startPos 
 * @param {*} endNode 
 * @param {*} endPos 
 * @param {*} surroundStartCharacter 
 * @param {*} surroundEndCharacter 
 */
export function surroundTextNodesWithCharacters(commonAncestor, startNode, startPos, endNode, endPos, surroundStartCharacter, surroundEndCharacter) {
  if (startNode.nodeType !== Node.TEXT_NODE) {
    startNode = getTextNodeByCharIndex(startNode, startPos);
  }
  if (endNode.nodeType !== Node.TEXT_NODE) {
    endNode = getTextNodeByCharIndex(endNode, endPos);
  }

  // Ensure that startNode is before or equal to endNode in the DOM tree
  if (isNodeBeforeAnotherInDom(startNode, endNode)) {
    [startNode, startPos, endNode, endPos] = [endNode, endPos, startNode, startPos];
  }

  // Create a range that spans the text between the two positions
  const range = createRange(document, startNode, startPos, endNode, endPos);
  const allTextNodes = findAllChildTextNodes(commonAncestor);

  // const tempSelection = createSelection(range)
  // includes partially contained nodes - e.g. startNode and endNode
  const textNodesInRange = allTextNodes.filter(node => node.isSameNode(startNode) ||
  // partially in the range
  range.intersectsNode(node) ||
  // fully inside the range
  node.isSameNode(endNode) // partially in the range
  );
  textNodesInRange.forEach(node => {
    if (node.isSameNode(startNode)) {
      const contentBeforeSurround = node.nodeValue.substring(0, startPos);
      const surroundedContent = `${surroundStartCharacter}${node.nodeValue.substring(startPos)}${surroundEndCharacter}`;
      node.nodeValue = `${contentBeforeSurround}${surroundedContent}`;
    } else if (node.isSameNode(endNode)) {
      const contentAfterSurround = node.nodeValue.substring(endPos);
      const surroundedContent = `${surroundStartCharacter}${node.nodeValue.substring(0, endPos)}${surroundEndCharacter}`;
      node.nodeValue = `${surroundedContent}${contentAfterSurround}`;
    } else {
      node.nodeValue = `${surroundStartCharacter}${node.nodeValue}${surroundEndCharacter}`;
    }
  });
}

/**
 * Surrounds the text content of a text node with a DOM element.
 * Returns the text node on the left of the surrounded text and the text node on the right of the surrounded text.
 * @param {*} textNode 
 * @param {*} createSurroudDomElementFn 
 * @param {*} startIndex 
 * @param {*} endIndex If undefined - surround from start to end of text node text content
 */
export function surroundTextNodeContent(doc, textNode, createSurroudDomElementFn, startIndex) {
  let endIndex = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : undefined;
  const textBeforeIndex = textNode.textContent.substring(0, startIndex);
  const beforeTextNode = doc.createTextNode(textBeforeIndex);
  const contentToSurround = textNode.textContent.substring(startIndex, endIndex);
  const surroundDomElement = createSurroudDomElementFn(contentToSurround);
  let afterTextNode = doc.createTextNode('');
  if (endIndex) {
    const textAfterIndex = textNode.textContent.substr(endIndex);
    afterTextNode = doc.createTextNode(textAfterIndex);
  }
  const {
    parentElement
  } = textNode;
  parentElement.replaceChild(afterTextNode, textNode);
  parentElement.insertBefore(surroundDomElement, afterTextNode);
  parentElement.insertBefore(beforeTextNode, surroundDomElement);
  return [beforeTextNode, afterTextNode];
}
const reachedANode = (childNode, firstNode, secondNode) => {
  if (childNode.isSameNode(firstNode) && firstNode.isSameNode(secondNode)) {
    return 'both';
  }
  if (childNode.isSameNode(firstNode)) {
    return 'first';
  }
  if (childNode.isSameNode(secondNode)) {
    return 'second';
  }
  return false;
};
function getAllNodesWithGivenNameInRange(doc, selection, range) {
  let nodeName = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  let showTextNodes = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : true;
  // There are other settings to show comments, attributes, etc, but we only want element and text nodes.
  // https://developer.mozilla.org/en-US/docs/Web/API/TreeWalker/whatToShow
  let nodesToShow = NodeFilter.SHOW_ELEMENT;
  if (showTextNodes) {
    nodesToShow += NodeFilter.SHOW_TEXT;
  }
  const matchingNodes = [];
  const walker = doc.createTreeWalker(range.commonAncestorContainer, nodesToShow, null, false);
  let node = walker.nextNode();
  while (node) {
    // Check if the current node is fully contained within the range. Only if it is fully contained.
    if (selection.containsNode(node, false)) {
      if (node.nodeName === nodeName || nodeName === null) {
        matchingNodes.push(node);
      }
    }
    node = walker.nextNode();
  }
  return matchingNodes;
}
function surroundAllTextNodesFullyContainedInSelection(doc, cursorSelection, cursorSelection0Range, createSurroudDomElementFn, createClassNamesFn, deleteClass, excludeClass) {
  const textNodes = getAllNodesWithGivenNameInRange(doc, cursorSelection, cursorSelection0Range, '#text').filter(foundNode => !(foundNode.isSameNode(cursorSelection0Range.startContainer) || foundNode.isSameNode(cursorSelection0Range.endContainer)));
  textNodes.forEach(textNode => {
    if (hasClassOrParentWithClass(textNode, deleteClass)) {
      textNode.textContent = '';
    } else if (hasClassOrParentWithClass(textNode, excludeClass)) {
      // skip
    } else {
      surroundTextNodeContent(doc, textNode, createSurroudDomElementFn, 0);
    }
  });
}
function surroundAllImagesFullyContainedInSelection(doc, cursorSelection, cursorSelection0Range, createSurroudDomElementFn, createClassNamesFn, deleteClass, excludeClass) {
  const images = getAllNodesWithGivenNameInRange(doc, cursorSelection, cursorSelection0Range, 'IMG');
  images.forEach(image => {
    if (hasClassOrParentWithClass(image, deleteClass)) {
      image.className += ` ${createClassNamesFn()}`;
    } else if (hasClassOrParentWithClass(image, excludeClass)) {
      // skip
    } else {
      image.className += ` ${createClassNamesFn()}`;
    }
  });
}
function deleteAllMatchingElementsInRange(doc, cursorSelection, cursorSelection0Range, createSurroudDomElementFn, createClassNamesFn, deleteClass, excludeClas) {
  const allElements = getAllNodesWithGivenNameInRange(doc, cursorSelection, cursorSelection0Range, null, false);
  const toDelete = allElements.filter(e => e.classList.contains(deleteClass));
  toDelete.forEach(e => e.remove());
}
function deleteFocusText(focusNode, cursorSelection0Range) {
  focusNode.textContent = `${focusNode.textContent.substring(cursorSelection0Range.endOffset)}`;
}
function deleteAnchorText(anchorNode, cursorSelection0Range) {
  anchorNode.textContent = `${anchorNode.textContent.substring(0, cursorSelection0Range.startOffset)}`;
}

/**
 * 
 * @param {*} anchorOrFocusNode 
 * @param {*} whichNode = 'anchor' | 'focus' | 'both'
 * @param {*} cursorSelection0Range 
 * @param {*} createSurroudDomElementFn 
 * @param {*} createClassNamesFn 
 * @param {*} deleteClass 
 * @param {*} excludeClass 
 * @returns 
 */
function surroundAnchorOrFocusNode(doc, anchorOrFocusNode, whichNode, cursorSelection0Range, createSurroudDomElementFn, createClassNamesFn, deleteClass, excludeClass) {
  let leftSideTextNode = null,
    rightSideTextNode = null;
  if (isImageNode(anchorOrFocusNode) && hasClassOrParentWithClass(anchorOrFocusNode, deleteClass)) {
    anchorOrFocusNode.remove();
  } else if (isImageNode(anchorOrFocusNode) && !hasClassOrParentWithClass(anchorOrFocusNode, excludeClass)) {
    anchorOrFocusNode.className += ` ${createClassNamesFn()}`;
    leftSideTextNode = anchorOrFocusNode;
    rightSideTextNode = anchorOrFocusNode;
  } else if (hasClassOrParentWithClass(anchorOrFocusNode, deleteClass)) {
    if (whichNode === 'anchor') {
      deleteAnchorText(anchorOrFocusNode, cursorSelection0Range);
    } else if (whichNode === 'focus') {
      deleteFocusText(anchorOrFocusNode, cursorSelection0Range);
    } else {
      anchorOrFocusNode.textContent = `${anchorOrFocusNode.textContent.substring(0, cursorSelection0Range.startOffset)}${anchorOrFocusNode.textContent.substring(cursorSelection0Range.endOffset)}`;
    }
    leftSideTextNode = anchorOrFocusNode;
    rightSideTextNode = anchorOrFocusNode;
  } else if (hasClassOrParentWithClass(anchorOrFocusNode, excludeClass)) {
    leftSideTextNode = anchorOrFocusNode;
    rightSideTextNode = anchorOrFocusNode;
  } else if (isTextNode(anchorOrFocusNode)) {
    if (whichNode === 'anchor') {
      [leftSideTextNode] = surroundTextNodeContent(doc, anchorOrFocusNode, createSurroudDomElementFn, cursorSelection0Range.startOffset);
    } else if (whichNode === 'focus') {
      [, rightSideTextNode] = surroundTextNodeContent(doc, anchorOrFocusNode, createSurroudDomElementFn, 0, cursorSelection0Range.endOffset);
    } else {
      [leftSideTextNode, rightSideTextNode] = surroundTextNodeContent(doc, anchorOrFocusNode, createSurroudDomElementFn, cursorSelection0Range.startOffset, cursorSelection0Range.endOffset);
    }
  } else if (isAnyElementNode(anchorOrFocusNode) && anchorOrFocusNode.children?.length) {
    // assume the offsets point to positions between child nodes
    if (whichNode === 'anchor') {
      const surroundElement = createSurroudDomElementFn('');
      Array.from(anchorOrFocusNode.childNodes).forEach((childNode, index) => {
        if (index >= cursorSelection0Range.startOffset) {
          surroundElement.appendChild(childNode);
        }
      });
      if (cursorSelection0Range.startOffset > 0) {
        anchorOrFocusNode.after(anchorOrFocusNode.childNodes[0], surroundElement);
      } else {
        anchorOrFocusNode.prepend(surroundElement);
      }
      const beforeTextNode = doc.createTextNode('');
      anchorOrFocusNode.before(beforeTextNode);
      leftSideTextNode = beforeTextNode;
    } else if (whichNode === 'focus') {
      const surroundElement = createSurroudDomElementFn('');
      Array.from(anchorOrFocusNode.childNodes).forEach((childNode, index) => {
        if (index < cursorSelection0Range.endOffset) {
          surroundElement.appendChild(childNode);
        }
      });
      anchorOrFocusNode.prepend(surroundElement);
      const afterTextNode = doc.createTextNode('');
      anchorOrFocusNode.after(afterTextNode);
      rightSideTextNode = afterTextNode;
    }
  }
  return [leftSideTextNode, rightSideTextNode];
}
export function surroundSelectionTextNodesWithDom(doc, cursorSelection, createSurroudDomElementFn, createClassNamesFn, deleteClass, excludeClass) {
  const cursorSelection0Range = cursorSelection.getRangeAt(0);
  deleteAllMatchingElementsInRange(doc, cursorSelection, cursorSelection0Range, createSurroudDomElementFn, createClassNamesFn, deleteClass, excludeClass);
  surroundAllTextNodesFullyContainedInSelection(doc, cursorSelection, cursorSelection0Range, createSurroudDomElementFn, createClassNamesFn, deleteClass, excludeClass);
  surroundAllImagesFullyContainedInSelection(doc, cursorSelection, cursorSelection0Range, createSurroudDomElementFn, createClassNamesFn, deleteClass, excludeClass);
  const {
    startContainer,
    endContainer
  } = cursorSelection0Range;
  if (startContainer.isSameNode(endContainer)) {
    return surroundAnchorOrFocusNode(doc, startContainer, 'both', cursorSelection0Range, createSurroudDomElementFn, createClassNamesFn, deleteClass, excludeClass);
  } else {
    const [leftSideTextNode] = surroundAnchorOrFocusNode(doc, startContainer, 'anchor', cursorSelection0Range, createSurroudDomElementFn, createClassNamesFn, deleteClass, excludeClass);
    const [, rightSideTextNode] = surroundAnchorOrFocusNode(doc, endContainer, 'focus', cursorSelection0Range, createSurroudDomElementFn, createClassNamesFn, deleteClass, excludeClass);
    return [leftSideTextNode, rightSideTextNode];
  }
}
export function getTextNodeByCharIndex(elementNode, charIndex) {
  const textNodes = findAllChildTextNodes(elementNode).map(t => [t, t.textContent.length]);
  if (!textNodes.length) {
    return null;
  }
  let currentNodeIndex = 0;
  let passedStringLength = textNodes[currentNodeIndex][1];
  while (passedStringLength < charIndex) {
    passedStringLength += textNodes[currentNodeIndex][1];
    currentNodeIndex += 1;
  }
  return textNodes[currentNodeIndex][0];
}
export function stopEvent(event) {
  let stopImmediate = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  event.preventDefault();
  event.stopPropagation();
  stopImmediate && event.stopImmediatePropagation();
}

/**
 * We are looking for adjacent nodes for text editing. If we look for nodes
 * priour to ours in the DOM tree, then the direction should be SIBLING_DIRECTION_PREV.
 * Else the direction should be SIBLING_DIRECTION_NEXT.
 * In addition because we want to do text editting, the adjacent nodes should be the deepest
 * child nodes of the NEXT/PREV sibling. In case we need the PREV sibling, then the deepest rightmost childnode.
 * In case its the NEXT sibling, then the deepest leftmost childnode.
 * 
 * That is because the element to the left may be something like this:
 * <div class="left sibling">text child 1 <span>some content</span> text child 2</div>CURRENT NODE</div class="right node">...</div>
 * In the above example if we are at the (CURRENT NODE) then the previous node which is adjacent to ours is (text child 2).
 * In this case this gives us the text node that is exactly next to our text node on the left.
 * 
 * It is the exact opposite if we want to find the text that is exactly next to our text node on the right.
 * 
 * This works for all kinds of nodes. Most of the time DIV, P, SPAN, etc will have an empty child text node.
 * If they dont, the function will match them. If e.g. we have an IMG node, since it doesnt have any children,
 * the function will match the IMG node.
 */
function findAdjacentElementsInDirection(node, direction, conditionFn) {
  const deepestNodeSide = direction === SIBLING_DIRECTION_PREV ? 'right' : 'left';
  const resultNodes = [];
  let sibling = findAnyNodeOrElementSibling(node, direction);
  // if it is an element with children - e..g <div><span></span><span></span>text_node</div> - we need its last child element,
  // as technicallly it is the first sibling on the left of node
  if (sibling?.children?.length) {
    sibling = findDeepestChildNodeInDirection(sibling, deepestNodeSide);
  }

  // while there is a previous sibling and the condition passes for it
  while (!!sibling && conditionFn(sibling)) {
    resultNodes.push(sibling);
    sibling = findAnyNodeOrElementSibling(sibling, direction);
    // if it is an element with children - e..g <div><span></span><span></span>text_node</div> - we need its last child element,
    // as technicallly it is the first sibling on the left of node
    if (sibling?.children?.length) {
      sibling = findDeepestChildNodeInDirection(sibling, deepestNodeSide);
    }
  }

  // For consistency. If previously we called resultNodes.push, and the content is like so:
  // A, B, C, CURRENT NODE, D, E, F, where each letter is a node, then the resultNodes will:
  //
  // * for direction = SIBLING_DIRECTION_PREV --- [C, B, A]
  // * for direction = SIBLING_DIRECTION_NEXT --- [D, E, F]
  // 
  // As you can see if we then combine the arrays including the current node, we get
  // [C, B, A, CURRENT NODE, D, E, F], which is not consistent with how the nodes appear in the DOM,
  // and if we want to traverse them, we will fail.
  // We call reverse, so if we combine all nodes - prev ajdacent elements, current, next adjacent elements,
  // we get: [A, B, C, CURRENT NODE, D, E, F], which is consistent with how the nodes appear in the DOM.
  if (direction === SIBLING_DIRECTION_PREV) {
    resultNodes.reverse();
  }
  return resultNodes;
}

/**
 * Finds all adjacent ELEMENT nodes for which the conditionFn returns true.
 * <div>1</div><div>2</div><div>current</div><div>3</div><div>4</div>
 * Returns elements in order [div 1, div 2, current, div 3, div 4], assuming conditionFn returns true for all of the divs.
 * 
 * @param {*} node | any node - text or element
 * @param {*} conditionFn | predicate that must return either true or false
 * @returns 
 */
export function findAdjacentElementsWhere(node, conditionFn) {
  const previousAdjacentSiblings = findAdjacentElementsInDirection(node, SIBLING_DIRECTION_PREV, conditionFn);
  const nextAdjacentSiblings = findAdjacentElementsInDirection(node, SIBLING_DIRECTION_NEXT, conditionFn);
  const resultNodes = [...previousAdjacentSiblings, node, ...nextAdjacentSiblings];
  let adjacentNodes = resultNodes;
  const nodeParentLi = matchNodeOrAnyParentElement(node, el => el.nodeName === 'LI');
  if (nodeParentLi) {
    adjacentNodes = resultNodes.filter(n => nodeParentLi.contains(n));
  }
  return adjacentNodes;
}