import React from 'react';
import { Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Button } from '@rd-web-markets/shared/dist/util/buttons';
import { useSelector } from 'react-redux';
import { DeleteButton } from '@rd-web-markets/shared/dist/util/buttons';
import { SHOW_CLAIM_GROUP_TASKS, SHOW_CLAIM_DELETE_BUTTON  } from '@rd-web-markets/market/dist/constants';

const labelStyles = {
  display: 'inline-block',
  padding: '.3em',
  fontSize: '.8em',
  borderRadius: '50%',
  whiteSpace: 'nowrap',
  border: '3px solid'
}

const tdCenter = { textAlign: 'center', verticalAlign: 'middle' };

const ClaimGroupItem = ({claimGroup, loading, deleteClaimGroup}) => {
  const user = useSelector((state) => state.auth.user);
  const { tasks } = claimGroup;

  const renderTaskIconStatus = (status, categoryTitle) => {
    switch(status) {
      case 'not_started':
        return (
          <Badge pill bg="danger" data-testid={`icon-${categoryTitle}-badge-not_started`}>
            <span className="material-icons text-white md-18">close</span>
          </Badge>
        )
      case 'in_progress':
        return (
          <Badge pill bg="warning"  data-testid={`icon-${categoryTitle}-badge-in_progress`}>
            <span className="material-icons text-white md-18" >format_align_center</span>
          </Badge>
        )
      case 'completed':
        return (
          <Badge pill bg="success" data-testid={`icon-${categoryTitle}-badge-success`}>
            <span className="material-icons text-white md-18" >done</span>
          </Badge>
        )
      default:
        <></>
    }
  }

  const renderTasks = () => {
    return (
      tasks.map((t) => {
        return (
          <td style={tdCenter} key={t.category_title}>
            {renderTaskIconStatus(t.status, t.category_title)}
          </td>
        );
      })
    )
  }

  const renderEmptyTable = () => {
    return (
      <>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </>
    )
  }

  return (
    <tr>
      <td>
      { user.account_type !== 'customer'
        ? <Link to={`/${user.account_type}/companies/${claimGroup.company_id}/${claimGroup.id}/master`}>{claimGroup.name}</Link>
        : <>{claimGroup.name}</>
      }
      </td>
      {
        tasks.length && (
          SHOW_CLAIM_GROUP_TASKS ?
            renderTasks()
            :
            renderEmptyTable()
        )
      }

      { user.account_type !== 'customer' && 
        <td>
          <div style={{display: SHOW_CLAIM_GROUP_TASKS ? 'block' : 'none'}}>
            <DeleteButton onClick={() => deleteClaimGroup(claimGroup.id)} loading={loading} />
          </div>
        </td>
      }
      {
        user.account_type === 'customer' && claimGroup.report_template?.id && (
          <Button
            variant="primary"
            as={Link}
            to={`/client/report_template/${claimGroup.report_template.id}`}>
            View Report
          </Button>
        )
      }
    </tr>
  )
}

export default ClaimGroupItem
