import { useCallback, useState, useEffect } from 'react';
import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
export const UserService = {
  useFetchUsersForSelect: function () {
    let type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    const [users, setUsers] = useState([]);
    const fetchUsers = useErrorHandling(useCallback(async () => {
      const response = await UserService.all(type);
      setUsers(response.users.map(user => ({
        value: user.id,
        label: user.name
      })));
    }, [type]));
    useEffect(() => {
      fetchUsers();
    }, [fetchUsers]);
    return [users, setUsers];
  },
  all: type => {
    return fetch(`/api/users/?type=${type}`, request.get);
  },
  get: id => {
    return fetch(`/api/users/${id}`, request.get);
  },
  getAuthorizationPath: () => {
    return fetch('/api/users/authorize_path', request.get);
  },
  initiateAuthorization: (authorizationPath, authenticityToken) => {
    return fetch(authorizationPath, {
      ...request.post,
      body: JSON.stringify({
        authenticity_token: authenticityToken
      })
    }).then(data => {
      console.log(data);
    });
  },
  logout: () => {
    return fetch('/api/users/sign_out.json', request.post);
  },
  delete: id => {
    return fetch(`/api/users/${id}`, request.delete);
  },
  update: user => {
    return fetch(`/api/users/${user.id}`, {
      ...request.put,
      body: JSON.stringify({
        user
      })
    });
  },
  create: user => {
    return fetch('/api/users/', {
      ...request.post,
      body: JSON.stringify({
        user
      })
    });
  },
  confirm: user => {
    return fetch('/api/users/confirm.json', {
      ...request.post,
      body: JSON.stringify({
        user
      })
    });
  },
  getCurrent: function () {
    return fetch('/api/users/current_session.json', request.get);
  },
  generateOtpBackupCodes: function () {
    return fetch('/api/users/otp_backup_codes.json', request.post);
  },
  validateConfirmationToken: token => {
    return fetch(`/api/users/confirm.json?user[confirmation_token]=${token}`, request.get);
  },
  sendInviteEmail: userId => {
    return fetch(`/api/users/send_invite_email/${userId}.json`, request.post);
  },
  updatePassword: user => {
    return fetch('/api/users/password.json', {
      ...request.put,
      body: JSON.stringify({
        user
      })
    });
  },
  sendPasswordResetInstructions: user => {
    return fetch('/api/users/password', {
      method: 'POST',
      body: JSON.stringify({
        user
      })
    });
  },
  signIn: user => {
    return fetch('/api/users/sign_in', {
      method: 'POST',
      body: JSON.stringify({
        user
      })
    });
  }
};