import React, { useCallback, useRef, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Button } from '@rd-web-markets/shared/dist/util/buttons';
import CostTemplateErrorsModal from '@components/modals/CostTemplateErrorsModal';
import { Table } from '@rd-web-markets/shared/dist/util';
import DownloadCostTemplateButton from '@rd-web-markets/market/dist/claim/costSection/DownloadCostTemplateButton';
import { SHOW_DOWNLOAD_TEMPLATE_BUTTON } from '@rd-web-markets/market/dist/constants';
import { createTimezonedDate } from '@rd-web-markets/shared/dist/util/date';
import { UploadButton } from '@rd-web-markets/shared/dist/util/buttons/UploadButton';
import BackgroundJobStatusDisplay from '@rd-web-markets/shared/dist/jobs/BackgroundJobStatusDisplay';
import { UPLOAD_COST_TEMPLATE_BUTTON_NAME } from '@rd-web-markets/market/dist/constants';
import { useTranslation } from 'react-i18next';

const ImportCostTemplateSection = ({user, claimGroup, handleToaster, claim, generateCostTemplate, templateInfo, handleImportCostTemplate, loading, selectedProject}) => {
  const hiddenFileInput = useRef(null);
  const [costDataContainsProjects, setCostDataContainsProjects] = useState(false)
  const { t } = useTranslation();

  const handleClick = (costDataContainsProjects) => {
    setCostDataContainsProjects(costDataContainsProjects)
    hiddenFileInput.current.click();
  };

  const ImportErrorTableHeaders = [ 
    { text: 'Sheet' }, 
    { text: 'Error' }, 
    { text: 'Line' } 
  ];

  const buildImportErrorTableRow = (importError, index) => {
    return {
      key: index,
      columns: [importError.sheet_name, importError.message, importError.line],
    }
  }

  const uploadCostTemplate = async e => {
    if(e.target.files[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      await handleImportCostTemplate(
        {
          file: e.target.files[0],
          with_projects: costDataContainsProjects,
        },
        claim, 
        selectedProject?.id
    );

      hiddenFileInput.current.value = '';
    } else {
      handleToaster('Please upload xlsx file', 'warning')
    }
  }

  const canUploadTemplate = () => {
    if(user.account_type !== 'customer') { 
      return false;
    } else {
      return !claimGroup.user_accesses.find(user_access => user_access.user_id === user.id)['can_upload_cost_template']
    }
  };

  const importTableHeaders = [
    { text: 'Date',
      text: 'Uploaded By',
      text: ''
    }
  ];

  const importTableRows = () => {
    return claim.claim_import_cost_template_infos.filter(im => im.import_status !== 'never_imported').reverse().map((im) => ({
      columns: [
        createTimezonedDate(new Date(im.created_at), true).toCustomLocaleTimeString(),
        im.imported_by,
        <a 
          href={im.download_link.url} target="_blank" rel="noreferrer"
        >
          {im.download_link.filename}
        </a>
      ]
    }));
  }

  return (
    <>
      {/* No need to render the modal's body if the modal is not going to be shown. */}
      
      <Row>
        <Col md={SHOW_DOWNLOAD_TEMPLATE_BUTTON ? 6 : 12} className="text-center">
          <UploadButton
            id="upload-cost-template-button"
            disabled={canUploadTemplate()}
            isImmutableUnderReview={true}
            loading={loading}
            onClick={() => handleClick(false)}
            text={t(UPLOAD_COST_TEMPLATE_BUTTON_NAME)}
          />
          { process.env.PUBLIC_URL === '/uk' &&
            <>
              {/* In the UK we can import templates with project data. For now only in the UK. */}
              <span className="me-4"></span>
              <UploadButton
                id="upload-cost-template-with-projects-button"
                isImmutableUnderReview={true}
                disabled={canUploadTemplate()}
                loading={loading}
                onClick={() => handleClick(true)}
                text='Upload Cost Template With Projects'
              />
            </>
          }
          <input
            style={{ display: 'none' }}
            type="file"
            data-testid='costTemplateUpload'
            ref={hiddenFileInput}
            onChange={(e) => uploadCostTemplate(e, claim)}
          ></input>
          { templateInfo &&
            <BackgroundJobStatusDisplay
              buildErrorTableRowFn={buildImportErrorTableRow}
              errorTableHeaders={ImportErrorTableHeaders}
              errorsArrayPropertyName='import_messages'
              jobStatusObject={templateInfo}
              onSuccessMessage='Template was imported successfully!'
              onErrorMessage='There were errors importing the file.'
              onNeverMessage='Template was never imported'
              onPendingMessage='Template upload is pending'
            />
          }
        </Col>
        {
          SHOW_DOWNLOAD_TEMPLATE_BUTTON &&
            <Col md={6} className="text-center">
            <DownloadCostTemplateButton
              canDownloadTemplate={canUploadTemplate()}
              loading={loading}
              claim={claim}
              selectedProject={selectedProject}
              generateCostTemplate={generateCostTemplate}
            />
          </Col>
        }
      </Row>
      <Row>
        <Col md={{offset: 3, span: 6}} className='text-center'>
          <Table headers={importTableHeaders} rows={importTableRows()} showControls={false} />
        </Col>
      </Row>
    </>

  )
}

export default ImportCostTemplateSection
