import { v4 as uuidv4 } from 'uuid';
import { ZERO_WIDTH_NO_BREAK_SPACE, hasClassOrParentWithClass, isEmptySelection, stopEvent, surroundSelectionTextNodesWithDom, userIsPastingContent } from "@rd-web-markets/shared/dist/util/domUtils";
import DeletionHighlight from "../models/DeletionHighlight";
import DynamicField from "../models/DynamicField";
import AdditionHighlight from '../models/AdditionHighlight';
import { findLastInsertedHighlightElementInDocument, replaceDeletionHighlightWithNewAdditionHighlight } from '../highlighintUtils';
import { setCursorPositionToEndOfElement, setCursorPositionToStartOfElement } from '../cursorUtils';
import selectionUtils from '../selectionUtils';

// WHEN PASTING
function insertAdditionHighlightForPasting(event, editor, contentNode, cursorSelection, predictedInputValueOrNull) {
  /**
   * In order to not create nested addition highlights
   */
  if (hasClassOrParentWithClass(contentNode, 'TrackChanges-Highlight-Addition') && !DeletionHighlight.isSelectionAnchorInHighlight(cursorSelection)) {
    editor.execCommand('mceInsertContent', false, event.key);
    stopEvent(event);
  } else if (!DynamicField.isSelectionAnchorInDeletedDynamicField(contentNode, cursorSelection)) {
    /**
     * If we are inside a deleted dynamic field, lets just not add text at all
     */
    const changeId = uuidv4();
    let contentToAdd = ZERO_WIDTH_NO_BREAK_SPACE;
    const additionHighlight = AdditionHighlight.createHighlightedElement(contentToAdd, changeId);
    editor.execCommand('mceInsertRawHTML', false, additionHighlight.outerHTML);
    let insertedSpan = findLastInsertedHighlightElementInDocument(editor.contentDocument, additionHighlight);
    if (insertedSpan.parentElement.classList?.contains('TrackChanges-Highlight-Deletion')) {
      replaceDeletionHighlightWithNewAdditionHighlight(insertedSpan, changeId, editor);
    }
    insertedSpan = findLastInsertedHighlightElementInDocument(editor.contentDocument, additionHighlight);
    const emptyTextNode = document.createTextNode('');
    insertedSpan.appendChild(emptyTextNode);
    // if we are pasting we should let the event bubble up - so not stopping
    // also we need to delete the empty space added into the addition span and then the pasted text will
    // go correctly into the addition highlight
    setCursorPositionToEndOfElement(editor, emptyTextNode);
  }
}

/**
 * Create an addition highlight in the desired place and place the cursor inside it, so that
 * when the pasting happens the pasted content will fall inside the highlight.
 * @param {*} editor 
 * @param {*} cursorSelection 
 * @param {*} rightSideTextNode 
 */
function prepareForPasting(editor, cursorSelection, rightSideTextNode) {
  const changeId = uuidv4();
  let insertedAdditionElement;
  const offset = selectionUtils.getOffsetIfnodeIsFocusOrAnchor(cursorSelection, rightSideTextNode);
  if (hasClassOrParentWithClass(rightSideTextNode, 'TrackChanges-Highlight-Deletion') && offset !== -1) {
    const textNodeCutContent = rightSideTextNode.textContent.substring(offset);
    rightSideTextNode.textContent = rightSideTextNode.textContent.substring(0, offset);
    rightSideTextNode.parentElement.insertAdjacentHTML('afterend', DeletionHighlight.createHighlightedElement(textNodeCutContent).outerHTML);
    const additionElement = AdditionHighlight.createHighlightedElement(ZERO_WIDTH_NO_BREAK_SPACE, changeId, null, 'div');
    rightSideTextNode.parentElement.insertAdjacentHTML('afterend', additionElement.outerHTML);
    insertedAdditionElement = findLastInsertedHighlightElementInDocument(editor.getDoc(), additionElement);
  }
  if (insertedAdditionElement) {
    setCursorPositionToEndOfElement(editor, insertedAdditionElement);
  } else {
    setCursorPositionToStartOfElement(editor, rightSideTextNode);
  }
  if (!insertedAdditionElement) {
    const additionElement = AdditionHighlight.createHighlightedElement(ZERO_WIDTH_NO_BREAK_SPACE, changeId, null, 'div');
    editor.execCommand('mceInsertRawHTML', false, additionElement.outerHTML);
    const insertedElement = findLastInsertedHighlightElementInDocument(editor.getDoc(), additionElement);
    const emptyTextNode = document.createTextNode('');
    insertedElement.appendChild(emptyTextNode);

    // For some reason, even if we copy paste text from the same editor, the new text is pasted inside a new div that has a BR tag.
    Array.from(insertedElement.childNodes).filter(c => c.nodeName === 'BR').forEach(br => br.remove());
    setCursorPositionToEndOfElement(editor, emptyTextNode); // to get the text node of the span - to be inside the span
  }
}

/**
 * Cutting is handled in onCut.
 * In case we are deleting or we are pasting content or we are pressing enter - we surround the selected text with a deletion highlight.
 * In case we are deleting or pressing Enter - we move the cursor to end of the text node which is on the right of the highlited part.
 * In case we are pasting content - we prepare for pasting by adding an addition highlight dom element to the right and putting the cursor in it.
 * In case we are adding new text - we add a new highlight dom element at the cursor position and put the cursor in that element.
 * @param {*} event 
 * @param {*} editor 
 * @param {*} contentNode 
 * @param {*} cursorSelection 
 * @param {*} predictedInputValueOrNull 
 */
function highlightAdditionAndDeletionChangesWhenSelectionIsNotEmpty(event, editor, contentNode, cursorSelection, predictedInputValueOrNull) {
  // cutting | pasting | event key enter
  if (contentNode.nodeName === 'IMG') {
    contentNode.classList.add(...DeletionHighlight.createDomElementHighlightClasses().split(' '));
    return;
  }
  const [leftSideTextNode, rightSideTextNode] = surroundSelectionTextNodesWithDom(editor.getDoc(), cursorSelection, DeletionHighlight.createHighlightedElement, DeletionHighlight.createDomElementHighlightClasses, 'TrackChanges-Highlight-Addition', 'TrackChanges-Highlight-Deletion');
  prepareForPasting(editor, cursorSelection, rightSideTextNode);
}
export function isPasting(event) {
  return userIsPastingContent(event);
}
export function doPaste(event, editor, cursorSelection, contentNode, predictedInputValueOrNull) {
  if (isEmptySelection(cursorSelection)) {
    insertAdditionHighlightForPasting(event, editor, contentNode, cursorSelection, predictedInputValueOrNull);
  } else {
    highlightAdditionAndDeletionChangesWhenSelectionIsNotEmpty(event, editor, contentNode, cursorSelection, predictedInputValueOrNull);
  }
}