import React, { useEffect, useState } from 'react'
import NewProjects from './NewProjects';
import OngoingProjects from './OngoingProjects';
import { useTranslation } from 'react-i18next';
import { Tabs, Tab } from 'react-bootstrap';

const ProjectOverviewQuestionnairesPage = ({ claimGroup, accountType, setClaimGroup, handleToaster }) => {
  const [activeTabKey, setActiveTabKey] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    setActiveTabKey('new_projects')
  }, []);

  return (
    <Tabs onSelect={(k) => setActiveTabKey(k)} activeKey={activeTabKey} className='mb-3'>
      <Tab eventKey='new_projects' title={t('new_projects')}>
        <NewProjects handleToaster={handleToaster} claimGroup={claimGroup} setClaimGroup={setClaimGroup} />
      </Tab>
      <Tab eventKey='ongoing' title={t('ongoing')}>
        <OngoingProjects claimGroup={claimGroup} accountType={accountType} setClaimGroup={setClaimGroup} handleToaster={handleToaster} />
      </Tab>
    </Tabs>
  )
}

export default ProjectOverviewQuestionnairesPage