import React, { useState } from 'react'
import { Breadcrumb, Row, Col, Button, Card, Table } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import SearchBar from '@components/util/SearchBar';
import { useDispatch } from 'react-redux';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import SelectNewCompanyTable from '@rd-web-markets/shared/dist/company/SelectNewCompanyTable';
import { NEW_COMPANY_NEEDS_COMPANY_NUMBER } from '@rd-web-markets/market/dist/constants';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import SidebarPortal from '@rd-web-markets/shared/dist/util/SidebarPortal';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { kimbleCompanyService } from '@rd-web-markets/shared/dist/services/kimble_company.service';

const NewCompanyPage = ({handleToaster}) => {
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.user);

  const handleSearch = async e => {
    setLoading(true);
    try { 
      const response = await kimbleCompanyService.all(e);
      setCompanies(response);
    } catch(error) {
      dispatch(handleError(error));
    }
    setLoading(false);
  }

  const setCompanyNumber = async (companyNumber, index) => {
    if(companyNumber.length === 8) {
      setLoading(true)
      try {
        const newCompanies = [...companies];
        newCompanies[index] = {...companies[index], number: companyNumber};
        setCompanies(newCompanies);
      } catch(er) { 
        handleToaster('There is no match for the Companies House number you have entered please  review and try again.', 'warning')
      } finally {
        setLoading(false);
      }

    } else { 
      handleToaster('Company Number must be 8 digits', 'warning');
    }
  }

  const updateCompanyNumber = () => {

  }

  const handleSelect = company => {
    history.push(`/${user.account_type}/companies/new/${company.id_in_provider_system}--${company.number}`)
  }

  /**
   * onSelect={handleSelect} - whether company number selection is needed depends on the market
   * it is controlled by showSetCompanyNumber
   */
  const renderCompanies = (
    <Col md={{span: 6, offset: 3}}>
      <SelectNewCompanyTable
        showSetCompanyNumber={NEW_COMPANY_NEEDS_COMPANY_NUMBER}
        companies={companies}
        onSelect={handleSelect}
        onSetCompanyNumber={setCompanyNumber}
      />
    </Col>
  )
  return (
    <>
      <SidebarPortal headerItem={{link: 'companies', text: 'Companies'}} />
      <BreadcrumbsPortal>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${user.account_type}`}}>{t('home')}</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${user.account_type}/companies`}}>{t('companies')}</Breadcrumb.Item>
          <Breadcrumb.Item active>{t('new')}</Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbsPortal>
      
      <Row>
        <Col md={12}>
          <Card>
            <Card.Header className="bg-primary bg-gradient text-white">{t('new_company')}</Card.Header>
            <Card.Body>
              <Row>
                <Col md={{span: 6, offset: 3}}>
                  <SearchBar
                    placeholder={t('start_typing_company_name_to_begin')}
                    onSubmit={handleSearch}
                    loading={loading} />
                </Col>
                {companies.length > 0 && renderCompanies}
              </Row>
            </Card.Body>
            <Card.Footer>
              <Row>
                <Col md={6} className="text-start">
                  <Button as={Link} variant="light" to={`/${user.account_type}/companies/`}>
                    <span className="material-icons md-18">chevron_left</span> {t('back')}
                  </Button>
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default NewCompanyPage
