import React from 'react'
import { Col, Form, Row } from 'react-bootstrap';
import { DatePicker } from '@rd-web-markets/shared/dist/util/date';
import { FormattedNumberInput } from '@rd-web-markets/shared/dist/util/FormattedNumberInput';
import ImmutableUnderReviewFormControl from '@rd-web-markets/shared/dist/util/reviewEditControls/ImmutableUnderReviewFormControl';

const ClaimInternalInoivceDetailForm = ({
  claim,
  handleInternalInvoiceDetailChange,
}) => {
  const { internal_invoice_detail } = claim;
  return (
    <>
      <Form.Group className='mb-2' as={Row}>
        <Form.Label column="md" md={{ span: 4, offset: 1 }}>
          Estimated Benefit Amount
        </Form.Label>
        <Col md={6}>
          <ImmutableUnderReviewFormControl
            as={FormattedNumberInput}
            customInput={Form.Control}
            onChange={handleInternalInvoiceDetailChange}
            name="estimated_benefit_amount"
            value={internal_invoice_detail.estimated_benefit_amount || ''}
            size="md"
            type="text"
          />
        </Col>
      </Form.Group>
      <Form.Group className='mb-2' as={Row}>
        <Form.Label column="md" md={{ span: 4, offset: 1 }}>
          Kimble Estimated Revenue
        </Form.Label>
        <Col md={6}>
          <ImmutableUnderReviewFormControl
            as={FormattedNumberInput}
            customInput={Form.Control}
            onChange={handleInternalInvoiceDetailChange}
            name="kimble_forecast_revenue"
            value={internal_invoice_detail.kimble_forecast_revenue || ''}
            size="md"
            type="text"
          />
        </Col>
      </Form.Group>
      <Form.Group className='mb-2' as={Row}>
        <Form.Label column="md" md={{ span: 4, offset: 1 }}>
          Estimated Date of Benefit
        </Form.Label>
        <Col md={6}>
          <ImmutableUnderReviewFormControl
            as={DatePicker}
            selected={internal_invoice_detail.estimated_date_of_benefit}
            onChange={(date) => handleInternalInvoiceDetailChange({target: {value: date, name: 'estimated_date_of_benefit'}})}
          />
        </Col>
      </Form.Group>
      <Form.Group className='mb-2' as={Row}>
        <Form.Label column="md" md={{ span: 4, offset: 1 }}>
          Internal Invoice Related Notes
        </Form.Label>
        <Col md={6}>
          <Form.Control
            as="textarea"
            rows={5}
            onChange={handleInternalInvoiceDetailChange}
            name="notes"
            value={internal_invoice_detail.notes || ''}
            size="md"
            type="text"
          ></Form.Control>
        </Col>
      </Form.Group>
    </>
  );
};

export default ClaimInternalInoivceDetailForm
