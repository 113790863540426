import fetch from '../Fetcher';
import { request } from '../request';
const claimProjectReportSliceService = {
  get: (project_report_id, slice_id) => {
    return fetch(`/api/claim_project_reports/${project_report_id}/project_report_slices/${slice_id}`, request.get);
  },
  update: (project_report_id, project_report_slice) => {
    return fetch(`/api/claim_project_reports/${project_report_id}/project_report_slices/${project_report_slice.id}`, {
      ...request.put,
      body: JSON.stringify({
        project_report_slice
      })
    });
  }
};
export default claimProjectReportSliceService;