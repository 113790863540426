import { ApiError } from './ApiError';
import { request } from './request';
const doFetch = async (url, opts) => {
  try {
    const res = await fetch(url, Object.assign({}, request.get, opts));
    if ([200, 201, 304].includes(res.status)) {
      try {
        return await res.json();
      } catch (error) {
        throw new ApiError('Unexpected error occurred. Received unexpected response.');
      }
    } else if ([202, 204].includes(res.status)) {
      return {};
    } else if (res.status === 401) {
      if (window.location.pathname.split('/')[2] !== 'login' && window.location.pathname.split('/')[2] !== 'login_failed' && window.location.pathname.split('/')[1] && !window.location.pathname.includes('reset_password') && !window.location.pathname.includes('confirm/') && !window.location.pathname.includes('claim_import_cost_template_infos') && !/technical_uploads$/.test(window.location.pathname)) {
        window.location.replace(`/${window.location.pathname.split('/')[1]}/login`);
      }
      const json = await res.json();
      throw new ApiError(json.errors);
    } else if (res.status === 502) {
      throw new ApiError('Request timed out. Please try again later.');
    } else if (res.status === 413) {
      throw new ApiError('Content too large.');
    } else {
      try {
        const json = await res.json();
        let errorData = json.errors || json.error;
        // Sometimes the above 2 fields are defined, but there is additional useful error information
        // inside the exception field.
        if (Array.isArray(errorData) && json.exception) {
          errorData.push(json.exception);
        } else {
          errorData = `${errorData}`;
        }
        throw new ApiError(errorData);
      } catch (error) {
        throw error;
      }
    }
  } catch (e) {
    console.log(`Unhandled error for request ${url}`, opts, e.message);
    if (!e.userFriendlyMessages) {
      throw new ApiError(`Could not call ${url}: ${e.message || 'Something unexpected happened.'}`);
    }
    throw e;
  }
};
export default doFetch;