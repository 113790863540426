import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import generalRouteSubRouteService from '@rd-web-markets/shared/dist/services/general_route_subroute.service';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import generalRouteService from '../services/general_route.service';
export function useFileManagementUrlHandler() {
  const [key, setKey] = useState('project_report_tab');
  const history = useHistory();
  const [fileUploaderUsers, setFileUploaderUsers] = useState([]);
  const {
    search
  } = useLocation();
  const modelId = new URLSearchParams(location.search).get('model_id');
  const modelType = new URLSearchParams(location.search).get('model_type');
  const modelName = new URLSearchParams(location.search).get('model_name');
  const goToFolderQuery = function () {
    let id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    let isVirtualFolder = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    let virtualFolderData = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
    if (isVirtualFolder) {
      history.push({
        search: `?key=documents&model_type=${virtualFolderData.model_type}&model_id=${virtualFolderData.model_id}&model_name=${virtualFolderData.name}&folder=${id}`
      });
    } else {
      history.push({
        search: `?key=documents&model_type=${modelType}&model_name=${modelName}&model_id=${modelId}&folder=${id}`
      });
    }
  };
  useEffect(() => {
    const urlKey = new URLSearchParams(search).get('key');
    if (!urlKey) {
      history.push({
        search: `?key=${key}`
      });
    }
  }, []);
  useEffect(() => {
    const urlKey = new URLSearchParams(search).get('key');
    if (urlKey) {
      setKey(urlKey);
    } else {
      history.push({
        search: `?key=${key}`
      });
    }
  }, [location.search]);
  const goToTab = (key, modelIdFromTab) => {
    if (key === 'documents' && !modelType) {
      history.push({
        search: `?key=documents&model_type=ClaimProjectReport&model_id=${modelIdFromTab}&folder=`
      });
    } else {
      history.push({
        search: `?key=${key}`
      });
    }
    setKey(key);
  };
  const getFileUploadersAndVirtualFolderPath = useErrorHandling(useCallback(async () => {
    const routeNames = {
      Company: 'companies',
      ClaimGroup: 'claim_groups',
      ClaimProjectReport: 'claim_project_reports'
    };
    if (!modelType) return;
    let response;
    if (modelType === 'ClaimProjectReport') {
      response = await generalRouteSubRouteService.get(1, 'claim_groups', 'claim_project_reports', modelId);
    } else {
      response = await generalRouteService.get(routeNames[modelType], modelId);
    }
    setFileUploaderUsers([...response.file_uploader_users]);
    return response.virtual_folder_path;
  }, [modelId, modelType]));
  useEffect(() => {
    if (modelType === 'Company') {
      getFileUploadersAndVirtualFolderPath();
    }
  }, [getFileUploadersAndVirtualFolderPath, modelType]);
  return [key, goToTab, goToFolderQuery, fileUploaderUsers, modelName, modelType, modelId, getFileUploadersAndVirtualFolderPath];
}