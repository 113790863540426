import { useCallback, useEffect, useState } from 'react';
import { request } from '@rd-web-markets/shared/dist/services/request';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import { buildService } from '@rd-web-markets/shared/dist/services/service';
const api = buildService('/companies/{id}/competent_professionals', {
  only: ['all', 'create', 'update']
});
const competentProfessionalsService = {
  useFetchAllcompetentProfessionals: (companyId, setLoading, shouldFetch) => {
    const [competentProfessionals, setcompetentProfessionals] = useState([]);
    const [competentProfessionalsTotalPages, setcompetentProfessionalsTotalPages] = useState(null);
    const {
      search
    } = useLocation();
    const pageNumber = new URLSearchParams(search).get('page') || 1;
    const fetchAllcompetentProfessionals = useErrorHandling(useCallback(async () => {
      if (!pageNumber || !shouldFetch) return;
      setLoading && setLoading(true);
      const allCompanycompetentProfessionals = await api.all(companyId, {
        page: pageNumber
      });
      setcompetentProfessionals([...allCompanycompetentProfessionals.competent_professionals]);
      setcompetentProfessionalsTotalPages(Number(allCompanycompetentProfessionals.total_pages));
    }, [companyId, setLoading, setcompetentProfessionals, pageNumber, shouldFetch]), useCallback(async () => {
      setLoading && setLoading(false);
    }, [setLoading]));
    useEffect(() => {
      fetchAllcompetentProfessionals();
    }, [fetchAllcompetentProfessionals]);
    return [competentProfessionals, setcompetentProfessionals, fetchAllcompetentProfessionals, competentProfessionalsTotalPages];
  },
  useUpdatecompetentProfessional: (companyId, competentProfessionals, setcompetentProfessionals, setLoading) => {
    const updatecompetentProfessional = useErrorHandling(useCallback(async competentProfessional => {
      setLoading && setLoading(true);
      await api.update(companyId, competentProfessional.id, {
        competent_professional: competentProfessional
      });
    }, [companyId, setLoading]), useCallback(async () => {
      setLoading && setLoading(false);
    }, [setLoading]));
    return updatecompetentProfessional;
  },
  useCreatecompetentProfessional: (companyId, competentProfessionals, setcompetentProfessionals, setLoading) => {
    const updatecompetentProfessional = useErrorHandling(useCallback(async competentProfessional => {
      setLoading && setLoading(true);
      await api.create(companyId, {
        competent_professional: competentProfessional
      });
    }, [companyId, setLoading]), useCallback(async () => {
      setLoading && setLoading(false);
    }, [setLoading]));
    return updatecompetentProfessional;
  },
  delete(companyId, competentProfessionalId) {
    return fetch(`/api/companies/${companyId}/competent_professionals/${competentProfessionalId}`, request.delete);
  }
};
competentProfessionalsService.useCrud = function (companyId, setLoading) {
  let shouldFetch = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  const [competentProfessionals, setcompetentProfessionals, fetchAllcompetentProfessionals, competentProfessionalsTotalPages] = competentProfessionalsService.useFetchAllcompetentProfessionals(companyId, setLoading, shouldFetch);
  const updatecompetentProfessional = competentProfessionalsService.useUpdatecompetentProfessional(companyId, competentProfessionals, setcompetentProfessionals, setLoading);
  const createcompetentProfessional = competentProfessionalsService.useCreatecompetentProfessional(companyId, competentProfessionals, setcompetentProfessionals, setLoading);
  return {
    competentProfessionals,
    setcompetentProfessionals,
    fetchAllcompetentProfessionals,
    updatecompetentProfessional,
    createcompetentProfessional,
    competentProfessionalsTotalPages
  };
};
export default competentProfessionalsService;