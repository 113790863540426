import { buildService } from '@rd-web-markets/shared/dist/services/service';
import serviceMethods from '@rd-web-markets/shared/dist/services/service_methods';
import { replaceInArray } from '@rd-web-markets/shared/dist/util/arrayUtils/arrayUtils';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
const api = buildService('/claims/{id}/benefit_adjustments', {
  only: ['all', 'create', 'update', 'delete']
});
const useFetchAll = _ref => {
  let {
    claimId,
    setLoading
  } = _ref;
  const [adjustments, setAdjustments, fetchAdjustments] = serviceMethods.useFetch({
    callback: useCallback(async () => await api.all(claimId), [claimId]),
    setLoading
  });
  return [adjustments, setAdjustments, fetchAdjustments];
};
const useCreate = _ref2 => {
  let {
    claimId,
    setLoading,
    setAdjustments
  } = _ref2;
  const updateAdjustment = serviceMethods.useCreate({
    callback: useCallback(async adjustment => {
      const createdAdjustment = await api.create(claimId, {
        adjustment
      });
      if (setAdjustments) {
        setAdjustments(replaceInArray(a => a.id === adjustment.id, createdAdjustment));
      }
      return createdAdjustment;
    }, [claimId, setAdjustments]),
    setLoading
  });
  return updateAdjustment;
};
const useUpdate = _ref3 => {
  let {
    claimId,
    setLoading,
    setAdjustments
  } = _ref3;
  const updateAdjustment = serviceMethods.useUpdate({
    callback: useCallback(async adjustment => {
      const updatedAdjustment = await api.update(claimId, adjustment.id, {
        adjustment
      });
      if (setAdjustments) {
        setAdjustments(replaceInArray(a => a.id === updatedAdjustment.id, updatedAdjustment));
      }
      return updatedAdjustment;
    }, [claimId, setAdjustments]),
    setLoading
  });
  return updateAdjustment;
};
const useDelete = _ref4 => {
  let {
    claimId,
    setLoading,
    setAdjustments,
    onSuccess
  } = _ref4;
  const {
    t
  } = useTranslation();
  const deleteModel = serviceMethods.useDelete({
    onSuccess: onSuccess,
    callback: useCallback(async deletedAdjustment => {
      if (window.confirm(t(`are_you_sure_you_wish_to_delete_this_benefit_${deletedAdjustment.type}`))) {
        const promise = await api.delete(claimId, deletedAdjustment.id);
        if (setAdjustments) {
          setAdjustments(adjustments => adjustments.filter(a => a.id !== deletedAdjustment.id));
        }
        return promise;
      }
    }, [claimId, setAdjustments, t]),
    setLoading
  });
  return deleteModel;
};
const benefitAdjustmentsService = {
  useCrud: _ref5 => {
    let {
      claimId,
      setLoading,
      onDeleteSuccess = () => {}
    } = _ref5;
    const [adjustments, setAdjustments, fetchAdjustments] = useFetchAll({
      claimId,
      setLoading
    });
    const updateAdjustment = useUpdate({
      claimId,
      setLoading
    });
    const deleteAdjustment = useDelete({
      claimId,
      setLoading,
      onSuccess: onDeleteSuccess
    });
    return {
      adjustments,
      setAdjustments,
      fetchAdjustments,
      updateAdjustment,
      deleteAdjustment
    };
  },
  useFetchAll,
  useUpdate,
  useDelete,
  useCreate
};
export default benefitAdjustmentsService;