import { useCallback, useEffect, useState } from 'react';
import { buildQueryString, parseQueryString } from '../../services/service';
const useSortRows = _ref => {
  let {
    tableHeaders,
    tableRows,
    setTableRows
  } = _ref;
  const sortRows = useCallback((columnIndex, direction) => {
    const defaultvalueFnForSortAndFilter = row => row.columns[columnIndex];
    const valueFnForSortAndFilter = tableHeaders[columnIndex].valueFnForSortAndFilter || defaultvalueFnForSortAndFilter;
    const nonSortableRows = tableRows.filter(r => r.opts?.sort === false);
    const sortableRows = tableRows.filter(r => r.opts?.sort !== false);
    const sortedRows = [...sortableRows].sort((a, b) => {
      const [valueA, valueB] = [valueFnForSortAndFilter(a), valueFnForSortAndFilter(b)];
      if (valueA > valueB) {
        return direction === 'asc' ? 1 : -1;
      }
      if (direction === 'asc') {
        return valueA === valueB ? 0 : -1;
      } else {
        return valueA === valueB ? 0 : -1;
      }
    });

    // For now those rows are only below the sortable ones.
    // In the future we can add additional functionality here if needed.
    sortedRows.push(...nonSortableRows);
    setTableRows(sortedRows);
  }, [setTableRows, tableHeaders, tableRows]);
  return sortRows;
};
function resetOrderAndSetFilterText(tableHeaders, setTableHeaders, text, columnIndex, textFilters, setTextFilters) {
  tableHeaders.forEach(h => h.order = null);
  const tableHeaderFilberBy = tableHeaders[columnIndex].filterBy;
  textFilters[tableHeaderFilberBy] = text;
  setTextFilters({
    ...textFilters
  });
  setTableHeaders([...tableHeaders]);
}

/**
 * Builds a query string from the text filters object, and calls onFilter with the current tableHeaders and the query string.
 * Good for backend filtering.
 * @param {*} param0 
 * @returns 
 */
const useBuildQueryForBackendFiltering = _ref2 => {
  let {
    tableHeaders,
    setTableHeaders,
    onFilter,
    textFilters,
    setTextFilters
  } = _ref2;
  const buildQueryForBackendFiltering = useCallback((text, columnIndex) => {
    resetOrderAndSetFilterText(tableHeaders, setTableHeaders, text, columnIndex, textFilters, setTextFilters);
    const queries = Object.entries(textFilters).reduce((acc, _ref3) => {
      let [key, value] = _ref3;
      acc[key] = value;
      return acc;
    }, {});
    const queryString = buildQueryString(queries);
    onFilter(tableHeaders, queryString);
  }, [tableHeaders, setTableHeaders, textFilters, setTextFilters, onFilter]);
  return buildQueryForBackendFiltering;
};
const useFilterRowsByText = _ref4 => {
  let {
    tableHeaders,
    setTableHeaders,
    rows,
    setTableRows,
    onFilter,
    textFilters,
    setTextFilters
  } = _ref4;
  // Sorting is related to filtering
  const filterRowsByText = useCallback((text, columnIndex) => {
    resetOrderAndSetFilterText(tableHeaders, setTableHeaders, text, columnIndex, textFilters, setTextFilters);
    const nonFilterableRows = rows.filter(r => r.opts?.filterableByText === false);
    let filterableRows = rows.filter(r => r.opts?.filterableByText !== false);
    let filteredRows = filterableRows;
    for (const [index, header] of tableHeaders.entries()) {
      if (header.filterableByText) {
        const filterText = (textFilters[header.filterBy] || '').toLowerCase();
        filteredRows = filteredRows.filter(r => {
          const defaultvalueFnForSortAndFilter = row => row.columns[index];
          const valueFnForSortAndFilter = header.valueFnForSortAndFilter || defaultvalueFnForSortAndFilter;
          const columnText = (valueFnForSortAndFilter(r) || '').toLowerCase();
          return columnText.includes(filterText);
        });
      }
    }
    filteredRows.push(...nonFilterableRows);
    setTableRows(filteredRows);
  }, [rows, setTableHeaders, setTableRows, setTextFilters, tableHeaders, textFilters]);
  return filterRowsByText;
};

/**
 * If we pass onFilter, then we get the build query string method, which results in calling onFilter with the table headers and
 * a query string produced from the current text filter values. This is good for backend sorting.
 * Otherwise we use local sorting.
 * @param {*} param0 
 * @returns 
 */
const useTextFilteringMethod = _ref5 => {
  let {
    tableHeaders,
    setTableHeaders,
    rows,
    setTableRows,
    onFilter,
    textFilters,
    setTextFilters
  } = _ref5;
  const buildQueryForBackendFiltering = useBuildQueryForBackendFiltering({
    tableHeaders,
    setTableHeaders,
    onFilter,
    textFilters,
    setTextFilters
  });
  const filterRowsByText = useFilterRowsByText({
    tableHeaders,
    setTableHeaders,
    rows,
    setTableRows,
    onFilter,
    textFilters,
    setTextFilters
  });
  const filteringMethod = onFilter ? buildQueryForBackendFiltering : filterRowsByText;
  return filteringMethod;
};
const useTextFiltering = textFilterQueryStringParamName => {
  const [textFilters, setTextFilters] = useState({});

  // setting the filter text here is required for when the page is loaded with a query string already set
  // Sets a default filterBy value for the headers. This is required for the textFilters state to work properly.
  // In case we want backend filtering - then the filterBy should be set outside of this method.
  // This filterBy is a default value ot make the local filtering work.
  const mapHeaders = useCallback(headers => [...headers].map((h, i) => ({
    ...h,
    order: h.order || null,
    _filterText: textFilters[h.filterBy] || '',
    filterBy: h.filterBy || `${h.text}-${i}`
  })), [textFilters]);

  // On the first render of the table - read the text filters from the query string parameters.
  // Only on the first render, to prevent a flashing text error. In future renders - the filters
  // will be stored and retrieved from the textFilters state variable.
  useEffect(() => {
    const jsonQuery = parseQueryString();
    const filtersFromQueryString = {};
    if (textFilterQueryStringParamName && jsonQuery[textFilterQueryStringParamName]) {
      // for each param in the query - e.g. query=business_unit%3Dsome_bu_name&&project_name%3Dsome_project_name
      // it will save the following values-
      // business_unit: 'some_bu_name',
      // project_name: 'some_project_name'
      for (const key in jsonQuery[textFilterQueryStringParamName]) {
        filtersFromQueryString[key] = jsonQuery[textFilterQueryStringParamName][key];
      }
    }
    setTextFilters({
      ...filtersFromQueryString
    });
  }, [textFilterQueryStringParamName]);
  return [textFilters, setTextFilters, mapHeaders];
};
const tableMethods = {
  useSortRows,
  useFilterRowsByText,
  // useResetOrderAndSetFilterText,
  useBuildQueryForBackendFiltering,
  useTextFiltering,
  useTextFilteringMethod
};
export default tableMethods;