import fetch from '../Fetcher';
import { request } from '../request';
const claimGroupRolesService = {
  emailRoles: {
    'cost': 'claim_group_subscriber_cost_email',
    'ics_event_template': 'claim_group_subscriber_ics_email',
    'kickoff': 'claim_group_subscriber_kickoff_email'
  },
  async toggleConsultantExcludeFromEmail(claimGroupId, consultantId, isExcluded, emailType) {
    if (isExcluded) {
      return await claimGroupRolesService.delete(claimGroupId, consultantId, claimGroupRolesService.emailRoles[emailType]);
    } else {
      return await claimGroupRolesService.create(claimGroupId, consultantId, claimGroupRolesService.emailRoles[emailType]);
    }
  },
  delete(claimGroupId, userId, roleName) {
    return fetch(`/api/claim_groups/${claimGroupId}/roles/${userId}?role=${roleName}`, request.delete);
  },
  create(claimGroupId, userId, role) {
    return fetch(`/api/claim_groups/${claimGroupId}/roles/`, {
      ...request.post,
      body: JSON.stringify({
        user: {
          id: userId,
          role: role
        }
      })
    });
  }
};
export default claimGroupRolesService;