
import { useTranslation } from 'react-i18next';

import React from 'react';
import { Table } from 'react-bootstrap';
import AllCLaimProjectReportsItem from './AllCLaimProjectReportsItem';

const AllClaimProjectReportsList = ({reports, loading, deleteClaimProjectReport}) => {
  const { t } = useTranslation();

  return (
    <Table hover>
      <thead>
        <tr className="text-primary app-fs-table-header">
          <th className="font-weight-normal text-start">{t('company_name')}</th>
          <th className="font-weight-normal text-start">{t('project_name')}</th>
          <th className="font-weight-normal text-start">{t('status')}</th>
          { process.env.PUBLIC_URL === '/de' &&
            <>
              <th className="font-weight-normal text-center">{t('start_date')}</th>
              <th className="font-weight-normal text-center">{t('end_date')}</th>
            </>
          }
          { process.env.PUBLIC_URL === '/de' &&
            <>
              <th className="font-weight-normal text-center">{t('bfsz')}</th>
              <th className="font-weight-normal text-center">{t('elster')}</th>
            </>
          }
          <th className="font-weight-normal text-center">{t('summary_project_details')}</th>
          <th className="font-weight-normal text-center"></th>
        </tr>
      </thead>
      <tbody>
        {reports.map((report) => {
          return <AllCLaimProjectReportsItem deleteClaimProjectReport={deleteClaimProjectReport} loading={loading} key={report.id} report={report}/>;
        })}
      </tbody>
    </Table>
  )
}

export default AllClaimProjectReportsList
