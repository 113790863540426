/**
 * Used to validate params passed to a function and show a consistent error message in the console.
 * This functionality is written as a class - as it looked more reusable this way.
 * It may not be necessary, but I kept it like this, as it can still just be called like:
 * ParamValidator.validateValueIn(...)
 */
class ParamValidator {
  /**
   * 
   * @param {*} callerName Simply the string name of the function that we are in. E.g.
   * If we are in function A and we simply pass "A" as the callerName argument.
   */
  constructor(callerName) {
    this.callerName = callerName;
    this.validateCallerName(callerName);
  }

  /** You need to pass the caller name varialbe for consistent error messages. */
  validateCallerName(name) {
    if (!name) {
      console.error('ParamValidator called without model/caller name.');
    }
  }

  /**
   * Method for validating if a parameter is valid with a matcher function.
   * If the parameter value is matched by the matcher function then its a valid value.
   * 
   * E.g. to check that a value is within an array of valid values, you would pass the value, and a function like so:
   * (value, v => arrayOfValidValues.includes(v)).
   * 
   * This function will call the matcher function and pass the value.
   * Its done in this way so that we can pass other matchers if we want to - e.g. object that has id property,
   * or string that contains some characters, etc.
   * 
   * For now it simply logs an error message if the passed value is invalid.
   * @param {*} paramValue 
   * @param {*} matcherFn 
   * @returns 
   */
  validateParam(paramValue, matcherFn) {
    if (matcherFn(paramValue)) {
      return true;
    } else {
      console.error(this.modelType, ': Incorrect parameter passed for method: ', paramValue, ' should match fn: ', matcherFn);
      return false;
    }
  }

  /**
   * Validates that the passed value is in the list of accepted values.
   * E.g. if we want to set a claim's rate type for the UK - it can only be one of ['rdec', 'sme', 'merge_type', 'eris', 'rdec_and_sme'] etc.
   * Any other values would be incorrect.
   * @param {*} value 
   * @param {*} acceptedValues 
   * @returns 
   */
  validateValueIn(value, acceptedValues) {
    return this.validateParam(value, value => acceptedValues.includes(value));
  }

  /**
   * Allows you to validate params without instantiating this class.
   * E.g.:
   *   ParamValidator.validateValueIn('BenefitAdjustmentsTable', type, ['adduction', 'deduction'])
   * 
   * That validates that the type argument is contained in the array ['adduction', 'deduction'].
   * The string 'BenefitAdjustmentsTable' is required for consistent error messaging. In this case so 
   * that we know that the issue lies in the BenefitAdjustmentsTable component.
   * 
   * @param {*} caller 
   * @param {*} value 
   * @param {*} acceptedValues 
   * @returns 
   */
  static validateValueIn(caller, value, acceptedValues) {
    const validator = new ParamValidator(caller);
    return validator.validateValueIn(value, acceptedValues);
  }
}
export default ParamValidator;