import { stringToDom } from '@rd-web-markets/shared/dist/util/domUtils';
import { v4 as uuidv4 } from 'uuid';

/**
 * Returns an object that contains the common logic for addition and deletion highlights.
 * This object is instantiated with the specific highlightSettings provided from the Addition / Deletion Highlight modules.
 * 
 * The only reason we have a function that defines a nested function and returns an object, instead of simply defining an object, or 
 * simply defining a function that returns an object, is because the "this" keyword in JS is unbound and depends on the context
 * of invocation of the function. So if we pass "DeletionHighlight.createDomElementHighlightClasses" anywhere in the code as a callback,
 * the "createDomElementHighlightClasses" function no longer knows which object it was defined in, because the function that calls it, only 
 * receives a pointer to the "createDomElementHighlightClasses". This is different than explicitly calling "DeletionHighlight.createDomElementHighlightClasses()"
 * in which case the "createDomElementHighlightClasses" knows that it belongs to the "DeletionHighlight" object.
 * 
 * So TWDR we are forced to create a function that returns an object or a class. Object is simpler to understand.
 * 
 * @param {*} highlightSettings 
 * @returns 
 */
export const createHighlightType = highlightSettings => {
  function createDomElementHighlightClasses(changeId, elementUuid) {
    const uniqueEEementId = elementUuid || uuidv4();
    changeId ||= uuidv4();
    const className = `aym-inline-section TrackChanges-Highlight ${highlightSettings.className} TrackChanges-Highlight-change-${changeId} TrackChanges-el-uuid-${uniqueEEementId}`;
    return className;
  }
  return {
    className: highlightSettings.className,
    highlightType: highlightSettings.highlightType,
    createDomElementHighlightClasses,
    isNodeAHighlight(node) {
      return node.classList?.contains(highlightSettings.className);
    },
    /**
    * It appears that deletion elements should most certainly not be divs, unlike addition
    * elements which need to be divs in order for pasting of HTML to work properly. When
    * pasting HTML tiny sometimes wants to add a root block, for which at least for the moment
    * we use divs.
    * @param {*} content 
    * @param {*} changeId 
    * @param {*} elementUuid
    * @returns 
    */
    createHighlightedElement(content) {
      let changeId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      let elementUuid = arguments.length > 2 ? arguments[2] : undefined;
      let elementType = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'track_changes_change';
      const uniqueEEementId = elementUuid || uuidv4();
      changeId ||= uuidv4();
      const className = createDomElementHighlightClasses(changeId, uniqueEEementId);

      // if the content is html, the best is to know about it, and insert it without any parent elements in the editor
      if (content.includes('<')) {
        const trackChangesTempEl = document.createElement(elementType);
        trackChangesTempEl.className = className;
        trackChangesTempEl.innerHTML = content.replaceAll(' ', '&puncsp;');
        trackChangesTempEl.childNodes.forEach(childNode => {
          childNode.className += ` ${className}`;
        });
        return trackChangesTempEl;
      }
      const span = document.createElement(elementType);
      span.className = className;
      span.innerHTML = content.replaceAll(' ', '&puncsp;');
      return span;
    }
  };
};