import { v4 as uuidv4 } from 'uuid';
import { hasClassOrParentWithClass, isEmptySelection, stopEvent, surroundSelectionTextNodesWithDom, userIsCuttingOrPasting, userIsPastingContent } from "@rd-web-markets/shared/dist/util/domUtils";
import DeletionHighlight from "../models/DeletionHighlight";
import DynamicField from "../models/DynamicField";
import AdditionHighlight from '../models/AdditionHighlight';
import { findLastInsertedHighlightElementInDocument, replaceDeletionHighlightWithNewAdditionHighlight } from '../highlighintUtils';
import { setCursorPositionToEndOfElement, setCursorPositionToStartOfElement } from '../cursorUtils';
import { isDeleting } from './DeleteAction';
import selectionUtils from '../selectionUtils';

/**
 * We have selected a piece of text and we are writing more text.
 * We have already added a deletion highlight to the selected text.
 * Now this function adds addition highlighting to the place where we want to input the new text and places the cursor in the new highlight.
 * The browser then fulfills the event and the new character appears inside the addition highlight.
 * If the cursor falls inside a deletion highlight - we split the deletion highlight into two, and insert the addition highlight between those two parts.
 * @param {*} event 
 * @param {*} editor 
 * @param {*} cursorSelection 
 * @param {*} rightSideTextNode 
 */
function addAdditionHighlightWhenAddingContentAndTheSelectinIsNotEmpty(event, editor, cursorSelection, rightSideTextNode) {
  const changeId = uuidv4();
  let additionElement = null;
  // in case the cursor selection ends in a deletion highlight, we need to split the deletion highlight into 2 parts, and insert the character between them
  const offset = selectionUtils.getOffsetIfnodeIsFocusOrAnchor(cursorSelection, rightSideTextNode);
  if (hasClassOrParentWithClass(rightSideTextNode, 'TrackChanges-Highlight-Deletion') && offset !== -1) {
    const textNodeCutContent = rightSideTextNode.textContent.substring(offset);
    rightSideTextNode.textContent = rightSideTextNode.textContent.substring(0, offset);
    rightSideTextNode.parentElement.insertAdjacentHTML('afterend', DeletionHighlight.createHighlightedElement(textNodeCutContent).outerHTML);
    additionElement = AdditionHighlight.createHighlightedElement(event.key, changeId);
    rightSideTextNode.parentElement.insertAdjacentHTML('afterend', additionElement.outerHTML);
  } else {
    setCursorPositionToStartOfElement(editor, rightSideTextNode);
    additionElement = AdditionHighlight.createHighlightedElement(event.key, changeId);
    editor.execCommand('mceInsertRawHTML', false, additionElement.outerHTML);
  }
  const insertedElement = findLastInsertedHighlightElementInDocument(editor.getDoc(), additionElement);
  setCursorPositionToEndOfElement(editor, insertedElement.childNodes[0]);
}
function onInsertCharacter(event, editor, contentNode, cursorSelection, predictedInputValueOrNull) {
  /**
   * In order to not create nested addition highlights
   */
  if (!!predictedInputValueOrNull?.full && contentNode.textContent !== predictedInputValueOrNull?.full || userIsPastingContent(event)) {
    if (hasClassOrParentWithClass(contentNode, 'TrackChanges-Highlight-Addition') && !DeletionHighlight.isSelectionAnchorInHighlight(cursorSelection)) {
      editor.execCommand('mceInsertContent', false, event.key);
      stopEvent(event);
    } else if (!DynamicField.isSelectionAnchorInDeletedDynamicField(contentNode, cursorSelection)) {
      /**
       * If we are inside a deleted dynamic field, lets just not add text at all
       */
      const changeId = uuidv4();
      let contentToAdd = event.key;
      const additionHighlight = AdditionHighlight.createHighlightedElement(contentToAdd, changeId);
      editor.execCommand('mceInsertRawHTML', false, additionHighlight.outerHTML);
      let insertedSpan = findLastInsertedHighlightElementInDocument(editor.contentDocument, additionHighlight);
      if (insertedSpan.parentElement.classList?.contains('TrackChanges-Highlight-Deletion')) {
        replaceDeletionHighlightWithNewAdditionHighlight(insertedSpan, changeId, editor);
      }
      insertedSpan = findLastInsertedHighlightElementInDocument(editor.contentDocument, additionHighlight);
      setCursorPositionToEndOfElement(editor, insertedSpan);
      stopEvent(event);
    }
  }
}

/**
 * Either handle adding a single character or removeing a single character.
 * If we are pressing Delete - we want to remove the character to the right, while with Backspace - we want to remove
 * the one to the left.
 * @param {*} event 
 * @param {*} editor 
 * @param {*} cursorSelection 
 * @param {*} contentNode 
 * @param {*} predictedInputValueOrNull 
 * @returns 
 */
function highlightAdditionChangesWhenSelectionIsEmpty(event, editor, cursorSelection, contentNode, predictedInputValueOrNull) {
  // we are inserting a single character or pasting new text without deleting
  onInsertCharacter(event, editor, contentNode, cursorSelection, predictedInputValueOrNull);
}

/**
 * Cutting is handled in onCut.
 * In case we are deleting or we are pasting content or we are pressing enter - we surround the selected text with a deletion highlight.
 * In case we are deleting or pressing Enter - we move the cursor to end of the text node which is on the right of the highlited part.
 * In case we are pasting content - we prepare for pasting by adding an addition highlight dom element to the right and putting the cursor in it.
 * In case we are adding new text - we add a new highlight dom element at the cursor position and put the cursor in that element.
 * @param {*} event 
 * @param {*} editor 
 * @param {*} contentNode 
 * @param {*} cursorSelection 
 * @param {*} predictedInputValueOrNull 
 */
function highlightAdditionAndDeletionChangesWhenSelectionIsNotEmpty(event, editor, contentNode, cursorSelection, predictedInputValueOrNull) {
  // addition | deletion
  if (!!predictedInputValueOrNull?.full && contentNode.textContent !== predictedInputValueOrNull?.full) {
    stopEvent(event);
  }

  // addition | deletion | cutting | pasting
  if (contentNode.nodeName === 'IMG') {
    contentNode.classList.add(...DeletionHighlight.createDomElementHighlightClasses().split(' '));
    return;
  }
  if (!!predictedInputValueOrNull?.full && contentNode.textContent !== predictedInputValueOrNull?.full) {
    const [leftSideTextNode, rightSideTextNode] = surroundSelectionTextNodesWithDom(editor.getDoc(), cursorSelection, DeletionHighlight.createHighlightedElement, DeletionHighlight.createDomElementHighlightClasses, 'TrackChanges-Highlight-Addition', 'TrackChanges-Highlight-Deletion');
    addAdditionHighlightWhenAddingContentAndTheSelectinIsNotEmpty(event, editor, cursorSelection, rightSideTextNode);
  }
}
export function isInserting(event, editor, cursorSelection, contentNode, predictedInputValueOrNull) {
  const contentWillBeChanged = !!predictedInputValueOrNull?.full && contentNode.textContent !== predictedInputValueOrNull?.full;
  const notPressingEnter = !(event.key === 'Enter');
  const notDeleting = !isDeleting(event);
  const notCuttingOrPasting = !userIsCuttingOrPasting(event);
  return notPressingEnter && notDeleting && notCuttingOrPasting && contentWillBeChanged;
}
export function doInsert(event, editor, cursorSelection, contentNode, predictedInputValueOrNull) {
  if (isEmptySelection(cursorSelection)) {
    highlightAdditionChangesWhenSelectionIsEmpty(event, editor, cursorSelection, contentNode, predictedInputValueOrNull);
  } else {
    // if selection is not empty - e.g. at least a single character in selection  
    highlightAdditionAndDeletionChangesWhenSelectionIsNotEmpty(event, editor, contentNode, cursorSelection, predictedInputValueOrNull);
  }
}