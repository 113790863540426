import { useEffect, useState } from 'react';

/**
 * Creates a proxy that says that if the abilities for a certain page are not defined - then by default everything is true (allowed) for that page.
 * So e.g. abilities.page_1.can_see_button - may be defined as false for the UK, but we may not have any such definition for this page in Germany.
 * With this proxy we can simply write 
 *    if (abilities.page_1.can_see_button) - which will give false for the UK and true for Germany.
 * 
 * In addition we have defined a level of nesting on 'componemts' - check the array passed to createNestedPagesPropProxy.
 * The first nesting level is pages, followed by null, then components, followed by null.
 * This means that abilities.page_1.can_see_button will default to true, and abilities.page_1.components.component_1.can_see_button
 * will also default to true.
 * 
 * However abilities.page_1.components.component_1.someting.between.can_see_button - will evaluate to
 *         proxy     proxy  proxy      proxy       true     undefined  Error
 * 
 * If we dont have this proxy - we will need to have a lot of if statements / ternary operators everywhere in the code.
 * Or we will have to defined every single ability for every single market which may not be feasible.
 * 
 * @returns {Proxy} the proxy to the provided user abilities object
 */
export function createPagesAbilitiesProxy(userClaimGroupAbilities) {
  const createNestedPagesPropProxy = (abilitiesPages, nestedPropsArray) => {
    const currentLevelNestedPropName = nestedPropsArray?.shift();
    return new Proxy(abilitiesPages || {}, {
      get(target, prop) {
        if (prop in target) {
          const value = target[prop];
          if (typeof value === 'object' && value !== null) {
            return createNestedPagesPropProxy(value, nestedPropsArray);
          }
          return value;
        }
        if (prop === currentLevelNestedPropName || currentLevelNestedPropName === null) {
          return createNestedPagesPropProxy({}, nestedPropsArray);
        }
        return true;
      }
    });
  };
  const abilitiesProxy = new Proxy(userClaimGroupAbilities || {}, {
    get(abilitiesObject, prop) {
      if (prop === 'pages') {
        const res = createNestedPagesPropProxy(abilitiesObject[prop], [null, 'components', null]);
        return res;
      }
      return abilitiesObject[prop];
    }
  });
  return abilitiesProxy;
}

// default empty abilities implementation - so I dont have to copy paste this in every market
function getUserClaimGroupAbilities(user, claimGroupId) {
  return createPagesAbilitiesProxy({
    pages: {}
  });
}

// default empty abilities implementation - so I dont have to copy paste this in every market
function getUserCompanyAbilities(user, companyId) {
  return createPagesAbilitiesProxy({
    pages: {}
  });
}

// default useGetUserClaimGroupAbilities implementation - so I dont have to copy paste this in every market
export function useGetUserClaimGroupAbilities(user, claimGroupId) {
  // it always initially returns this state, so we need to build it asap
  const [userClaimGroupAbilities, setUserClaimGroupAbilities] = useState(getUserClaimGroupAbilities(user, claimGroupId));
  useEffect(() => {
    setUserClaimGroupAbilities(getUserClaimGroupAbilities(user, claimGroupId));
  }, [user, claimGroupId]);
  return userClaimGroupAbilities;
}
export function useGetUserCompanyAbilities(user, companyId) {
  // it always initially returns this state, so we need to build it asap
  const [userCompanyAbilities, setUserCompanyAbilities] = useState(getUserCompanyAbilities(user, companyId));
  useEffect(() => {
    const claimGroupAbilities = getUserCompanyAbilities(user, companyId);
    setUserCompanyAbilities(claimGroupAbilities);
  }, [user, companyId]);
  return userCompanyAbilities;
}