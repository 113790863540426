import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import orderBy from 'lodash.orderby';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import generalRouteService from '@rd-web-markets/shared/dist/services/general_route.service';
import generalRouteSubRouteService from '@rd-web-markets/shared/dist/services/general_route_subroute.service';
const competentProfessionalProjectTemplateService = {
  useImportStatus(companyId) {
    const [data, setData] = useState([]);
    const [importStatus, setImportStatus] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const [intervalId, setIntervalId] = useState(null);
    const {
      report_id
    } = useParams();
    const getImportStatusAndCompetentProfessionalData = useErrorHandling(useCallback(async () => {
      const response = await this.getImportStatus(companyId);
      if (response === null) {
        setImportStatus({
          import_status: 'never_imported'
        });
      } else {
        setImportStatus({
          ...response
        });
      }
      if (response?.import_status !== 'pending') {
        competentProfessionalProjectTemplateService.useGetStaffCost(companyId, setData, report_id);
      }
      return response;
    }, [companyId, report_id]));
    const importTemplate = useErrorHandling(useCallback(async body => {
      const response = await this.importCompetentProfessionalTemplate(companyId, body, report_id);
      setImportStatus({
        ...response
      });
    }, [companyId, report_id]));
    useEffect(() => {
      if (importStatus.import_status === 'pending' && !intervalId) {
        const currentId = setInterval(() => {
          getImportStatusAndCompetentProfessionalData();
        }, 4000);
        setIntervalId(currentId);
      } else if (importStatus.import_status !== 'pending' && !!intervalId) {
        clearInterval(intervalId);
        setIntervalId(null);
      }
      return () => {
        clearInterval(intervalId);
      };
    }, [getImportStatusAndCompetentProfessionalData, importStatus.import_status, intervalId, report_id]);
    useEffect(() => {
      getImportStatusAndCompetentProfessionalData();
    }, [getImportStatusAndCompetentProfessionalData]);
    useEffect(() => {
      competentProfessionalProjectTemplateService.useGetStaffCost(companyId, setData, report_id);
    }, [companyId, report_id]);
    return [data, setData, importStatus, importTemplate, report_id, searchQuery, setSearchQuery];
  },
  async useGetStaffCost(companyId, setData, selectedProjectId) {
    let searchQuery = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
    setData([]);
    const response = await competentProfessionalProjectTemplateService.getCompetentProfessionals(companyId, selectedProjectId, 'competent_professionals', searchQuery);
    const responseOrderedById = orderBy(response, ['id'], ['asc']);
    setData([...responseOrderedById]);
  },
  useExportTemplate(selectedProjectId, companyId) {
    const companyRoute = `/api/companies/${companyId}/export_competent_professional_template`;
    const projectRoute = `/api/claim_project_reports/${selectedProjectId}/export_competent_professional_template`;
    const route = selectedProjectId ? projectRoute : companyRoute;
    window.open(route);
  },
  getCompetentProfessionals(companyId) {
    let selectedProjectId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    let route = arguments.length > 2 ? arguments[2] : undefined;
    let searchQuery = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
    const finalSearchQuery = `?search=${searchQuery}`;
    if (selectedProjectId) {
      return generalRouteSubRouteService.getAll(selectedProjectId, 'claim_project_reports', 'competent_professionals', finalSearchQuery);
    } else {
      const finalRoute = `${route}${finalSearchQuery}`;
      return generalRouteService.getAll(`/companies/${companyId}/${finalRoute}`);
    }
  },
  getImportStatus(companyId) {
    return generalRouteService.getAll(`companies/${companyId}/import_competent_professional_templates`);
  },
  importCompetentProfessionalTemplate(companyId, body) {
    let claimProjectReportId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
    return generalRouteService.postFormData(`/companies/${companyId}/import_competent_professional_templates?project_id=` + claimProjectReportId, body);
  }
};
export default competentProfessionalProjectTemplateService;