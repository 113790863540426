import React, { useState } from 'react';
import { Col, Row, Form } from 'react-bootstrap';

import Modal from '@rd-web-markets/shared/dist/util/Modal';
import TextInputGroup from '@components/util/TextInputGroup';
import { DatePicker } from '@rd-web-markets/shared/dist/util/date';

export default function ReportCompletionModal({ onHide, onSubmit, show }) {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const modalBody = 
    <Form>
      <Form.Group>
        <Row md={2}>
          <Form.Label column="md" md={4}>
            Signed Off Date
          </Form.Label>
          <Col>
            <TextInputGroup placeholder="Start Date" prependIcon="calendar_month">
              <DatePicker
                className='border-0'
                selected={selectedDate}
                onChange={date => { setSelectedDate(date) }}
              />
            </TextInputGroup>
          </Col>
        </Row>
      </Form.Group>
    </Form>;

  return (
    <Modal
      title='Report Completed and Signed Off'
      show={show}
      renderBody={() => modalBody}
      onHide={onHide}
      onSubmit={() => onSubmit(selectedDate)}
    />
  )
}
