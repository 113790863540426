import { Loading } from '@rd-web-markets/shared/dist/util';
import React, { useState, useCallback, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom';
import { Card, Form, Row, Col, Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import claimGroupService from '@rd-web-markets/shared/dist/services/claim_group.service';
import claimGroupTasksService from '@rd-web-markets/shared/dist/services/claim_group_tasks.service';
import { SaveButton, CancelButton, AddButton, UploadButton } from '@rd-web-markets/shared/dist/util/buttons';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import AddTaskBreadcrumbs from './AddTaskBreadcrumbs';
import { DatePicker } from '@rd-web-markets/shared/dist/util/date';
import TaskLists from './TaskLists';
import { UserService } from '@rd-web-markets/shared/dist/services/user.service';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';

const AddTaskPage = ({ handleToaster, accountType }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { claimGroupId, taskId } = useParams();
  const [claimGroup] = claimGroupService.useGetClaimGroup(claimGroupId);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState();
  const user = useSelector((state) => state.auth.user);
  const [tag, setTag] = useState('');
  const [task, setTask] = useState({
    name: '',
    type: 'task',
    subtype: 'subtype1',
    status: 'not_started',
    description: '',
    tags: 'tag 1,tag 2',
    creator: user.id,
    assignee_id: null,
    comments: [
      { id: 1, created_at: new Date(), user: 3, text: 'Lorem ipsum' },
      { id: 2, created_at: new Date(), user: 3, text: 'Lorem ipsum 2' },
    ],
    attachments: [
      { id: 1, created_at: new Date(), user: 3, link: 'link_to_attachment1' },
      { id: 2, created_at: new Date(), user: 3, link: 'link_to_attachment2' },
      { id: 3, created_at: new Date(), user: 3, link: 'link_to_attachment3' },
    ],
    logs: [],
    files: [
      { id: 1, date: '17/01/2024', name: 'Cost template', link: '' },
    ],
  });
  const [initialTask, setInitialtask] = useState(task);

  useEffect(() => {
    (async () => { // fetch users
      try {
        const response = await UserService.all('admin');
        setUsers(response.users);
        if (taskId) {
          const task = await claimGroupTasksService.get(claimGroupId, taskId);
          setTask(task);
          setInitialtask(task);
        }
      } catch(e) {
        dispatch(handleError(e))
      }
    })();
  }, [dispatch]);

  const goBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const detectAndSaveChanges = (actualTask) => {
    let id = +actualTask.logs[actualTask.logs.index - 1]?.id || 1;
    const fields = ['name', 'type', 'subtype', 'status', 'description', 'tags', 'assignee'];
    const sets = ['comments', 'attachments'];
    // fields
    fields.forEach(field => {
      if (actualTask[field] !== initialTask[field]) {
        actualTask.logs.push({ id: id++, date: new Date(), user: user.id, action: `Field '${field}' was set to '${actualTask[field]}'`});
      }
    });
    // tags
    const actualTags = actualTask.tags.split(',');
    const initialTags = initialTask.tags.split(',');
    initialTags.forEach(initialTag => {
      if (!actualTags.find(actualTag => actualTag === initialTag)) {
        actualTask.logs.push({ id: id++, date: new Date(), user: user.id, action: `Tag '${initialTag}' was deleted`});
      }
    });
    actualTags.forEach(actualTag => {
      if (!initialTags.find(initialTag => initialTag === actualTag)) {
        actualTask.logs.push({ id: id++, date: new Date(), user: user.id, action: `Tag '${actualTag}' was added`});
      }
    });
    // sets
    sets.forEach(set => {
      initialTask[set].forEach(initialRecord => {
        const actualRecord = actualTask[set].find(actualRecord => actualRecord.id === initialRecord.id);
        if (!actualRecord) {
          actualTask.logs.push({ id: id++, date: new Date(), user: user.id, action: `Field '${set}', record with id ${initialRecord.id} was deleted`});
        }
      });
      actualTask[set].forEach(actualRecord => {
        const initialRecord = initialTask[set].find(initialRecord => initialRecord.id === actualRecord.id);
        if (!initialRecord) {
          actualTask.logs.push({ id: id++, date: new Date(), user: user.id, action: `Field '${set}', record '${JSON.stringify(actualRecord)}' was added`});
        } else if(JSON.stringify(initialRecord) !== JSON.stringify(initialRecord)) {
          actualTask.logs.push({ id: id++, date: new Date(), user: user.id, action: `Field '${set}', record with id ${initialRecord.id} was changed to '${JSON.stringify(actualRecord)}'`});
        }
      });
    });
  };

  const addTask = useErrorHandling(useCallback(async (e) => {
    e.preventDefault();
    if (taskId) {
      detectAndSaveChanges(task);
      await claimGroupTasksService.update(claimGroupId, taskId, task);
    } else {
      task.logs.push({ id: 1, date: new Date(), user, action: `Record was created with state '${JSON.stringify(task)}'` });
      await claimGroupTasksService.create(claimGroupId, task);
    }
    goBack();
  }, [claimGroupId, task, goBack]));

  const addFile = () => {
    const files = task.files;
    const id = (files[files.length - 1]?.id || 0) + 1;
    files.push({ id, date: '17/01/2024', name: `New file ${id}`, link: '' });
    setTask({ ...task, files });
  };

  const handleFileName = (name, id) => {
    const file = task.files.find(f => f.id === id);
    file.name = name;
    setTask({ ...task, files: task.files });
  };

  const handleFileDate = (date, id) => {
    const file = task.files.find(f => f.id === id);
    file.date = date;
    setTask({ ...task, files: task.files });
  };

  const addTag = () => {
    setTask({ ...task, tags: task.tags + ',' + tag });
    setTag('');
  };

  const uploadFile = (id) => {
    const file = task.files.find(el => el.id === id);
    file.link = 'TODO: upload file and set link';
    setTask({ ...task, files: task.files });
  };

  const setFileLink = (link, id) => {
    const file = task.files.find(el => el.id === id);
    file.link = link;
    setTask({ ...task, files: task.files });
  };

  if (!claimGroup) return <Loading/>;

  return (
    <>
      <AddTaskBreadcrumbs claimGroup={claimGroup}/>
      <Card>
        <Form onSubmit={addTask}>
          <Card.Body>
            <Form.Group className='mb-2' as={Row}>
              <Form.Group as={Col} md={8}>
                <Form.Control
                  as='input'
                  onChange={(event) => setTask({ ...task, name: event.target.value })}
                  size='md'
                  type='text'
                  value={task.name}
                  placeholder={t('enter_task_title')}
                />
              </Form.Group>
              <Form.Group as={Col} md={{span: 3, offset: 1}}>
                <Form.Control
                  as='select'
                  onChange={(event) => setTask({ ...task, assignee_id: event.target.value })}
                  size='md'
                  type='text'
                  value={task.assignee_id}
                >
                  <option value={0} disabled>{t('choose_assignee')}</option>
                  {users.map((user) => (
                    <option
                      value={user.id}
                      key={user.id}
                    >
                      {user.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form.Group>
            <Form.Group className='mb-2' as={Row}>
              <Form.Label column='md' md={1}>
                {t('task_type')}
              </Form.Label>
              <Form.Control
                as='select'
                className='col-md-2'
                onChange={(event) => setTask({ ...task, type: event.target.value })}
                size='md'
                type='text'
                value={task.type}
              >
                <option value={'task'}>{t('task')}</option>
                <option value={'file_request'}>{t('file_request')}</option>
              </Form.Control>
              <Form.Group as={Col} md={{span: 4, offset: 1}} className='mb-0'>
                <Form.Group as={Row} className='mb-0' style={{paddingRight: '15px'}}>
                  <Form.Group as={Col} column='md' md={9} className='mb-0'>
                    {task.tags.split(',').map((tag, index) => (
                      <Badge
                        key={index}
                        pill
                        bg='success'
                        style={{ fontSize: '16px', marginRight: '0.5rem' }}
                      >
                        {tag}
                      </Badge>
                    ))}
                  </Form.Group>
                  <Form.Group as={Col} md={3} className='mb-0'>
                    <Form.Group as={Row} className='mb-0'>
                      <Form.Control
                        as='input'
                        value={tag}
                        onChange={e => setTag(e.target.value)}
                        placeholder={t('enter_new_tag')}
                      />
                    </Form.Group>
                    <Form.Group as={Row} className='mb-0'>
                      <AddButton
                        variant='info'
                        onClick={addTag}
                        text={t('add_tag')}
                        style={{width: '100%'}}
                      />
                    </Form.Group>
                  </Form.Group>
                </Form.Group>
              </Form.Group>
              <Form.Group as={Col} md={{span: 3, offset: 1}} className='mb-0'>
                <Form.Label>
                  {t('creator')}: <span className='material-icons'>account_circle</span>{users.find((user) => user.id === task.creator).name}
                </Form.Label>
              </Form.Group>
            </Form.Group>
            {task.type === 'task' && <Form.Group className='mb-2' as={Row}>
              <Form.Label column='md' md={1}>
                {t('subtask_type')}
              </Form.Label>
              <Form.Control
                as='select'
                className='col-md-2'
                onChange={(event) => setTask({ ...task, subtype: event.target.value })}
                size='md'
                type='text'
                value={task.subtype}
              >
                <option value={'subtype1'}>{t('subtype1')}</option>
                <option value={'subtype2'}>{t('subtype2')}</option>
                <option value={'subtype3'}>{t('subtype3')}</option>
                <option value={'subtype4'}>{t('subtype4')}</option>
              </Form.Control>
            </Form.Group>}
            <Form.Group className='mb-2' as={Row}>
              <Form.Label column='md' md={1}>
                {t('status')}
              </Form.Label>
              <Form.Control
                as='select'
                className='col-md-2'
                onChange={(event) => setTask({ ...task, status: event.target.value })}
                size='md'
                type='text'
                value={task.status}
              >
                <option value={'new'}>{t('new')}</option>
                <option value={'not_started'}>{t('not_started')}</option>
                <option value={'in_progress'}>{t('in_progress')}</option>
                <option value={'completed'}>{t('completed')}</option>
              </Form.Control>
            </Form.Group>
            {task.type === 'file_request' && <>
              <Form.Group className='mb-2' as={Row}>
                <Form.Label column='md'>
                  {t('list_of_requested_files')}
                </Form.Label>
              </Form.Group>
              {task.files.map((file) => {
                return (
                  <Form.Group as={Row} key={file.id}>
                    <Form.Label column='md' md={1} className='mb-0'>
                      {file.id}
                    </Form.Label>
                    <Form.Group as={Row} column='md' md={4} className='mb-0'>
                      <Form.Label column='md' md={4}>
                        {t('due_date')}:
                      </Form.Label>
                      <Form.Group as={Col} md={6} className='mb-0'>
                        <DatePicker
                          column='md'
                          md={8}
                          selected={file.date}
                          onChange={(date) => handleFileDate(date, file.id)}
                        />
                      </Form.Group>
                    </Form.Group>
                    <Form.Group as={Row} column='md' md={4} className='mb-0'>
                      <Form.Label column='md' md={4}>
                        {t('file_name')}:
                      </Form.Label>
                      <Form.Control
                        className='col-md-8'
                        value={file.name}
                        onChange={(event) => handleFileName(event.target.value, file.id)}
                      />
                    </Form.Group>
                    <Form.Group as={Col} style={{marginLeft: '50px'}} className='mb-0'>
                      <Form.Group as={Row} className='mb-0'>
                        <UploadButton
                          className='mb-0'
                          loading={loading}
                          onClick={() => uploadFile(file.id)}
                          text={t('upload_file')}
                        />
                        <Form.Label style={{paddingLeft: '15px', paddingTop: 'calc(0.375rem + 1px)'}}>
                          {t('or')}
                        </Form.Label>
                        <Form.Group as={Col} md={9} className='mb-0'>
                          <Form.Control
                            as='input'
                            onChange={(event) => setFileLink(event.target.value, file.id)}
                            size='md'
                            type='text'
                            value={file.link}
                            placeholder={t('enter_link_to_file')}
                          />
                        </Form.Group>
                      </Form.Group>
                    </Form.Group>
                  </Form.Group>
                );
              })}
              <Form.Group className='mb-2' as={Row}>
                <AddButton
                  variant='info'
                  onClick={addFile}
                  text={t('add_file')}
                  style={{marginLeft: '15px'}}
                />
              </Form.Group>
            </>}
            {task.type === 'task' && <>
              <Form.Group className='mb-2' as={Row}>
                <Form.Label column='md' md={1}>
                  {t('description')}
                </Form.Label>
              </Form.Group>
              <Form.Group className='mb-2' as={Row}>
                <Form.Group as={Col} className='col-md-8'>
                  <Form.Control
                    as='textarea'
                    onChange={(event) => setTask({ ...task, description: event.target.value })}
                    size='md'
                    type='text'
                    value={task.description}
                    style={{ resize: 'none' }}
                    rows='10'
                  />
                </Form.Group>
              </Form.Group>
            </>}
            <Form.Group as={Row} className='col-md-8' style={{justifyContent: 'flex-end'}}>
              <SaveButton className='me-2'/>
              <CancelButton onClick={goBack}/>
            </Form.Group>
            <Form.Group className='mb-2' as={Row}>
              <TaskLists task={task} setTask={setTask} className='col-md-8'/>
            </Form.Group>
          </Card.Body>
        </Form>
      </Card>
    </>
  )
}

export default AddTaskPage;
