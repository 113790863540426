import { buildQueryString, createCachedService } from '@rd-web-markets/shared/dist/services/service';
import { useCallback, useEffect, useState } from 'react';
import { useErrorHandling } from '../hooks/useErrorHandling';
const cachedService = createCachedService('regularDashboardService');
const regularDashboardService = {
  // We use different ids in IT vs UK/US
  all: (companyOrClaimGroupId, selectedYear) => cachedService.get(`/dashboards_regular/${companyOrClaimGroupId}?selected_year=${selectedYear}`),
  // type: claim_group | company
  get: (companyId, queryParams) => cachedService.get(`/dashboards_regular/${companyId}?${buildQueryString(queryParams)}`),
  useFetchDashboardDataForYear: function () {
    const [dashboardData, setDashboardData] = useState(null);
    const fetchDashboardData = useErrorHandling(useCallback(async function () {
      const res = await regularDashboardService.all(...arguments);
      setDashboardData(res);
    }, []));

    // This is in order to fix the useEffect dependencies.
    // useeffect can also depend on args directly, but since its an array
    // it does a shallow comparison, and therefore it calls the useeffect twice instead of once.
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    const [companyOrClaimGroupId, selectedYear] = [...args];
    useEffect(() => {
      fetchDashboardData(companyOrClaimGroupId, selectedYear);
    }, [companyOrClaimGroupId, fetchDashboardData, selectedYear]);
    return dashboardData;
  }
};
export default regularDashboardService;