/**
 * We need to explicitly pass the time and date format parameters to the React Date Picker
 * @returns 
 */
export function getLocaleDateFormat() {
  const LOCALE = window.AYMING_MARKET_LOCALE;
  switch (LOCALE) {
    case 'en-GB':
      return 'dd/MM/yyyy';
    case 'en-US':
      return 'MM/dd/yyyy';
    case 'de-DE':
      return 'dd.MM.yyyy';
    case 'it-IT':
      return 'dd.MM.yyyy';
    default:
      return 'dd/MM/yyyy';
  }
}

/**
 * We need to explicitly pass the time and date format parameters to the React Date Picker
 * @returns 
 */
export function getLocaleDateTimeFormat() {
  const LOCALE = window.AYMING_MARKET_LOCALE;
  switch (LOCALE) {
    case 'en-GB':
      return 'dd/MM/yyyy HH:mm';
    case 'en-US':
      return 'MM/dd/yyyy HH:mm';
    case 'de-DE':
      return 'dd.MM.yyyy HH:mm';
    case 'it-IT':
      return 'dd.MM.yyyy HH:mm';
    default:
      return 'dd/MM/yyyy HH:mm';
  }
}
export function getDateTimeFormatForTable() {
  const LOCALE = window.AYMING_MARKET_LOCALE;
  switch (LOCALE) {
    case 'en-US':
      return 'HH:mm MM.DD.yyyy';
    default:
      return 'HH:mm DD.MM.yyyy';
  }
}
const DEFAULT_FORMATTING_OPTIONS = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric'
};

// Displaying formats based on locales for different environment
function localesForCustomLocaleDateString() {
  const LOCALE = window.AYMING_MARKET_LOCALE;
  if (LOCALE === 'en-CA') {
    return {
      locale: 'en-US',
      formattingOptions: {
        month: 'short',
        day: '2-digit',
        year: 'numeric'
      }
    };
  } else if (LOCALE === 'it-IT') {
    return {
      locale: 'de-DE',
      formattingOptions: DEFAULT_FORMATTING_OPTIONS
    };
  } else {
    return {
      locale: LOCALE,
      formattingOptions: DEFAULT_FORMATTING_OPTIONS
    };
  }
}
export function extractMonthYear(dateString) {
  if (dateString) {
    return dateString.split('/').slice(1).join('/');
  }
}
export function dateToString(date) {
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
}
export function dateToLocaleString(date) {
  const dateObject = new Date(date); // in case its a string, works fine it its a date
  return `${dateObject.toLocaleString()}`;
}

/**
 * We mostly follow the 2023-01-01 format - YYYY-MM-DD.
 * Converts 31/01/2023 to 2023--01-31 and then creates a date in the current
 * There is a possibility for the passed date to contain time data as well. Therefore we first need to strip out
 * only the date part.
 * @param {*} dateStringWithSlashes 
 * @returns 
 */
export function DDMMYYYStringToDate(dateStringWithSlashes) {
  const DATE_PART_REGEX = /(\d*\/\d*)+/;
  const datePart = dateStringWithSlashes.match(DATE_PART_REGEX)[0];
  const dateStringCorrectFormat = datePart.split('/').reverse().join('-');
  return createDateInCurrentTimezone(dateStringCorrectFormat);
}

/**
 * Javascript creates dates in different timezones depending on the datestring passed to the new Date constructor.
 * This is because depending the string itself may belong to a different time format.
 * 
 * E.g. a London timezoned date for the 1st of August, when loaded on a laptop in the USA, will convert that date to a USA timezone,
 * subtracting the timedifference in hours and reducing the days by 1, since the 1st of August may not yet have happened in the USA, depending
 * on the time of the date - e.g. 1st of August 22:00 London time vs 1st of August 00:00 London time.
 * 
 * In order to avoid such issues with a date string format, we convert the 2 string formats we currently use to YYYY-MM-DD, and take the 
 * year, month and day component separately, and then create a date in the local timezone at 00:00:00.000 without using the string format.
 * @param {Object | String} dateStringOrObject
 * @returns 
 */
export function createTimezonedDate(dateStringOrObject, defaultToToday) {
  if (!dateStringOrObject) {
    if (!defaultToToday) {
      return null;
    }
    dateStringOrObject = new Date();
  }
  let date;
  if (dateStringOrObject && typeof dateStringOrObject === 'object') {
    date = dateStringOrObject;
  } else if (dateStringOrObject) {
    if (dateStringOrObject.includes('/')) {
      date = DDMMYYYStringToDate(dateStringOrObject);
    } else {
      date = createDateInCurrentTimezone(dateStringOrObject);
    }
  }
  date.toCustomLocaleDateString = isDEDocx => {
    const locales_format = localesForCustomLocaleDateString();
    if (process.env.PUBLIC_URL === '/ca') {
      return date.toLocaleDateString(locales_format.locale, locales_format.formattingOptions).replace(/,/g, '').replace(/\s+/g, '.');
    } else if (isDEDocx) {
      return date.toLocaleDateString('ko-KR', locales_format.formattingOptions).replace(/[.\s]/g, ''); // yyyymmdd format for DE docx
    } else {
      return date.toLocaleDateString(locales_format.locale, locales_format.formattingOptions);
    }
  };
  date.toCustomLocaleTimeString = () => {
    const locales_format = localesForCustomLocaleDateString();
    return date.toLocaleTimeString(locales_format.locale, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    });
  };
  return date instanceof Date && !isNaN(date) ? date : console.log('Invalid date');
}
export function createEndOfCurrentYear() {
  const currentYear = new Date().getFullYear();
  const endOfCurrentYear = `${currentYear}-12-31`;
  return endOfCurrentYear;
}
/**
 * Accepts date in the format of YYYY-MM-DD and creates a new date in the current timezone
 */
function createDateInCurrentTimezone(dateString) {
  const dateStringParts = dateString.split('-');
  const year = dateStringParts[0];
  const month = Number(dateStringParts[1]) - 1;
  const day = parseInt(dateStringParts[2]);
  if (month < 0 || month > 11) {
    throw new Error(`Invalid date ${dateString}. Month - 1 must be between 0 and 11. Possibly passed a date in a wrong format.`);
  }
  return new Date(year, month, day, 0, 0, 0, 0);
}
export const getYear = dateInString => new Date(dateInString).getFullYear(); // gets 'DD/MM/YYYY and retruns years as a number

export const currentTime = () => {
  const today = new Date();
  const hour = String(today.getHours()).padStart(2, '0');
  const minute = String(today.getMinutes()).padStart(2, '0');
  const second = String(today.getSeconds()).padStart(2, '0');
  return `${hour}:${minute}:${second}`;
};
export const datesInChronologicalOrder = function (firstDate, secondDate) {
  let format = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  let strictComparison = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  let startDate, endDate;
  if (!format) {
    startDate = new Date(firstDate);
    endDate = new Date(secondDate);
  } else if (format.toLowerCase() === 'mm/yyyy') {
    const [firstMonth, firstYear] = firstDate.split('/').slice(-2);
    const [secondMonth, secondYear] = secondDate.split('/').slice(-2);

    // one of the dates is not specified, return true (means that another date is valid)
    if (!firstYear || !secondYear) {
      return true;
    }
    startDate = new Date(firstYear, firstMonth - 1);
    endDate = new Date(secondYear, secondMonth - 1);
  } else if (format.toLowerCase() === 'dd/mm/yyyy') {
    const [firstDay, firstMonth, firstYear] = firstDate.split('/').slice(-3);
    const [secondDay, secondMonth, secondYear] = secondDate.split('/').slice(-3);

    // one of the dates is not specified, return true (means that another date is valid)
    if (!firstYear || !secondYear) {
      return true;
    }
    startDate = new Date(firstYear, firstMonth - 1, firstDay);
    endDate = new Date(secondYear, secondMonth - 1, secondDay);
  } else if (format.toLowerCase() === 'mm/dd/yyyy') {
    startDate = new Date(firstDate);
    endDate = new Date(secondDate);
  }
  return strictComparison ? startDate < endDate : startDate <= endDate;
};