import franklinGothikBook from './styles/franklin_gothic_book.css.js';
import franklinGothikDemi from './styles/franklin_gothic_demi.css.js';
import georgia from './styles/georgia.css.js';
export function getContentStyle(isTrackingChanges) {
  const trackChangesStyleIfTracking = `
    [data-aym-comment-id] {
      text-decoration: underline; 
      text-decoration-color: orange; 
      background-color: orange;
      cursor: pointer;
    }

    .TrackChanges-Highlight-Addition.TrackChanges-Highlight-Newline:before {
      content: "->";
      position: absolute;
      left: 0px;
      color: green;
    }

    .TrackChanges-Highlight-Deletion {
      color: red !important;
      text-decoration: line-through !important;
      font-weight: bold !important;
      display: inline;
    }

    .TrackChanges-Highlight-Addition {
      color: green !important;
      font-weight: bold !important;
      display: inline;
      text-decoration: underline; /* dynamic fields have inline styles, but we need to highlight them somehow */
    }

    img.TrackChanges-Highlight-Deletion {
      border: 1px solid red;
    }

    .TrackChanges-Highlight-Addition>img {
      border: 1px solid green;
    }

    .Aym-TextEditor-popover {
      position: absolute;
      width: 100px;
      height: 100px;
      background-color: white;
      color: black;
      z-index: 100;
      border: 1px solid black;
      border-radius: 5px;
      box-shadow: 0px 0px 5px black;
      padding: 5px;
      min-width: 300px;
      min-height: 150px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    
    .Aym-TextEditor-popover .popover-header {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid black;
      padding-bottom: 10px;
    }
    
    .Aym-TextEditor-popover .btn__close {
      top: 0px;
      right: 0px;
      background-color: white;
      color: black;
      border: none;
      padding: 5px;
      font-size: 20px;
      cursor: pointer;
    }
    
    .Aym-TextEditor-popover .popover-change-container {
      overflow-y: auto;
      padding: 5px;
    }
    
    .Aym-TextEditor-popover .text {
      padding: 5px;
      /* font-size: 20px; */
    }
    
    .Aym-TextEditor-popover .action_buttons_container {
      position: relative;
      bottom: 0;
      text-align: right;
      border-top: 1px dotted black;
      padding-top: 5px;
    }
    
    .Aym-TextEditor-popover .action_buttons_container .btn__accept {
      padding: 5px;
      /* font-size: 20px; */
      cursor: pointer;
      border-radius: 5px;
      border: 1px solid green;
      background-color: white;
      color: black;
      margin-right: 5px;
    }
    
    .Aym-TextEditor-popover .action_buttons_container .btn__reject {
      padding: 5px;
      /* font-size: 20px; */
      cursor: pointer;
      border-radius: 5px;
      border: 1px solid red;
      background-color: white;
      color: black;
    }
    
    .Aym-TextEditor-popover .Aym-TextEditor-TrackChanges-Content-Deletion {
      color: red;
    }
    
    .Aym-TextEditor-popover .Aym-TextEditor-TrackChanges-Content-Addition {
      color: green;
    }
  `;
  const trackChangesStyleIfNotTracking = `
    .TrackChanges-Highlight.TrackChanges-Highlight-Deletion {
      display: none;
    }
  `;
  const trackChangesStyle = isTrackingChanges ? trackChangesStyleIfTracking : trackChangesStyleIfNotTracking;
  return `
    ${franklinGothikBook}
    ${franklinGothikDemi}
    ${georgia}
    
    .aym-inline-section {
      display: inline;
      height: auto;
      width: auto;
    }

    ${trackChangesStyle}
  `;
}

// This defines a list of elements that we allow to appear inside spans inside tinymce.
// Most of these should never occur, but are added for completeness just in case, as adding them is fine.
export const ALLOWED_SPAN_CHILDREN = ['track_changes_change', 'track_changes_change_block', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'strong', 'em', 'sup', 'sub', 'code', 'p', 'blockquote', 'pre', 'address', 'article', 'aside', 'canvas', 'dd', 'div', 'dl', 'dt', 'fieldset', 'figcaption', 'figure', 'footer', 'form', 'header', 'hr', 'li', 'main', 'nav', 'noscript', 'ol', 'section', 'table', 'tfoot', 'ul', 'video'];