import React from 'react'
import { Card, Row, Col, Form, InputGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Button } from '@rd-web-markets/shared/dist/util/buttons';
import { helpPages } from '@components/shared/financialSection/general/Help';
import { Editor } from '@tinymce/tinymce-react';
import { apiKey, getDefaultConfig } from '@rd-web-markets/shared/dist/util/tinyMceConfig';
import { ChangeHighlighter } from '@rd-web-markets/shared/dist/util/ChangeHighlighter';
import ClaimStateAlerts from '@rd-web-markets/shared/dist/claim/ClaimStateAlerts';
import { FormattedNumberInput } from '@rd-web-markets/shared/dist/util/FormattedNumberInput';
import TeachingBubble from '@rd-web-markets/shared/dist/util/TeachingBubble';
import NotesList from '@rd-web-markets/shared/dist/financialSection/NotesList';
import ChangeHighlightedImmutableUnderReviewControl from '@rd-web-markets/shared/dist/util/reviewEditControls/ChangeHighlightedImmutableUnderReviewControl';

const GeneralSection = ({ accountType, claim, setClaim, saveClaim }) => {
  const handleTinyMce = (content) => {
    setClaim({
      ...claim,
      financial_notes: content,
    });
  };

  const handleChange = event => {
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value;
    setClaim({
      ...claim,
      [event.target.name]: value,
    });
  }

  const hasPermissions = () => {
    return accountType !== 'customer' && accountType !== 'accountant'
  }

  return <>
    <Form onSubmit={(e) => {
      e.preventDefault();
      saveClaim(claim);
    }}>
      <ClaimStateAlerts claim={claim} />
      <div className="text-end"><TeachingBubble pages={helpPages} position="left" /></div>
      {claim.is_long_claim_period && (
        <Form.Group>
          <Form.Label className="col-md-4 offset-4 col-form-label">
            1<sup>st</sup> CT:
            {claim.periods[0].start} - {claim.periods[0].end}
          </Form.Label>
          <Form.Label className="col-md-4 col-form-label">
            2<sup>st</sup> CT:
            {claim.periods[1].start} - {claim.periods[1].end}
          </Form.Label>
        </Form.Group>
      )}
      <Form.Group>
        {hasPermissions() &&
          <Row>
            <Form.Label className="col-md-2 col-form-label">
              Lock For Customers
            </Form.Label>
            <Form.Check
              type="checkbox"
              name="lock_import_cost_template_for_customers"
              checked={claim.lock_import_cost_template_for_customers}
              value={claim.lock_import_cost_template_for_customers}
              onChange={handleChange}
            />
          </Row>
        }
        <Row>
          <Form.Label className="col-md-3 col-form-label">
            Normal Taxable Profit/Trading Loss
          </Form.Label>
          <div className="col-md-4">
            <InputGroup>
              <InputGroup.Text>1st CT £</InputGroup.Text>
              <ChangeHighlightedImmutableUnderReviewControl
                as={FormattedNumberInput}
                onChange={handleChange}
                disabled={claim.financials_locked || !hasPermissions()}
                name="normal_profit_loss"
                object={claim}
                size="md"
                type="text"
              />
            </InputGroup>
          </div>
            {claim.is_long_claim_period && (
              <div className="col-md-4">
                <InputGroup>
                  <InputGroup.Text>2nd CT £</InputGroup.Text>
                  <ChangeHighlightedImmutableUnderReviewControl
                    as={FormattedNumberInput}
                    onChange={handleChange}
                    disabled={claim.financials_locked || !hasPermissions()}
                    name="normal_profit_loss_2nd_ct"
                    object={claim}
                    size="md"
                    type="text"
                  />
                </InputGroup>
              </div>
            )}
          </Row>
        </Form.Group>
        {(claim.normal_profit_loss < 0 ||
          claim.normal_profit_loss_2nd_ct < 0) && (
          <>
            <Form.Group>
              <Row>
                <Form.Label className="col-md-4 text-end col-form-label">
                  Utilised Current Year Losses
                </Form.Label>
                {claim.normal_profit_loss < 0 && (
                  <div className="col-md-4">
                    <InputGroup>
                      <InputGroup.Text>1st CT £</InputGroup.Text>
                      <ChangeHighlightedImmutableUnderReviewControl
                        as={FormattedNumberInput}
                        onChange={handleChange}
                        disabled={claim.financials_locked || !hasPermissions()}
                        name="relief_already_obtained"
                        object={claim}
                        size="md"
                        type="text"
                      />
                    </InputGroup>
                  </div>
                )}

                {claim.is_long_claim_period &&
                  claim.normal_profit_loss_2nd_ct < 0 && (
                    <div className="col-md-4">
                      <InputGroup>
                        <InputGroup.Text>2nd CT £</InputGroup.Text>
                        <ChangeHighlightedImmutableUnderReviewControl
                          as={FormattedNumberInput}
                          onChange={handleChange}
                          disabled={claim.financials_locked || !hasPermissions()}
                          name="relief_already_obtained_2nd_ct"
                          object={claim}
                          size="md"
                          type="text"
                        />
                      </InputGroup>
                    </div>
                  )}
              </Row>
            </Form.Group>
            <Form.Group>
              <Row>
                <Form.Label className="col-md-4 text-end col-form-label">
                  Loss Already Surrendered to Group
                </Form.Label>
                {claim.normal_profit_loss < 0 && (
                  <div className="col-md-4">
                    <InputGroup>
                      <InputGroup.Text>1st CT £</InputGroup.Text>
                      <ChangeHighlightedImmutableUnderReviewControl
                        as={FormattedNumberInput}
                        onChange={handleChange}
                        disabled={claim.financials_locked || !hasPermissions()}
                        name="loss_already_surrendered"
                        object={claim}
                        size="md"
                        type="text"
                      />
                    </InputGroup>
                  </div>
                )}

                {claim.is_long_claim_period &&
                  claim.normal_profit_loss_2nd_ct < 0 && (
                    <div className="col-md-4">
                      <InputGroup>
                        <InputGroup.Text>2nd CT £</InputGroup.Text>
                        <ChangeHighlightedImmutableUnderReviewControl
                          as={FormattedNumberInput}
                          onChange={handleChange}
                          disabled={claim.financials_locked || !hasPermissions()}
                          name="loss_already_surrendered_2nd_ct"
                          object={claim}
                          size="md"
                          type="text"
                        />
                      </InputGroup>
                    </div>
                  )}
              </Row>
            </Form.Group>
          </>
        )}
        <Card>
          <Card.Header className="bg-secondary">Notes</Card.Header>
          <Card.Body>
            <NotesList
              notes={claim.financial_notes.filter(note => note.section === 'general')}
              claim={claim}
              setClaim={setClaim}
              section={'general'}
            />
          </Card.Body>
        </Card>
        <Row>
          <Col md={6} className="text-start">
            <Button as={Link} variant="outline-primary" to={`/${accountType}/claim_groups/${claim.claim_group_id}/project_overview`}>
              <span className="material-icons md-18">chevron_left</span> Back
            </Button>
          </Col>
          {
            hasPermissions() &&
            <Col md={6} className="text-end">
              <Button 
                isImmutableUnderReview={true}
                type="submit" variant="outline-primary" className="me-3">
                <span className="material-icons md-18">save</span> Save
              </Button>
              <Button as={Link} variant="primary" to={`/${accountType}/claims/${claim.id}/financial_details/employees_bio`}>
                Continue <span className="material-icons md-18">chevron_right</span>
              </Button>
            </Col>
          }
        </Row>
    </Form>
  </>;
}

export default GeneralSection
