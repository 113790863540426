import React, { useCallback, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import ClaimActivitiesModal from './modals/ClaimActivitiesModal'
import ClaimService from '@services/claim/claim.service'
import claimGroupService from '@rd-web-markets/shared/dist/services/claim_group.service'
import { ClaimGroupActivityService } from '@services/claim_groups/claim_group_activity.service'
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice'
import { useDispatch } from 'react-redux'
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling'

export default function LockUnlockClaim({ claimGroup }) {
  const [ modalVisible, setModalVisible ] = useState(false);
  const [ claimActivities, setClaimActivities ] = useState([]);
  const dispatch = useDispatch();

  const toggleClaimLocked = useErrorHandling(
    useCallback(async () => {
      claimGroup.is_locked = !claimGroup.is_locked;
      await claimGroupService.update(claimGroup)
      window.location.reload()
    }, [])
  );

  useEffect(() => {
    ClaimGroupActivityService.all(claimGroup.id)
      .then(setClaimActivities)
  }, [claimGroup]);

  const linkBtnStyle = {
    textTransform: 'none'
  };

  return (
    <>
      <Button
        variant="info"
        className='mb-3 pb-3 pt-3 ps-3 pe-3'
        size="md"
        onClick={toggleClaimLocked}
      >
        <span className="material-icons md-18">{claimGroup.is_locked ? 'lock_open' : 'lock'}</span> { claimGroup.is_locked ? 'Unlock' : 'Lock' }
      </Button>
      <br />
      <Button
        variant="link"
        style={linkBtnStyle}
        onClick={ () => setModalVisible(true) }
      >
        See claim activity
      </Button>

      <ClaimActivitiesModal
        claimActivities={claimActivities}
        show={modalVisible}
        onHide={ () => setModalVisible(false) }
      />
    </>
  )
}
