import { DeleteButton } from '@rd-web-markets/shared/dist/util/buttons';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ProjectItemName from './ProjectItemName';

const AllCLaimProjectReportsItem = ({report, deleteClaimProjectReport, loading}) => {
  const user = useSelector((state) => state.auth.user);
  const { t } = useTranslation();

  const renderCompanyName = () => {
    if(process.env.PUBLIC_URL === '/de' || process.env.PUBLIC_URL === '/it') {
      return (
        <Link to={`/${user.account_type}/companies/${report.claim_group.company.id}/master`}>{report.claim_group.company.name}</Link>
      )
    } else {
      return (
        report.claim_group.company.name
      )
    }
  }

  return (
    <tr>
      <td className="td-center text-start">
        {renderCompanyName()}
      </td>
      <td className="td-center text-start">
        <ProjectItemName report={report}/>
      </td>
      <td className="td-center text-center"></td>
      { process.env.PUBLIC_URL === '/de' &&
        <>
          <td className="td-center text-center">{report.first_fast_check.lifetime_of_the_customer_project_from?.replaceAll('/', '.')}</td>
          <td className="td-center text-center">{report.first_fast_check.lifetime_of_the_customer_project_to?.replaceAll('/', '.')}</td>
        </>
      }
      { process.env.PUBLIC_URL === '/de' &&
        <>
          <td className="td-center text-center">
            <Link to={`/${user.account_type}/claim_groups/${report.claim_group.id}/technical_proof/project_reports/${report.id}`}>{t('bfsz')}</Link>
          </td>
          <td className="td-center text-center">{t('elster')}</td>
        </>
      }
      <td className='td-center text-center'>
        <Link to={`/${user.account_type}/claim_groups/${report.claim_group.id}/technical_proof/project_reports/${report.id}`}>{t('summary')}</Link>
      </td>
      <td className="td-center text-center">
        <DeleteButton onClick={() => deleteClaimProjectReport(report)} loading={loading} />
      </td>
    </tr>
  )
}

export default AllCLaimProjectReportsItem
