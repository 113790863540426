import React from 'react'

export default function ClaimGroupTechnicalNarrativeTemplateLink({ claimGroup }) {
  const generateTechnicalNarrative = () => {
    window.open(`/api/claim_groups/${claimGroup.id}/technical_narrative_export`);
  };

  return (
    <div
      title='The email includes the technical narrative template file' 
      className='d-flex align-items-center p-2 cursor-pointer' 
      style={{border: '1px solid lightgrey'}}
      onClick={generateTechnicalNarrative}
    >
      <span className='me-2'> Technical Narrative Template {claimGroup.name} </span>
      <span className="material-icons" style={{width: '2rem', height: '2rem', color: '#295394'}}>description</span>
    </div>
  )
}
