import Modal from '@rd-web-markets/shared/dist/util/Modal';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { Card, Form, Row, Col} from 'react-bootstrap';
import { CROSS_MARKET_REPORT_TEMPLATE_DYNAMIC_FIELDS } from '@rd-web-markets/shared/dist/util/constants';
import SelectConsultants from '@rd-web-markets/shared/dist/company/SelectConsultants';
import { fetchAndSetAdmins, fetchAndSetConsultants } from 'src/effects/user.effects';
import { useDispatch } from 'react-redux';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import claimGroupService from '@rd-web-markets/shared/dist/services/claim_group.service';
import { fetchAndSetCompany } from 'src/effects/company.effects';
import { CompanyService } from '@services/company.service';
import { SubmitButton } from '@rd-web-markets/shared/dist/util/buttons';
import { EDITOR_CLAIM_GROUP_DYNAMIC_FIELDS, EDITOR_CLAIM_SPECIFIC_DYNAMIC_FIELDS } from '@rd-web-markets/market/dist/constants';
import TextInputGroup from '@components/util/TextInputGroup';
import { DatePicker } from '@rd-web-markets/shared/dist/util/date';
import claimService from '@services/claim/claim.service';
import { dateToString } from '@rd-web-markets/shared/dist/util/date/dateUtils';
import SelectManager from '@rd-web-markets/shared/dist/company/SelectManager';

export default function EditDynamicFieldsModal ({ accountType, claimGroup, onHide, show, onSubmit }) {
  const [selectedField, setSelectedField] = useState({ title: undefined, claim: undefined })
  const [consultants, setConsultants] = useState(null)
  const [admins, setAdmins] = useState(null)
  const dispatch = useDispatch()
  const [claimGroupCopy, setClaimGroupCopy] = useState({
    ...claimGroup,
    consultants_attributes: claimGroup.consultants_attributes || claimGroup.consultant_roles
  })
  const [company, setCompany] = useState(null)
  const [claim, setClaim] = useState(null)
  const [companyField, setCompanyField] = useState({ name: null, value: null })
  const [claimGroupField, setClaimGroupField] = useState({ name: null, value: null })
  const [modelUpdateIsPending, setModelUpdateIsPending] = useState(false)

  useEffect(() => {
    fetchAndSetConsultants(setConsultants,dispatch, handleError)
    fetchAndSetAdmins(setAdmins, dispatch, handleError)
    fetchAndSetCompany(claimGroup.company_id, setCompany, dispatch, handleError)
  }, [claimGroup.company_id, dispatch]);

  const setConsultantsAttributes = (claimGroup) => {
    setClaimGroupCopy({
      ...claimGroup,
      consultants_attributes: claimGroup.consultants_attributes || claimGroup.consultant_roles
    })
  }

  const reloadPage = () => {
    window.location.reload()
  }

  const updateClaimGroup = async () => {
    try {
      setModelUpdateIsPending(true)
      await claimGroupService.update({
        ...claimGroupCopy,
        [claimGroupField.name]: claimGroupField.value
      })
    } catch (error) {
      dispatch(handleError(error))
    } finally {
      setModelUpdateIsPending(false)
    }
  }

  const updateCompanyField = async () => {
    try {
      setModelUpdateIsPending(true)
      setCompany(await CompanyService.update({
        ...company,
        [companyField.name]: companyField.value
      }))
    } catch (error) {
      dispatch(handleError(error))
    } finally {
      setModelUpdateIsPending(false)
    }
  }

  const updateClaim = async () => {
    try {
      setModelUpdateIsPending(true)
      const updatedClaim = await claimService.update(claim)
      const claimIndex = claimGroupCopy.claims.findIndex(c => c.id === updatedClaim.id)
      claimGroupCopy.claims[claimIndex] = updatedClaim
      setClaim({ ...updatedClaim })
      setClaimGroupCopy({ ...claimGroupCopy })
    } catch (error) {
      dispatch(handleError(error))
    } finally {
      setModelUpdateIsPending(false)
    }
  }

  const claimSpecificDynamicFields = () => {
    return claimGroupCopy.claims.flatMap(claim => {
      return EDITOR_CLAIM_SPECIFIC_DYNAMIC_FIELDS
        .filter(f => f.editableInModal)
        .map(field => ({
          field: `${field.field}__${claim.name}`,
          title: `${field.title} - ${claim.name}`,
          editableInModal: true,
          claim
        }))
    })
  }

  const claimUpdateFieldUI = (field) => {
    const fieldName = field.split('__')[0]
    const claimFieldName = {
      'accounting_period_end_date': 'to_date',
      'accounting_period_start_date': 'from_date',
    }[fieldName]

    return <>
     <Row>
       <Col md={10}>
        <TextInputGroup placeholder="Start Date" prependIcon="calendar_month">
          <DatePicker
            className="border-0"
            selected={claim[claimFieldName]}
            shouldFormatDate={false}
            onChange={date => {
              setClaim({
                ...claim,
                [claimFieldName]: dateToString(date)
              })
            }}
          />
        </TextInputGroup>
       </Col>
       <Col md={2}>
          <SubmitButton
            loading={modelUpdateIsPending}
            onClick={updateClaim}
          />
        </Col>
     </Row>
    </>
  }

  const updateFieldUi = (model, fieldName) => {
    return (
      <>
        <Row>
          <Col md={10}>
            <Form.Control
              value={
                model === 'company' ?
                companyField?.value || company[fieldName]
                :
                claimGroupField.value || claimGroupCopy.with_changes?.[fieldName] || claimGroupCopy[fieldName]
              }
              name={fieldName}
              onChange={event => {
                model === 'company' ?
                  setCompanyField({ name: fieldName, value: event.target.value })
                  :
                  setClaimGroupField({ name: fieldName, value: event.target.value })
              }}
              size="md"
              type="text"
            />
          </Col>
          <Col md={2}>
            <SubmitButton
              loading={modelUpdateIsPending}
              onClick={model === 'company' ? updateCompanyField : updateClaimGroup}
            />
          </Col>
        </Row>
      </>
    );
  }

  const body = () => {
    return (
      <>
        <Card>
          <Card.Body>
              <Form.Group className='mb-2' as={Row}>
                <Form.Label column md={4}>
                  Choose field
                </Form.Label>
                <Form.Control
                    as="select"
                    name="methodology_id"
                    value={selectedField.title}
                    onChange={(e) => {
                      const claim = claimSpecificDynamicFields().find(f => f.field === e.target.value)?.claim
                      setSelectedField({ 
                        title: e.target.value, 
                        claim
                      })
                      setCompanyField(null)
                      setClaim(claim)
                    }}
                    size="md"
                    type="text"
                  >
                    <option value={null} key={'null'}></option>
                    { [
                          ...CROSS_MARKET_REPORT_TEMPLATE_DYNAMIC_FIELDS,
                          ...EDITOR_CLAIM_GROUP_DYNAMIC_FIELDS,
                          ...claimSpecificDynamicFields(),
                      ].filter(f => f.editableInModal).map(f => 
                        <option value={f.field} key={f.field + (f.claim ? f.claim.id : '')}>{f.title}</option>
                      )
                    }
                  </Form.Control>
              </Form.Group>
          </Card.Body>
          <Card.Body>
            { selectedField.title === 'manager' &&
              <SelectManager
                accountType={accountType}
                claimGroup={claimGroupCopy}
                onChange={event => {
                  setClaimGroupCopy({
                    ...claimGroupCopy,
                    admin_id: event.target.value
                  })
                }}
                admins={admins}
              />
            }

            { selectedField.title === 'lead_consultant' &&
              <SelectConsultants
                claimGroup={claimGroupCopy}
                consultantsAndAdmins={[...consultants, ...admins]}
                onClaimGroupConsultantRolesChange={setConsultantsAttributes}
                size='lg'
              />
            }

            { selectedField.title === 'utr' && updateFieldUi('company', 'utr') }
            { selectedField.title === 'company' && updateFieldUi('company', 'name') }
            { selectedField.title === 'abbreviated_name' && updateFieldUi('company', 'abbreviated_name') }
            { selectedField.title === 'contact_to_collaborate_on_rnd' && updateFieldUi('claimGroup', 'technical_contact_name') }

            { !!selectedField.claim && claimUpdateFieldUI(selectedField.title) }
          </Card.Body>
        </Card>
      </>
    );
  };
  
  return (
    <Modal
      show={show}
      size="lg"
      title="Edit Dynamic Fields"
      renderBody={() => body()}
      onHide={reloadPage}
      closeText='Close'
      onSubmit={selectedField.title === 'manager' ?
        () => {
          updateClaimGroup()
          reloadPage()
        }
        :
        null
      }
    />
  );
}
