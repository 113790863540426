import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Card, Breadcrumb, Row, Form, Col } from 'react-bootstrap';
import { Loading } from '@rd-web-markets/shared/dist/util';
import claimGroupQuestionnairesService from '@services/claim_groups/questionnaires.service';
import claimGroupService from '@rd-web-markets/shared/dist/services/claim_group.service';
import { Table } from '@rd-web-markets/shared/dist/util';
import { Button } from '@rd-web-markets/shared/dist/util/buttons';
import SidebarPortal from '@rd-web-markets/shared/dist/util/SidebarPortal';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import moment from 'moment';
import { getDateTimeFormatForTable } from '@rd-web-markets/shared/dist/util/date';
import { useGetUserClaimGroupAbilities } from '@rd-web-markets/market/dist/userAbilities';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import claimProjectReportService from '@rd-web-markets/shared/dist/services/project_report/claim_project_report.service';
import claimProjectReportsListsService from '@rd-web-markets/shared/dist/services/lists/claim_groups/claim_project_reports_lists.service';

const ProjectReportQuestionnairesPage = () => {
  const accountType = useSelector((state) => state.auth.user.account_type);
  const dateTimeFormat = getDateTimeFormatForTable();
  const { t } = useTranslation();
  const { claim_group_id, report_id } = useParams();
  const [searchField, setSearchField] = useState('');
  const [questionnaires, setQuestionnaires] = useState([]);
  const [allReports, setAllReports] = claimProjectReportsListsService.useFetchAll({ accountType, claimGroupId: claim_group_id })
  const [report, setReport] = claimProjectReportService.useGet(claim_group_id, report_id)
  const user = useSelector((state) => state.auth.user);
  const history = useHistory();
  const [claimGroup] = claimGroupService.useGetClaimGroup(claim_group_id);

  const userClaimGroupAbilities = useGetUserClaimGroupAbilities(user, claim_group_id)
  const pageAccess = userClaimGroupAbilities?.pages?.ProjectReportQuestionnairesPage

  const [claimGroupQuestionnaires] = claimGroupQuestionnairesService.useQuestionnaires(claim_group_id);
  
  
  useEffect(() => {
    const questionnaires = claimGroupQuestionnaires?.filter(q => {
      const index = q.claim_project_reports.findIndex(report => report.id === +report_id);
      return index !== -1;
    });
    if (searchField) {
      setQuestionnaires(questionnaires.filter(q => q.name.toLowerCase().includes(searchField.toLowerCase())));
    } else {
      setQuestionnaires(questionnaires);
    }
  }, [claimGroupQuestionnaires, searchField, report_id]);

  const headers = [
    { text: t('date'), sortable: true, order: 'desc' },
    { text: t('claim_group') },
    { text: t('project') },
    { text: t('name'), sortable: true },
    { text: t('sent_by'), sortable: true },
    { text: t('status'), sortable: true },
    { text: t('updated_by'), sortable: true },
    { text: t('actions') }
  ];

  const QuestionnairesButton = ({ id }) => {
    return <Button
      onClick={() => history.push(`/${user.account_type}/claim_groups/${claim_group_id}/technical_proof/project_reports/${report_id}/questionnaires/${id}`)}
      variant="light"
      className="p-1 d-inline-flex me-1"
    >
      <span className="material-icons md-18 text-info mx-auto">edit</span>
    </Button>
  }

  if (!questionnaires || !claimGroup || !allReports || !report) return <Loading />

  const rows = questionnaires?.sort((a, b) => a.created_at > b.created_at ? -1 : 1).map(q => {
    return {
      key: q.id,
      columns: [
        moment(q.created_at).format(dateTimeFormat),
        q.claim_group.name,
        report.project_name,
        q.name,
        q.sent_by,
        q.status,
        q.updated_by,
        pageAccess.canEditQuestionnaire ? <QuestionnairesButton id={q.id}/> : ''
      ]
    }
  });

  const menuItems = allReports.map((report) => {
    return {
      link: `claim_groups/${claim_group_id}/technical_proof/project_reports/${report_id}`,
      text: report.project_name,
      highlighted: report.id === +report_id,
    };
  });

  return (
    <>
      <SidebarPortal headerItem={{link: `claim_groups/${claim_group_id}/project_overview`, text: t('project_overview')}} menuItems={menuItems} />
      <BreadcrumbsPortal>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${user.account_type}`}}>{t('home')}</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${user.account_type}/companies`}}>{t('companies')}</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${user.account_type}/companies/${claimGroup.company_id}/master`}}>{claimGroup.company.name}</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${user.account_type}/companies/${claimGroup.company_id}/${claim_group_id}/project_overview`}}>{claimGroup.name.replace(claimGroup.company.name, '').trim()}</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${user.account_type}/claim_groups/${claim_group_id}/technical_proof/project_reports/${report_id}`}}>{report.project_name}</Breadcrumb.Item>
          <Breadcrumb.Item active>{t('questionnaires')}</Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbsPortal>

      <Card className="mt-3">
        <Card.Header>{`${report.project_name} ${t('questionnaires')}`}</Card.Header>
        <Card.Body>
          <Form.Group className='mb-2' as={Row}>
            <Form.Label column="md" md={1}>
              {t('search')}:
            </Form.Label>
            <Col md={{ span: 5 }}>
              <Form.Control
                onChange={(e) => setSearchField(e.target.value)}
                name="searchField"
                value={searchField}
                size="md"
                type="text"
                placeholder={t('enter_questionnaire_name')}
              />
            </Col>
          </Form.Group>
          <Table
            headers={headers}
            rows={rows}
          />
        </Card.Body>
      </Card>
    </>
  );
}

export default ProjectReportQuestionnairesPage;
