import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
const staffProviderService = {
  create(claimId, staffProvider) {
    return fetch(`/api/claims/${claimId}/staff_providers`, {
      ...request.post,
      body: JSON.stringify({
        staff_provider: staffProvider
      })
    });
  }
};
export default staffProviderService;