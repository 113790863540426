import React, { useState } from 'react';
import { Card, Col, Form, FormControl, InputGroup, Pagination, Row } from 'react-bootstrap';
import { Button } from '@rd-web-markets/shared/dist/util/buttons';

 const SearchBar = ({loading, query: passedQuery, onSubmit, placeholder}) => {
  const [query, setQuery] = useState(passedQuery || '');

  const onSearchSubmit = async (e) => {
    e.preventDefault();
    await onSubmit(query);
  };

  return <Form onSubmit={onSearchSubmit}>
    <Form.Group>
      <InputGroup className='mb-3'>
        <FormControl placeholder={placeholder || ''} value={query} onChange={(e) => setQuery(e.target.value)} />
        <Button type="submit" loading={loading} icon='search' variant="primary" data-testid="btn-searchbar-submit" size="md"></Button>
      </InputGroup>
    </Form.Group>
  </Form>;
};

export default SearchBar;
