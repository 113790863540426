import { isContentOverlappingSelection, isTextNode, loopChildNodes } from "@rd-web-markets/shared/dist/util/domUtils";
import DeletionHighlight from "./DeletionHighlight";
const DYNAMIC_FIELD_PATTERN = /\[ *: *\w+(?: *, *\w[\w|\-|"|'|.|;|&|\s]+\w)* *\]/g;
const DISABLED_BUTTON_SELECTORS_WHILE_EDDITING_DYNAMIC_FIELD = ['*[aria-label="Insert/edit image"]', '*[aria-label="Text color"]', '*[aria-label="Background color"]', '*[aria-label="Align left"]', '*[aria-label="Align center"]', '*[aria-label="Align right"]', '*[aria-label="Justify"]', '*[aria-label="Font sizes"]', '*[aria-label="Fonts"]', '*[aria-label="Insert/edit link"]', '*[aria-label="Italic"]', '*[aria-label="Bold"]', '*[aria-label="Formats"]', '*[aria-label="Add Footnote"]', '*[aria-label="Add Comment"]'];
const DynamicField = {
  /**
   * Checks whether the cursor selection overlaps a dynamic field. If we are deleting elements with Backspace, then a caret position like so(*): [:field]*
   * would mean that we would be editing the dynamic field. Same if we are deleting with Delete and the caret is here *[:field].
   * If we are adding content then a caret position of either *[:field]* is fine.
   * This function works also for non-collapsed document selection where the anchor and focus are in different places.
   * 
   * @param {*} tinyContentNode 
   * @param {*} cursorSelection 
   * @param {*} isAddingContent 
   * @returns 
  */
  isEditingPartOfADynamicField(tinyContentNode, cursorSelection, isAddingContent) {
    // We need to loop through all text node children in so that when we do find a dynamic field - we can get its parent node.
    // When we know the parent node - we can replace text inside it.
    const dynamicFields = [];
    if (isTextNode(tinyContentNode)) {
      let match = DYNAMIC_FIELD_PATTERN.exec(tinyContentNode.textContent);
      while (match !== null) {
        dynamicFields.push({
          text: match[0],
          index: match.index,
          length: match[0].length,
          indexEnd: match.index + match[0].length - 1,
          textNode: tinyContentNode,
          parentElement: tinyContentNode.parentElement
        });
        match = DYNAMIC_FIELD_PATTERN.exec(tinyContentNode.textContent);
      }
    } else {
      loopChildNodes(tinyContentNode, childNode => {
        if (isTextNode(childNode)) {
          const nodeText = childNode.textContent;
          let match = DYNAMIC_FIELD_PATTERN.exec(nodeText);
          while (match !== null) {
            dynamicFields.push({
              text: match[0],
              index: match.index,
              length: match[0].length,
              indexEnd: match.index + match[0].length - 1,
              textNode: childNode,
              parentElement: childNode.parentElement
            });
            match = DYNAMIC_FIELD_PATTERN.exec(nodeText);
          }
        }
      });
    }
    const dynamicFieldBeingEditted = dynamicFields.find(d => {
      const edittingDynamicFieldInAnchorNode = (d.textNode.isSameNode(cursorSelection.anchorNode) || d.parentElement.isSameNode(cursorSelection.anchorNode)) && isContentOverlappingSelection(d.index, d.indexEnd, cursorSelection, isAddingContent);
      const edittingDynamicFieldInFocusNode = (d.textNode.isSameNode(cursorSelection.focusNode) || d.parentElement.isSameNode(cursorSelection.focusNode)) && isContentOverlappingSelection(d.index, d.indexEnd, cursorSelection, isAddingContent);
      return edittingDynamicFieldInAnchorNode || edittingDynamicFieldInFocusNode;
    });
    return dynamicFieldBeingEditted || false;
  },
  /**
   * Simply calls the other function, but passing true for the last argument. Makes it more readable in the code below.
   * @param {*} node 
   * @param {*} selection 
   * @returns 
   */
  isAddingContentInADynamicField(node, selection) {
    return this.isEditingPartOfADynamicField(node, selection, true);
  },
  /**
  * Returns true if the cursor is inside a deletion which is a deleted dynamic field
  * @param {*} tinyContentNode 
  * @param {*} cursorSelection 
  * @returns 
  */
  isSelectionAnchorInDeletedDynamicField(tinyContentNode, cursorSelection) {
    return DeletionHighlight.isSelectionAnchorInHighlight(cursorSelection) && this.isEditingPartOfADynamicField(tinyContentNode, cursorSelection);
  },
  disableMenuButtonsIfTheSelectionFallsInsideADynamicField(editor) {
    const self = this;
    const cursorSelection = editor.selection.getSel();
    const contentNode = editor.selection.getNode();
    const dynamicFieldButtonXPath = "//*[text()='Add Dynamic Value']";
    const dynamicFieldButton = document.evaluate(dynamicFieldButtonXPath, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue?.parentElement;
    const buttons = [...DISABLED_BUTTON_SELECTORS_WHILE_EDDITING_DYNAMIC_FIELD.map(selector => document.querySelector(selector)), dynamicFieldButton].filter(b => !!b);
    buttons.forEach(button => {
      if (self.isEditingPartOfADynamicField(contentNode, cursorSelection) || self.isAddingContentInADynamicField(contentNode, cursorSelection)) {
        if (!button.classList.contains('disabled-btn')) {
          button.setAttribute('disabled', true);
          button.classList.add('disabled-btn');
        }
      } else if (button.classList.contains('disabled-btn')) {
        button.removeAttribute('disabled');
        button.classList.remove('disabled-btn');
      }
    });
  }
};
export default DynamicField;