import React, { useState } from 'react'
import { Card, Row, Col, Accordion } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const ProjectSummarySection = ({
  claimGroup
}) => {
  const [open, setOpen] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const { t } = useTranslation();

  return (
    <Accordion>
      <Card>
        <Accordion.Button
          onClick={() => setOpen(!open)}
          as={Card.Header}
          eventKey="0"
          className="bg-primary bg-gradient text-white cursor-pointer">
          {t('project_summary')}
          <span className="mx-1">
            {open ? (
              <span className="material-icons md-18">expand_more</span>
            ) : (
              <span className="material-icons md-18">chevron_right</span>
            )}
          </span>
        </Accordion.Button>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <Row>
              <Col md={{span: 10, offset: 1}}>
              </Col>
            </Row>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  )
}

export default ProjectSummarySection
