import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import ClaimGeneralEditMarketFields from '@rd-web-markets/market/dist/claim/editForm/ClaimGeneralEditMarketFields';
import ImmutableUnderReviewFormControl from '@rd-web-markets/shared/dist/util/reviewEditControls/ImmutableUnderReviewFormControl';

const ClaimGeneralEditForm = ({claim, handleChange }) => {
  return (
    <>
      <Form.Group className='mb-2' as={Row}>
        <Form.Label column="md" md={3}>
          Name
        </Form.Label>
        <Col md={{ span: 5, offset: 4 }}>
          <ImmutableUnderReviewFormControl
            onChange={handleChange}
            disabled={claim.is_locked || claim.financials_locked}
            name="name"
            value={claim.name || ''}
            size="md"
            type="text"
          />
        </Col>
      </Form.Group>
      <ClaimGeneralEditMarketFields claim={claim} handleChange={handleChange} />
    </>
  )
}

export default ClaimGeneralEditForm
