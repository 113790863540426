import React, { useEffect, useState, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Col, Form, Row, Card, Container } from 'react-bootstrap';
import { getEmailTemplateOptions, apiKey } from '@rd-web-markets/shared/dist/util/tinyMceConfig';
import { Button } from '@rd-web-markets/shared/dist/util/buttons';
import TechnicalTemplateField from '@rd-web-markets/market/dist/email_template/TechnicalTemplateField';
import { ATTACHMENT_OPTIONS, EMAIL_TEMPLATE_DYNAMIC_FIELDS } from '@rd-web-markets/market/dist/constants';
import { FileUpload } from '@rd-web-markets/shared/dist/util';
import { useFileUpload } from '@rd-web-markets/shared/dist/hooks'
import { TEMPLATE_HEADER_TITLE } from '@rd-web-markets/market/dist/constants';
import { useTranslation } from 'react-i18next';

const templateInitials = {
  email_template: {
    id: 0,
    title: '',
    body: '',
    subject: '',
    attach_cost_template: false,
  },
  client_cost_template: {
    id: 0,
    title: '',
    body: '',
    subject: '',
    attach_calendar_appointment: true,
  },
  
  claim_schedule_ics_event_template: {
    id: 0,
    title: '',
    body: '',
    subject: '',
    include_cost_template: true,
  },
  self_declaration_template: {
    id: 0,
    title: '',
    body: '',
    subject: '',
  },
}

const TemplateForm = ({
  loading,
  handleRemoveTemplate,
  handleSubmit,
  templates,
  templateType,
  createdTemplate,
  claimSchedule,
  removeAttachment,
  addAttachment,
  showInModal = false,
  initialSubject = '',
  report,
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [enableSelect, setEnableSelect] = useState(true);
  const hiddenFileInput = useRef(null);
  const [file, isFileUploadLoading, setFile] = useFileUpload(addAttachment)
  const { t } = useTranslation();

  const handleSelect = (e) => {
    if (e.target.value === '0') {
      setSelectedTemplate(templateInitials[templateType]);
    } else {
      setSelectedTemplate(templates.find((t) => t.id === +e.target.value));
    }
  };

  useEffect(() => {
    setSelectedTemplate(createdTemplate);
  }, [createdTemplate]);

  useEffect(() => {
    if (claimSchedule && claimSchedule[`active_${templateType}`]) {
      setSelectedTemplate(claimSchedule[`active_${templateType}`]);
      setEnableSelect(false);
    } else if (report?.active_declaration_email_template){
      setSelectedTemplate(report.active_declaration_email_template);
      setEnableSelect(false);
    }else {
      setSelectedTemplate(null);
    }
  }, [claimSchedule, templateType, report]);


  const handleChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    setSelectedTemplate({
      ...selectedTemplate,
      [event.target.name]: value,
    });
  };

  const handleTinyMce = (content) => {
    setSelectedTemplate({
      ...selectedTemplate,
      body: content,
    });
  };

  const save = async (e) => {
    e.preventDefault();
    await handleSubmit(selectedTemplate);
  };

  const removeTemplate = async (e) => {
    await handleRemoveTemplate(selectedTemplate);
    setEnableSelect(true);
    setSelectedTemplate(null);
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const selectOptions = templates.map((t) => (
    <option key={t.id} value={t.id}>
      {t.title}
    </option>
  ));

  const showDeleteButton = () => {
    if (selectedTemplate.id !== 0) {
      if (!claimSchedule || !enableSelect ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  if (showInModal && selectedTemplate && !selectedTemplate.subject) {
    selectedTemplate.subject = initialSubject;
  }

  return (
    <>
      {!showInModal && <h2 className="text-primary mb-4 mt-2">{t(TEMPLATE_HEADER_TITLE[templateType])}</h2>}
      <Card style={{ width: '100%' }}>
      <Card.Body>
        {enableSelect && (
          <Form.Group className='mb-2' as={Row}>
            <Col sm={1}>
              <Form.Label className='col-form-label'>{t('select_template')}</Form.Label>
            </Col>
            <Col sm={3}>
              <Form.Control as='select' onChange={(e) => handleSelect(e)}>
                <option value={null}>{t('select_template')}</option>
                {selectOptions}
              </Form.Control>
            </Col>
          </Form.Group>
        )}
        {selectedTemplate && (
          <Form onSubmit={save}>
            {enableSelect && <hr />}
            {selectedTemplate.id !== 0 && selectedTemplate.download_links && (
              <Form.Group className='mb-2' as={Row}>
                <Col md={12} className='text-end'>
                <FileUpload
                  title={t('add_attachment')}
                  loading={isFileUploadLoading}
                  file={file}
                  onSelectFile={setFile}
                  floatRight
                />
                  <div style={{ marginBottom: '30px'}}></div>
                  {selectedTemplate.download_links.map((attachment, index) => {
                    return (
                      <>
                        <br />
                        <span className="material-icons" style={{ width: '1.5rem', height: '1.5rem', color: 'darkCyan', marginRight: '2px' }}>description</span>
                        <span className=''></span>
                        <a href={attachment.url} target='_blank' rel='noreferrer'>
                          {attachment.filename}
                        </a>
                        <small>
                          <span 
                            onClick={() => removeAttachment(attachment.id, index)}
                            className="material-icons" 
                            style={{ cursor: 'pointer', width: '1.5rem', height: '1.5rem', color: 'red', marginLeft: '2px' }}
                          >
                            close
                          </span>{' '}
                        </small>
                      </>
                    );
                  })}
                </Col>
              </Form.Group>
            )}
            <Form.Group className='mb-2' as={Row}>
              {!showInModal && (
                <>
                  <Col sm={3}>
                    <Form.Label className='col-form-label'>Name</Form.Label>
                  </Col>
                  <Col sm={3}>
                    <Form.Control type='text' value={selectedTemplate.title} name='title' onChange={handleChange} />
                  </Col>
                </>
              )}
              {showInModal && (
                <>
                  <Col sm={1}>
                    <Form.Label className='col-form-label'>{t('subject')}</Form.Label>
                  </Col>
                  <Col sm={3}>
                    <Form.Control type='text' value={selectedTemplate.subject} name='subject' onChange={handleChange} />
                  </Col>
                </>
              )}

              <Col sm={{ span: 5, offset: 1 }}>
                {templateType === 'claim_schedule_ics_event_template' && (
                  <Form.Check
                    type='checkbox'
                    name='attach_calendar_appointment'
                    checked={selectedTemplate.attach_calendar_appointment}
                    value={selectedTemplate.attach_calendar_appointment}
                    onChange={handleChange}
                    label='Attach Calendar Appointment'
                  />
                )}
                {templateType === 'email_template' && (
                  <>
                    {ATTACHMENT_OPTIONS.attachCostTemplate &&
                      <Form.Check
                        type='checkbox'
                        name='attach_cost_template'
                        checked={selectedTemplate.attach_cost_template}
                        value={selectedTemplate.attach_cost_template}
                        onChange={handleChange}
                        label='Include Cost Template as an attachment'
                      />
                    }
                    {
                      ATTACHMENT_OPTIONS.technicalTemplateField && 
                        <TechnicalTemplateField handleChange={handleChange} selectedTemplate={selectedTemplate} />
                    }
                    
                    {ATTACHMENT_OPTIONS.includeTechnicalUploadField &&
                      <Form.Check
                        style={{ color: '#0078b9' }}
                        type='checkbox'
                        name='include_technical_upload_link'
                        checked={selectedTemplate.include_technical_upload_link}
                        value={selectedTemplate.include_technical_upload_link}
                        onChange={handleChange}
                        label='Include Tax Computation Upload Link'
                      />
                    }

                    {ATTACHMENT_OPTIONS.includeCostTemplateLink &&
                      <Form.Check
                      style={{ color: '#0078b9' }}
                      type='checkbox'
                      name='include_cost_template_link'
                      checked={selectedTemplate.include_cost_template_link}
                      value={selectedTemplate.include_cost_template_link}
                      onChange={handleChange}
                      label='Include Cost Template Upload Link'
                    />
                    }
                  </>
                )}
                {templateType === 'client_cost_template' && (
                  <Form.Check
                    type='checkbox'
                    name='include_cost_template'
                    checked={selectedTemplate.include_cost_template}
                    value={selectedTemplate.include_cost_template}
                    onChange={handleChange}
                    label='Include Cost Template as an attachment'
                  />
                )}
              </Col>
            </Form.Group>
            <Form.Group>
              <Form.Label>{t('body')}</Form.Label>
              <Editor
                apiKey={apiKey}
                onEditorChange={handleTinyMce}
                value={selectedTemplate.body}
                name='body'
                init={getEmailTemplateOptions(templateType, EMAIL_TEMPLATE_DYNAMIC_FIELDS)}
              />
            </Form.Group>
            {showDeleteButton() && (
              <Button
                icon='delete'
                iconPosition='left'
                loading={loading}
                className='btn btn-danger float-start'
                onClick={() => removeTemplate()}
              >
                <span>{t('delete_email_draft')}</span>
              </Button>
            )}
            <Button type='submit' variant='primary' className='float-end' loading={loading} icon="save">
              {t('save_email_draft')}
            </Button>
          </Form>
        )}
      </Card.Body>
    </Card>
    </>
  );
};

export default TemplateForm;
