import { CHARACTER_NO_BREAK_SPACE, CHARACTER_WORD_JOINER, ZERO_WIDTH_NO_BREAK_SPACE, findAllChildTextNodes, matchNodeOrAnyParentElement } from '../../../domUtils';
import { setCursorPositionToEndOfElement } from '../track_changes/cursorUtils';
import { v4 as uuidv4 } from 'uuid';
import AdditionHighlight from '../track_changes/models/AdditionHighlight';
import { acceptChange, rejectChange } from '../track_changes/acceptRejectChanges';

// it is quite impossible to prevent the user from deleting the footnotes section from the screen,
// but we can recreate it
let oldFootnotesSection = null;
let oldFootnoteMarkerElements = [];
let oldFootnoteContentElements = [];
function findAllFootnoteMarkers(editorBody) {
  return Array.from(editorBody.querySelectorAll('.aym-footnote-marker'));
}
function findFootnotesSection(editorBody) {
  return editorBody.querySelector('.aym-footnotes');
}

/**
 * The footnotes section is part of the editor for the purpose of being able to 
 * use the track changes in the footnotes text. This is also how the official tinymce
 * footnotes plugin implements the functionality. This one is better though because in the
 * official plugin version it is possible to accidentally delete the footnotes section.
 * This function prevents that by checking if on edit of the text - nothing about the footnotes changed,
 * apart from the footnotes not existing anymore. It works even if there are multiple editors on the page.
 * If only the footnotes section was deleted, it recreates it from memory.
 * @param {*} editorBody 
 */
function ensureFootnotesSectionWasNotDeletedByMistake(editorBody) {
  if (oldFootnotesSection) {
    const currentFootnotesSectionWasDeleted = !findFootnotesSection(editorBody);
    const footnoteMarkersHaveNotChanged = findAllFootnoteMarkers(editorBody).length === oldFootnoteMarkerElements.length;
    const oldFootnotesSetionHadContent = oldFootnotesSection.querySelectorAll('li').length > 0;
    if (currentFootnotesSectionWasDeleted && footnoteMarkersHaveNotChanged && oldFootnotesSetionHadContent) {
      editorBody.appendChild(oldFootnotesSection);
    }
  }
  oldFootnotesSection = findFootnotesSection(editorBody)?.cloneNode(true) || null;
}

/**
 * Each footnote, while in the editor (not in the preview), has a LI element and inside it - a DIV element.
 * The user writes text in the DIV. It is possible for the user to delete the DIV, so if that happens, we recreate it.
 * @param {*} editorBody 
 */
function ensureFootnotesAreEditable(editorBody) {
  const footnoteListItems = Array.from(editorBody.querySelectorAll('.aym-footnotes-list-item'));
  footnoteListItems.forEach(footnoteListItem => {
    const markerId = footnoteListItem.getAttribute('data-footnote-marker-id');
    if (!footnoteListItem.querySelector('.aym-footnote-content')) {
      footnoteListItem.innerHTML = `<div class="aym-footnote-content" data-footnote-marker-id="${markerId}" contenteditable="true">&nbsp;</div>`;
    }
  });
}

/**
 * In track changes - when we add deletion highlight to a footnote marker,
 * automatically add it to the footnote itself
 * @param {*} editorBody 
 */
function fixHighlightDeletionFootnoteColor(editorBody) {
  const allMarkers = Array.from(editorBody.querySelectorAll('.aym-footnote-marker'));
  const deletedMarkers = allMarkers.filter(el => el.querySelector('.TrackChanges-Highlight-Deletion'));
  deletedMarkers.forEach(marker => {
    const footnoteId = marker.getAttribute('data-footnote-id');
    const footnoteContent = editorBody.querySelector(`.aym-footnote-content[data-footnote-marker-id="${footnoteId}"]`);
    if (footnoteContent) {
      footnoteContent.parentElement.classList.add('TrackChanges-Highlight');
      footnoteContent.parentElement.classList.add('TrackChanges-Highlight-Deletion'); // li element
      footnoteContent.parentElement.style.display = 'list-item';
      // TODO: try to fix
      // Setting this color is required because the backend ayming styles formatter does not use css classes.
      // Then if we reject the change of the LI element, this one's color will automatically be returned to whatever color
      // the backend ayming styles formatters wants it to be.
      footnoteContent.style.color = 'red';
    }
  });
}

/**
 * When we delete a footnote marker - automatically delete the footnote itself
 * @param {*} editorBody 
 */
function removeDeletedFootnotes(editorBody) {
  const footnoteListItems = Array.from(editorBody.querySelectorAll('.aym-footnotes-list-item'));
  const footnotesListItemsWithoutMarkers = footnoteListItems.filter(footnoteListItem => {
    const markerId = footnoteListItem.getAttribute('data-footnote-marker-id');
    const matchingMarker = editorBody.querySelector(`.aym-footnote-marker[data-footnote-id="${markerId}"]`);
    return !matchingMarker;
  });
  footnotesListItemsWithoutMarkers.forEach(el => el.remove());
  const allMarkers = Array.from(editorBody.querySelectorAll('.aym-footnote-marker'));
  const markersWithoutMatchingLiElement = allMarkers.filter(m => {
    const markerId = m.getAttribute('data-footnote-id');
    const matchingLiElement = editorBody.querySelector(`.aym-footnotes-list-item[data-footnote-marker-id="${markerId}"]`);
    return !matchingLiElement;
  });
  markersWithoutMatchingLiElement.forEach(el => el.remove());
}
function acceptFootnoteChange(editor, editorBody) {
  const allMarkers = Array.from(editorBody.querySelectorAll('.aym-footnote-marker'));
  allMarkers.forEach(marker => {
    const markerId = marker.getAttribute('data-footnote-id');
    const oldMarkerEl = oldFootnoteMarkerElements.find(oldEl => oldEl.getAttribute('data-footnote-id') === markerId);
    if (oldMarkerEl && oldMarkerEl.querySelector('.TrackChanges-Highlight-Addition') && !marker.querySelector('.TrackChanges-Highlight-Addition')) {
      // we have accepted the marker itself, so we should accept all content of the footnote
      const matchingFootnoteContentEl = editorBody.querySelector(`.aym-footnote-content[data-footnote-marker-id="${markerId}"]`);
      const contentChangeEls = Array.from(matchingFootnoteContentEl.querySelectorAll('.TrackChanges-Highlight-Addition'));
      contentChangeEls.forEach(el => {
        acceptChange(editor, el);
      });
    }
  });
  const allFootnoteContentEls = Array.from(editorBody.querySelectorAll('.aym-footnote-content'));
  allFootnoteContentEls.forEach((el, footnoteIndex) => {
    const oldContentEl = oldFootnoteContentElements.find(oldEl => oldEl.getAttribute('data-footnote-marker-id') === el.getAttribute('data-footnote-marker-id'));
    if (oldContentEl && oldContentEl.querySelector('.TrackChanges-Highlight-Addition') && !el.querySelector('.TrackChanges-Highlight-Addition')) {
      // all additions in this footnote are accepted, or removed
      const markerId = el.getAttribute('data-footnote-marker-id');
      const matchingMarker = editorBody.querySelector(`.aym-footnote-marker[data-footnote-id="${markerId}"]`);
      const markerChangeEl = matchingMarker.querySelector('.TrackChanges-Highlight-Addition');
      if (markerChangeEl) {
        acceptChange(editor, markerChangeEl);
      }
    }
  });
}

/**
 * Observes the editor body's dom for changes.
 * 'childList' means that something was - added to / changed in / removed from -  the dom.
 * @param {*} editor 
 */
function addEditorBodyDomObserver(editor, footnoteStartNumber) {
  const editorBody = editor.getBody();
  var observer = new MutationObserver(function (mutationsList, observer) {
    // Handle the mutations here
    mutationsList.forEach(function (mutation) {
      if (mutation.type === 'childList') {
        // something changed in the content dom
        // this one should be caled first
        ensureFootnotesSectionWasNotDeletedByMistake(editorBody);
        fixHighlightDeletionFootnoteColor(editorBody);
        removeDeletedFootnotes(editorBody);
        acceptFootnoteChange(editor, editorBody);
        sortFootnotes(editor, footnoteStartNumber);
        ensureFootnotesAreEditable(editorBody);

        // saves the old markers and old footnotes for later.
        // this may be optimized
        oldFootnoteMarkerElements = Array.from(editorBody.querySelectorAll('.aym-footnote-marker')).map(n => n.cloneNode(true));
        oldFootnoteContentElements = Array.from(editorBody.querySelectorAll('.aym-footnote-content')).map(n => n.cloneNode(true));
      }
    });
  });
  var observerConfig = {
    childList: true,
    subtree: true
  };

  // Start observing the target node for DOM mutations
  observer.observe(editorBody, observerConfig);
}

/**
 * Creates a footnote marker HTML (the superscript number)
 * @param {*} isTrackingChanges 
 * @returns 
 */
function createFootnoteMarkerHTML() {
  let isTrackingChanges = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  let footnote = null;
  footnote = document.createElement('span');
  if (isTrackingChanges) {
    footnote.innerHTML = AdditionHighlight.createHighlightedElement('&#8288;', null, null, 'span').outerHTML;
  } else {
    footnote.innerHTML = '&#8288;';
  }
  footnote.classList.add('aym-footnote-marker');
  footnote.setAttribute('data-footnote-id', uuidv4());
  return [footnote, footnote.outerHTML];
}

/**
 * Used to expand and shring the footnotes section. This is done in order to
 * make it difficult to accidentally delete the footnotes section, while at the same time
 * make the footnotes not go in the way of the normal text editing.
 * @param {*} editor 
 */
function toggleFootnoteSectionOnClick(editor) {
  editor.on('click', event => {
    const footnotesToggle = matchNodeOrAnyParentElement(event.target, node => node?.classList?.contains('aym-footnotes-toggle'));
    if (footnotesToggle) {
      footnotesToggle.parentElement.classList.toggle('aym-footnotes--expanded');
    }
  });
}

/**
 * Make sure that if we are trying to add a new footnote, the footnotes section exists.
 * This is relevant when we open the editor and we do not have any footnotes yet.
 * Or when we delete all footnotes, and then delete the footnotes section.
 * @param {*} editorBody 
 */
function ensureFootnotesSectionExists(editorBody) {
  let aymingFootnotesSection = findFootnotesSection(editorBody);
  if (!aymingFootnotesSection) {
    aymingFootnotesSection = document.createElement('div');
    aymingFootnotesSection.classList.add('aym-footnotes');
    aymingFootnotesSection.setAttribute('contenteditable', 'false');
    editorBody.appendChild(aymingFootnotesSection);
  }
  if (!aymingFootnotesSection.querySelector('.aym-footnotes-toggle')) {
    const div = document.createElement('div');
    div.style = 'display: flex; justify-content: space-between; align-items: center;';
    div.innerHTML = `
      <div>
        <img height="16px" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAABrElEQVR4nO2az06DQBDGv4Qe1HDR+mQ7J5+uXozvpZ69tLSG2mAwi0ELy/4Z4LPhS/bQMJ2ZHxvamWEBoEpYBwAZ0pVZX1XC+vXhCOA9YG2gp9pXSOyjC+QTwAP4JQDKLpBT60JpDf8DRGlz/wEpAJiWAevOSEeOxV8QkMNIT26dIKww4sipF4QNRgZycYKwwIhHDoMgc8OIZ2wvkLlgJCCmN8jUMBIYKwhkKhiJiBEMMjZMDMQZyDYyoFY5k+Jz24AcIqpYTZhUXxvLEN1PaMBo3ZAMiUp5ZmKfidEUA0MH0ZXYHsAa/Vq32lrK3kdsgm8Acoddbm32jBCN7gcgGuUDu7Zo0aJFi4J/fq887K6tLaUEwAeAFwA3Drv62qu1pftDlFaJUjc6tw7bO9YSRSIKQIZRk1oVqw6zuoDGagXr4GmmBDR8PdvvfTvYTRhY2+cuZhw0ZmcX+8wEz7WmaE/N2AO6KXtsM9bIdI5BgdEeYs857TBarxUYRjYm9UUPA4QvTC8IE4QPTCcII8QQzBkIM4QLprjIIxwV+U64dobumNOj5jGn0EVz8OwLG8btlAmDgsoAAAAASUVORK5CYII=">
      </div>
      <div>footnotes</div>
      <div></div>
    `;
    div.classList.add('aym-footnotes-toggle');
    div.style.cursor = 'pointer';
    aymingFootnotesSection.prepend(div);
  }
  if (!aymingFootnotesSection.querySelector('ol')) {
    const orderedList = document.createElement('ol');
    orderedList.setAttribute('contenteditable', 'false');
    aymingFootnotesSection.appendChild(orderedList);
  }
  aymingFootnotesSection.querySelector('ol');
}

/**
 * Gets the index of the footnote marker with matching marker id.
 * Since footnotes are ordered from 1, not from 0, we add 1 to the index.
 * @param {*} editor 
 * @param {*} markerId 
 * @returns 
 */
function getCorrectFootnoteNumber(editor, markerId) {
  const editorBody = editor.getBody();
  const footnoteMarkerElement = editorBody.querySelector(`.aym-footnote-marker[data-footnote-id="${markerId}"]`);
  const allMarkers = Array.from(editorBody.querySelectorAll('.aym-footnote-marker'));
  const index = allMarkers.indexOf(footnoteMarkerElement);
  return index + 1;
}

/**
 * Orders the footnotes inside the editor, based on the index of the matching footnote marker.
 * The lower the marker is in the dom, the higher the number on the footnote.
 * @param {*} editor 
 * @returns 
 */
function sortFootnotes(editor, footnoteStartNumber) {
  const editorBody = editor.getBody();

  // This one was just deleted and its class messes up the sorting.
  // We want to remove the class, but not the element itself, so that the caret position remains
  // the same.
  const footnoteInsideCaret = editorBody.querySelector('#_mce_caret .aym-footnote-marker');
  if (footnoteInsideCaret && footnoteInsideCaret.textContent === ZERO_WIDTH_NO_BREAK_SPACE) {
    footnoteInsideCaret.classList.remove('aym-footnote-marker');
  }
  const footnotesSectionOl = editorBody.querySelector('.aym-footnotes > ol');
  if (!footnotesSectionOl) {
    // if there are no footnotes, tinymce deletes those div and ol
    return;
  }
  if (footnotesSectionOl.getAttribute('start') !== `${footnoteStartNumber}`) {
    footnotesSectionOl.setAttribute('start', `${footnoteStartNumber}`);
  }
  const footnotes = Array.from(footnotesSectionOl.querySelectorAll('li'));
  const allMarkers = Array.from(editorBody.querySelectorAll('.aym-footnote-marker')).filter(marker => marker.textContent !== ZERO_WIDTH_NO_BREAK_SPACE);
  allMarkers.forEach((marker, index) => {
    const changeEl = marker.querySelector('.TrackChanges-Highlight');
    const footnoteNumer = `${footnoteStartNumber + index}`;
    if (changeEl && changeEl.innerHTML !== footnoteNumer) {
      changeEl.innerHTML = footnoteNumer;
    } else if (!changeEl && marker.innerHTML !== footnoteNumer) {
      marker.innerHTML = footnoteNumer;
    }
  });

  // correctNumber is only used for sorting
  const sortedFootnotes = footnotes.map(footnote => ({
    footnote,
    correctNumber: getCorrectFootnoteNumber(editor, footnote.getAttribute('data-footnote-marker-id'))
  })).sort((a, b) => a.correctNumber - b.correctNumber);
  let shouldUpdateDom = false;
  sortedFootnotes.forEach((sortedFootnote, index) => {
    if (!sortedFootnote.footnote.isEqualNode(footnotes[index])) {
      shouldUpdateDom = true;
    }
  });
  if (shouldUpdateDom) {
    sortedFootnotes.forEach(_ref => {
      let {
        footnote
      } = _ref;
      return footnotesSectionOl.appendChild(footnote);
    });
  }
}

// It is possible to select with the mouse - a word and the space after it.
// In that case the footnote would appear far away from the word - 1 space away.
// Its possible that the user double clicks on the word and the browser selects the word
// and the space after it. This is something like a styling fix for that - make sure to 
// place the footnote marker next to the word (before the space).
// 
// If the char on the left is part of the word - then focusOffset is correct.
// Otherwise focusOffset should decrease, until the char on the left is part of the word.
function changeFocusOffsetToMoveFootnoteLocationCloserToWord(focusNode, focusOffset) {
  const charsToCheck = [' ', CHARACTER_NO_BREAK_SPACE, CHARACTER_WORD_JOINER];
  let backOffset = 0;
  while (charsToCheck.includes(focusNode.textContent[focusOffset - backOffset - 1])) {
    backOffset += 1;
  }
  return focusOffset - backOffset;
}

/**
 * Adds a new LI element to the footnotes section.
 * @param {*} editor 
 * @param {*} markerId 
 * @param {*} text 
 */
function createFootnoteTextHTML(editor, markerId, text) {
  const footnoteLiElement = document.createElement('li');
  footnoteLiElement.classList.add('aym-footnotes-list-item');

  // content editable for the inner div - so we can edit text inside it
  footnoteLiElement.innerHTML = `<div class="aym-footnote-content" data-footnote-marker-id="${markerId}" contenteditable="true">&nbsp;</div>`;
  footnoteLiElement.setAttribute('data-footnote-marker-id', markerId);

  // content editable false for the li element - so that we cannot delete it
  footnoteLiElement.setAttribute('contenteditable', 'false');
  const footnotesSectionOl = editor.getBody().querySelector('.aym-footnotes > ol');
  footnotesSectionOl.appendChild(footnoteLiElement);
}

/**
 * Makes sure that the footnotes section exists.
 * Adds a new footnote marker and a new footnote.
 * Sorts the footnotes. This is relevant when we have a text with footnotes, and we add a new one,
 * closer to the beginning of the text.
 * @param {*} editor 
 * @param {*} isTrackingChanges 
 */
function addFootnote(editor) {
  let isTrackingChanges = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  const editorBody = editor.getBody();
  // TinyMCE changes its contents when opening a dialog modal, and looses its initial caret placement.
  // Not sure why this happens, but we need to set it back, when inserting the content.
  // this is a let, because of the focusOffset change below.
  let {
    focusNode: oldFocusNode,
    focusOffset
  } = editor.selection.getSel();

  // Tinymce does something with the textnodes inside the editor and somehow the old
  // selection focusNode, anchorNode etc, are no longer descendants of the editor body.
  // Maybe they are cloned, or whatever. So we need to find a text node that contains the
  // text content of the original text node, and select it(put the caret there).
  const newFocusNode = findAllChildTextNodes(editorBody).find(node => node.textContent.includes(oldFocusNode.textContent));
  focusOffset = changeFocusOffsetToMoveFootnoteLocationCloserToWord(newFocusNode, focusOffset);
  editor.selection.setCursorLocation(newFocusNode, focusOffset);

  // prepends a zero-width no-break space before the footnote element in order to prevent 
  // the case where you add several footnotes, and they become nested - e.g. <span></span></span></span>

  const [footnoteMarker, footnoteHTML] = createFootnoteMarkerHTML(isTrackingChanges);
  const finalFootNoteHTML = `&#8288;${footnoteHTML}`;
  editor.execCommand('mceInsertContent', false, finalFootNoteHTML);
  ensureFootnotesSectionExists(editorBody);
  createFootnoteTextHTML(editor, footnoteMarker.getAttribute('data-footnote-id'));

  //TODO: find last inserted footnote by attribute
  const lastInsertedMarkerInDom = editor.contentDocument.querySelector(`*[data-footnote-id="${footnoteMarker.getAttribute('data-footnote-id')}"]`);
  setCursorPositionToEndOfElement(editor, lastInsertedMarkerInDom);
}

/**
 * These styles are moved here in the footnotes file to make the functionality self-contained.
 * Since we load the editors in iframes, these styles are added to the head tag of the iframe document.
 * @param {*} editor 
 * @returns 
 */
function addFootnotesStyle(editor) {
  const editorDocument = editor.getDoc();
  const footnotesStyles = `
    .aym-footnotes {
      background-color: #efefef;
      position: fixed;
      bottom: 0;
      width: 97%;
      max-height: 20px;
      transition: max-height 0.15s ease-out;
    }

    .aym-footnotes.aym-footnotes--expanded {
      max-height: 300px;
      transition: max-height 0.25s ease-in;
      overflow-y: auto;
    }

    .aym-footnote-marker {

      /* Specified in % so that the sup/sup is the
        right size relative to the surrounding text */
      font-size: 75%;

      /* Zero out the line-height so that it doesn't
        interfere with the positioning that follows */
      line-height: 0;

      /* Where the magic happens: makes all browsers position
        the sup/sup properly, relative to the surrounding text */
      position: relative;

      /* Note that if you're using Eric Meyer's reset.css, this
        is already set and you can remove this rule */
      vertical-align: baseline;

      /* Move the superscripted text up */
      top: -0.5em;
    }
  `;
  const styleElement = document.createElement('style');
  styleElement.appendChild(document.createTextNode(footnotesStyles));
  editorDocument.head.appendChild(styleElement);
}

/**
 * Creates the footnotes button in the editor menu.
 * @param {*} editor 
 * @param {*} isTrackingChanges 
 */
function createFootnoteButton(editor, isTrackingChanges) {
  editor.ui.registry.addIcon('aym-footnote', `
  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="34" height="34" viewBox="0 0 50 50">
<path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 19.535156 16 L 19.535156 34 L 21.78125 34 L 21.78125 26.203125 L 29.751953 26.203125 L 29.751953 24.207031 L 21.78125 24.207031 L 21.78125 18.021484 L 30.462891 18.021484 L 30.462891 16 L 19.535156 16 z"></path>
</svg>
  `);
  editor.ui.registry.addButton('footnote', {
    icon: 'aym-footnote',
    tooltip: 'Add Footnote',
    onAction: function (_) {
      addFootnote(editor, isTrackingChanges);
      editor.fire('change', {
        content: editor.getContent()
      });
    }
  });
}
const footnotes = {
  name: 'footnotes',
  addIfApplicable(editor, _ref2) {
    let {
      isTrackingChanges = false,
      footnoteStartIndex = 0
    } = _ref2;
    createFootnoteButton(editor, isTrackingChanges);
    editor.on('init', function (e) {
      // calls these functions here, so that they get executed once on load, and
      // later in the observer - on each edit
      fixHighlightDeletionFootnoteColor(editor.getBody());
      removeDeletedFootnotes(editor.getBody());
      addEditorBodyDomObserver(editor, footnoteStartIndex + 1);
      addFootnotesStyle(editor);
      toggleFootnoteSectionOnClick(editor);
      sortFootnotes(editor, footnoteStartIndex + 1); // does iniitial sorting of the footnotes on init
    });

    // Registers an event handler that checks if we have just rejected the deletion highlight of a list footnote item,
    // and if so - rejects the deletion highlight of the corresponding footnote marker.
    // This works because if the marker has the highlights, that means that the whole foonote is deleted, and if we reject it,
    // then we should undelete the marker as well. If the user simply deleted a part of the text of the footnote, then on rejection
    // nothing happens, since the marker does not have the highlight classes.
    editor.on('TrackChanges:rejectChangeForElementAfter', _ref3 => {
      let {
        payload
      } = _ref3;
      const {
        node,
        changeTypeClass,
        newEditorBody
      } = payload;
      const editorBody = newEditorBody || editor.getBody();
      if (node.classList.contains('aym-footnotes-list-item') && changeTypeClass === 'TrackChanges-Highlight-Deletion') {
        const markerId = node.getAttribute('data-footnote-marker-id');
        const matchingMarker = editorBody.querySelector(`.aym-footnote-marker[data-footnote-id="${markerId}"]`);
        const markerChangeEl = matchingMarker.querySelector('.TrackChanges-Highlight');
        if (markerChangeEl) {
          rejectChange(editor, markerChangeEl, false);
        }
      }
    });

    // Registers an event handler that checks if we have just rejected the deletion highlight of a footnote marker,
    // and if so - rejects the deletion highlight of the corresponding footnote list item.
    editor.on('TrackChanges:rejectChangeForElementBefore', _ref4 => {
      let {
        payload
      } = _ref4;
      const {
        node,
        changeTypeClass,
        newEditorBody
      } = payload;
      const editorBody = newEditorBody || editor.getBody();
      if (node.parentElement.classList.contains('aym-footnote-marker') && changeTypeClass === 'TrackChanges-Highlight-Deletion') {
        const markerId = node.parentElement.getAttribute('data-footnote-id');
        const matchingFootnoteContentEl = editorBody.querySelector(`.aym-footnotes-list-item[data-footnote-marker-id="${markerId}"]`);
        if (matchingFootnoteContentEl.classList.contains('TrackChanges-Highlight')) {
          rejectChange(editor, matchingFootnoteContentEl, false);
          matchingFootnoteContentEl.querySelector('.aym-footnote-content').style.color = '';
        }
      }
    });
    return true;
  }
};
export default footnotes;