import { createEditorSelectionWithAllRelatedTrackChangesElements, findNextPreviousNonAdjacentChanges } from "./findNextPreviousChange";

/**
 * 
 * @param {*} editor 
 * @param {*} moduleInstance 
 * @param {String} direction "next" | "previous"
 */
export function triggerCommentsReload(editor, moduleInstance, direction) {
  const currentPopoverChangeElements = moduleInstance.currentPopover?.changeElements || [];
  const nonAdjacentChanges = findNextPreviousNonAdjacentChanges(editor, currentPopoverChangeElements);
  const nextPreviousChangeElementToUse = nonAdjacentChanges[`${direction}PopoverChangeElement`];
  const changeElementToUse = nextPreviousChangeElementToUse || editor.getBody().querySelector('TrackChanges-Highlight');
  if (changeElementToUse) {
    moduleInstance.createPopover(editor, changeElementToUse);
    const editorSelectionForComments = createEditorSelectionWithAllRelatedTrackChangesElements(editor, changeElementToUse);
    editor.fire('Comments:triggerChangeSelectedCommentSpanId', {
      payload: {
        cursorSelection: editorSelectionForComments
      }
    });
  }
}