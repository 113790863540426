import React, { useState } from 'react'
import { Card, Accordion, Tabs, Tab } from 'react-bootstrap'
import ObjectChangeSetsList from './ObjectChangeSetsList'
import CostManagerReviewControls from './CostManagerReviewControls'

const ClaimCostManagerQAReviews = ({
    claim,
    claimGroup,
    loading,
    company,
    createChangeSet,
    approveChangeSet,
    rejectChangeSet,
    finalizeChangeSet,
    createChangeSetsForAllClaimsInClaimGroup
  }) => {
    const [open, setOpen] = useState(false);

  return (
    <Accordion>
      <Card className='shadow border-0'>
        <Accordion.Button
          onClick={() => setOpen(!open)}
          as={Card.Header}
          eventKey="0"
          className="bg-primary bg-gradient text-white cursor-pointer">
          Manager Cost Review
          <span className="mx-1">
            {open ? (
              <span className="material-icons md-18">expand_more</span>
            ) : (
              <span className="material-icons md-18">chevron_right</span>
            )}
          </span>
        </Accordion.Button>
        <Accordion.Collapse eventKey="0">
        <Card.Body>
        { claim && 
          <>
            <CostManagerReviewControls
              createChangeSet={createChangeSet}
              approveChangeSet={approveChangeSet}
              rejectChangeSet={rejectChangeSet}
              finalizeChangeSet={finalizeChangeSet}
              reviewedObject={claim}
              reviewedObjectClass='Claim'
              loading={loading}
              company={company}
              claimGroup={claimGroup}
              createChangeSetsForAllClaimsInClaimGroup={createChangeSetsForAllClaimsInClaimGroup}
            />

            <ObjectChangeSetsList object={claim} />
          </>
        }

        {/* If by any chance */}
        { !claim && <span>Please create a Claim</span> }
        </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}

export default ClaimCostManagerQAReviews
