import { buildService } from '@rd-web-markets/shared/dist/services/service';
import serviceMethods from '@rd-web-markets/shared/dist/services/service_methods';
import { replaceInArray } from '@rd-web-markets/shared/dist/util/arrayUtils/arrayUtils';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
const api = buildService('/claims/{id}/benefit_calculations', {
  only: ['all']
});
const useFetchAll = _ref => {
  let {
    claimId,
    setLoading
  } = _ref;
  const [calculations, setCalculations, fetchCalculations] = serviceMethods.useFetch({
    callback: useCallback(async () => await api.all(claimId), [claimId]),
    setLoading
  });
  return [calculations, setCalculations, fetchCalculations];
};
const benefitCalculationsService = {
  useFetchAll
};
export default benefitCalculationsService;