import React from 'react';
import { Card, Form, Row, Col } from 'react-bootstrap';
import { Button } from '@rd-web-markets/shared/dist/util/buttons';

const TranslationForm = ({selectedKeyValue, handleSubmit, closeForm, handleChange, loading}) => {
  return (
    <Card>
      <Card.Body>
        <Form>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Key*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Key"
                onChange={handleChange}
                name="key"
                value={selectedKeyValue.key}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Value</Form.Label>
              <Form.Control
                type="text"
                onChange={handleChange}
                name="value"
                placeholder="Value"
                value={selectedKeyValue.value}
              />
            </Form.Group>
          </Row>
        </Form>
      </Card.Body>
      <Card.Footer>
        <Row className="justify-content-end">
          <Button
            onClick={() => handleSubmit()}
            icon='save'
            iconPosition={'left'}
            loading={loading}
            variant="primary">
            Save
          </Button>
          <Button
            className="ms-2"
            onClick={() => closeForm()}
            loading={loading}
            variant="light">
            Close Form
          </Button>
        </Row>
      </Card.Footer>
    </Card>
  )
}

export default TranslationForm