import { CROSS_MARKET_REPORT_TEMPLATE_DYNAMIC_FIELDS } from './constants';
import footnotes from './textEdit/modules/footnotes/';
import track_changes from './textEdit/modules/track_changes';
import comments from './textEdit/modules/comments';
import styles_formatter from './textEdit/modules/styles_formatter';
import resize_image_when_added from './textEdit/modules/resize-image-when-added';
import { ALLOWED_SPAN_CHILDREN, getContentStyle } from './textEdit/trachChangesEditorStyles';
import { TINYMCE_MARKET_REPORT_MODULES, TINYMCE_MARKET_EMAIL_MODULES, TINYMCE_MARKET_OTHER_MODULES } from '@rd-web-markets/market/dist/constants';
import { ACCEPTED_COLORS } from './textEdit/modules/styles_formatter/settings';
import AdditionHighlight from './textEdit/modules/track_changes/models/AdditionHighlight';
function fixImageDimensions() {}

/**
 * Loads only the modules that should be loaded for the current market.
 * In addition - e.g. the track changes depends on whether we are tracking changes, otherwise it shouldnt be loaded.
 * 
 * To do that we loop through all the modules and pass the current editor, as well as the options - e.g. isTrackingChanges.
 * Each module now has a function to add it to the editor which uses these options.
 * @param {*} editor 
 * @param {*} options 
 */
function loadTinyMCEModules(editor, marketModulsForEditorType, options) {
  // since we are saving the loaded modules on the editor instance for later use,
  // we should make copies of them, so that we dont ever end up in a case where we reference old data
  const tinyMCEModules = {
    footnotes: {
      ...footnotes
    },
    track_changes: {
      ...track_changes
    },
    comments: {
      ...comments
    },
    styles_formatter: {
      ...styles_formatter
    },
    resize_image_when_added: {
      ...resize_image_when_added
    }
  };
  editor.AYMING_MODULES = [];

  // if we pass modules { track_change: false } then we should not load the track_changes module
  const eligibleModules = marketModulsForEditorType.filter(moduleName => !options.modules || options.modules[moduleName] !== false);
  eligibleModules.forEach(moduleName => {
    const module = tinyMCEModules[moduleName];
    const isAdded = module.addIfApplicable(editor, options);
    if (isAdded) {
      editor.AYMING_MODULES.push(tinyMCEModules[moduleName]);
    }
  });
}

/**
 * There is a bug that happens if we open a tinymce dialog modal, when the tinymce editor is opened insde a bootrstrap modal.
 * The tinymce modal goes outside the bootstrap modal in the DOM. The bootstrap modal keeps the focus on itself.
 * As a result, it is impossible to focus an input field in the tynimce modal.
 * All events, properties, arguments, etc did not work, so the current solution fixes that issue, by
 * monitoring all dom changes.
 * 
 * When we open the tinymce editor it adds a specific div element which is used as a container to all tinymce dialog modals
 * opened in the future. This fix checks when the bootstrap modal is added to the dom and saves it. Then when the tinymce
 * modal container is added to them dom - it moves it under the bootstrap modal div. This way when we focus an input field
 * in the tinymce modal, the focus is still inside the bootstrap modal, and it works.
 */
function addTinyBootstrapModalObserver() {
  // no need to add it twice
  if (window.TINY_BOOTSTRAP_MODAL_OBSERVER) {
    return;
  }
  let latestModal = null;
  var observer = new MutationObserver(function (mutationsList, observer) {
    // Handle the mutations here
    mutationsList.forEach(function (mutation) {
      if (mutation.type === 'childList') {
        const newNode = mutation.addedNodes[0];

        // check whether the current node is a bootstrap modal div
        if (newNode?.getAttribute && newNode.getAttribute('role') === 'dialog' && newNode.classList.contains('modal')) {
          latestModal = newNode;
        }

        // check whether the current node is a tinymce modal container - and if so - move it under the latest bootstrap modal
        if (newNode?.classList?.value === 'tox tox-silver-sink tox-tinymce-aux' && !(newNode.parentElement.getAttribute('role') === 'dialog')) {
          // in case we open the editor outside a modal, the latestModal variable will be null
          latestModal?.appendChild(newNode);
        }
      }
    });
  });
  var observerConfig = {
    childList: true,
    subtree: true
  };

  // Start observing the target node for DOM mutations
  observer.observe(document.body, observerConfig);
  window.TINY_BOOTSTRAP_MODAL_OBSERVER = observer;
}
function createDynamicFieldInsertContent(fieldMarkup, isTrackingChanges) {
  const finalInsertMarkup = isTrackingChanges ? AdditionHighlight.createHighlightedElement(fieldMarkup).outerHTML : fieldMarkup;
  return `&puncsp;<span style="color: #666E7E; font-family: franklin gothic book; font-size: 11pt">${finalInsertMarkup}</span>`;
}
function getDefaultSetup(moduleOptions) {
  return function (editor) {
    loadTinyMCEModules(editor, TINYMCE_MARKET_OTHER_MODULES, {
      ...moduleOptions
    });
  };
}
function getReportTemplateSetup(claimGroupEditorFields, perClaimEditorFields, moduleOptions) {
  return function (editor) {
    loadTinyMCEModules(editor, TINYMCE_MARKET_REPORT_MODULES, {
      ...moduleOptions
    });
    const items = [{
      type: 'menuitem',
      text: 'Pluralize (by number of cost claims)',
      onAction: function () {
        const content = createDynamicFieldInsertContent('[:pluralize]', moduleOptions.isTrackingChanges);
        editor.insertContent(content);
      }
    }, ...CROSS_MARKET_REPORT_TEMPLATE_DYNAMIC_FIELDS.map(f => ({
      type: 'menuitem',
      text: f.title,
      onAction: function () {
        const content = createDynamicFieldInsertContent(`[:${f.field}]`, moduleOptions.isTrackingChanges);
        editor.insertContent(content);
      }
    }))];
    perClaimEditorFields.forEach(field => {
      items.push({
        type: 'menuitem',
        text: field.text,
        onAction: function () {
          const content = createDynamicFieldInsertContent(`[:${field.insertContent}]`, moduleOptions.isTrackingChanges);
          editor.insertContent(content);
        }
      });
    });
    claimGroupEditorFields.forEach(field => {
      items.push({
        type: 'menuitem',
        text: field.text,
        onAction: function () {
          const content = createDynamicFieldInsertContent(`[:${field.insertContent}]`, moduleOptions.isTrackingChanges);
          editor.insertContent(content);
        }
      });
    });
    editor.ui.registry.addMenuButton('dynamic', {
      text: 'Add Dynamic Value',
      fetch: function (callback) {
        callback(items);
      }
    });
  };
}
function getEmailTemplateSetup(templateType, emailTemplateDynamicFileds) {
  return function (editor) {
    loadTinyMCEModules(editor, TINYMCE_MARKET_EMAIL_MODULES, {});
    let emailDynamicFields = emailTemplateDynamicFileds[templateType];
    const items = emailDynamicFields.map(f => ({
      type: 'menuitem',
      text: f.title,
      onAction: function () {
        editor.insertContent(createDynamicFieldInsertContent(`[:${f.field}]`, false));
      }
    }));
    editor.ui.registry.addMenuButton('dynamic', {
      text: 'Add Dynamic Value',
      fetch: function (callback) {
        callback(items);
      }
    });
  };
}
function getPerClaimEditorFields(reportTemplate, marketClaimSpecificFields) {
  if (reportTemplate.claims?.length > 0) {
    let editorFields = [];
    reportTemplate.claims.forEach(claim => {
      const dynamicFields = marketClaimSpecificFields.map(f => ({
        text: `${f.title} - ${claim.name}`,
        insertContent: `${f.field},${claim.name}`
      }));
      editorFields = editorFields.concat(dynamicFields);
    });
    return editorFields;
  } else {
    return [];
  }
}
function getClaimGroupEditorFields(marketClaimGroupFields) {
  const dynamicFields = marketClaimGroupFields.map(field => ({
    text: `${field.title}`,
    insertContent: `${field.field}`
  }));
  return dynamicFields;
}
export const defaultConfig = {
  plugins: ['image', 'link', 'paste', 'save', 'lists', 'advlist', 'wordcount', 'code'],
  menubar: false,
  branding: false,
  paste_block_drop: true,
  // this line and the inclusion of 'paste' plugin above - remove the ability to drop dragged content inside the editor. Conventional copy/paste still works.
  paste_data_images: true,
  // this makes it possible to paste inline images in the editor
  image_uploadtab: false,
  image_title: false,
  image_description: false,
  image_dimensions: false,
  forced_root_block: 'div',
  custom_elements: '~track_changes_change,track_changes_comment,track_changes_change_block',
  valid_children: [...ALLOWED_SPAN_CHILDREN.map(x => `+span[${x}]`), ...ALLOWED_SPAN_CHILDREN.map(x => `+track_changes_change[${x}]`)].join(','),
  // extended_valid_elements: 'span[data-track-changes-original-color]'
  extended_valid_elements: "+@[data-track-changes-original-color]",
  fontsize_formats: '8pt 9pt 10pt 11pt 12pt 13pt 14pt 15pt 16pt 17pt 18pt 19pt 20pt 21pt 22pt 23pt 24pt 25pt 26pt 27pt 28pt 29pt 30pt 31pt 32pt 33pt 34pt 35pt 36pt',
  lineheight_formats: '0 0.5 0.6 0.7 1 1.1 1.2 1.3 1.4 1.5 2',
  content_style: getContentStyle(false),
  font_formats: 'Georgia=georgia, regular; Franklin Gothic Demi=franklin gothic demi; Franklin Gothic Book=franklin gothic book',
  file_picker_types: 'image',
  // The images dataimg filter is needed to tell tinymce to keep the images' src attributes as base64 data.
  // Otherwise when TinyMCE loads the image - it saves its base64 data into the data-mce-src attribute and
  // then converts the src to some url. This causes a problem, because the code for adding comments is set up
  // to add the comments to the innerHTML of the editor body. However at this point the image's src is already
  // converted to a url, so when we save the content, we save the url to the database and consequently break the image.
  // Here we ignore all images that have the data-mce-src attribute - therefore all base64 images.
  images_dataimg_filter: function (img) {
    return img.hasAttribute('data-mce-src');
  },
  paste_webkit_styles: 'all',
  paste_retain_style_properties: 'all',
  // paste_merge_formats is required for the track changes when pasting text. In order (when accepting a pasted text change) to
  // return the accepted text in its original color. E.g. pasting text in ayming blue, making it green, and when accepting it -
  // returning it to ayming blue. We use a custom data-track-changes-original-color attribute on the new pasted html. To have a 
  // green color we strip any colors from the style attribute. That leads to having a case like this:
  // <div><span style="xyz" data-attr-blue><track_changes_change><span style="xyz" data-attr-red>text</span></track_changes_change></span></div>
  // In that case the paste plugin by default will merge the 2 spans, because their style attributes are the same and we will lose the 
  // knowledge that the inner span was ayming red.
  paste_merge_formats: false,
  // sets a predefined width and height to all pasted images
  paste_postprocess: function (plugin, args) {
    const editor = args.target;
    editor.AYMING_MODULES.forEach(module => {
      module.paste_preprocess?.call(null, args);
    });
  },
  file_picker_callback: function (cb, value, meta) {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.onchange = function () {
      const file = this.files[0];
      const reader = new FileReader();
      reader.onload = function () {
        const id = 'blobid' + new Date().getTime();
        const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
        const base64 = reader.result.split(',')[1];
        const blobInfo = blobCache.create(id, file, base64);
        blobCache.add(blobInfo);
        cb(blobInfo.blobUri(), {
          title: file.name
        });
      };
      reader.readAsDataURL(file);
    };
    input.click();
  },
  color_map: ['#666E7E', 'Ayming Gray', '#00AEEF', 'Ayming Blue', '#A20A42', 'Ayming Red']
};
const TOOLBARS = {
  DEFAULT_CONFIG_TOOLBAR: ['wordcount | undo redo | styleselect | bold italic | link image footnote | dynamic | fontselect | fontsizeselect | forecolor | backcolor | alignleft aligncenter alignright alignjustify | numlist bullist'],
  REPORT_TEMPLATE_CONFIG_TOOLBAR: ['wordcount | undo redo | styleselect | bold italic | link image footnote | dynamic | fontselect | fontsizeselect | forecolor | backcolor | alignleft aligncenter alignright alignjustify | numlist bullist | styles_formatter_toggle'],
  DEFAULT_TRACK_CHANGES_TOOLBAR: ['wordcount | undo redo | styleselect | bold italic | link image footnote | dynamic | | fontselect | fontsizeselect | forecolor | backcolor | alignleft aligncenter alignright alignjustify | numlist bullist | comments_view_toggle', 'track_changes_accept track_changes_reject track_changes_accept_all track_changes_reject_all | track_changes_toggle_changes | add_comment | track_changes_previous_change | track_changes_next_change'],
  REPORT_TEMPLATE_TRACK_CHANGES_TOOLBAR: ['wordcount | undo redo | styleselect | bold italic | link image footnote | dynamic | | fontselect | fontsizeselect | forecolor | backcolor | alignleft aligncenter alignright alignjustify | numlist bullist | styles_formatter_toggle | comments_view_toggle', 'track_changes_accept track_changes_reject track_changes_accept_all track_changes_reject_all | track_changes_toggle_changes | add_comment | track_changes_previous_change | track_changes_next_change']
};
function getFullModuleOptions(providedModuleOptions) {
  const defaultModuleOptions = {
    isTrackingChanges: false,
    onAddCommentButtonClickCallback: () => {},
    onNextChangeButtonClickCallback: () => {},
    showTrackChangesControls: false,
    footnoteStartIndex: 0,
    toggleTrackChangesVisibility: true,
    onStylesFormatterToggled: () => {},
    stylesFormatterToggle: true,
    nextChange: () => {},
    previousChange: () => {}
  };
  const fullModuleOptions = {
    ...defaultModuleOptions,
    ...providedModuleOptions
  };
  return fullModuleOptions;
}
export const apiKey = 'o9kqqlyz26o6rxv1o2xq807104qdz1ez1f4t1yqfqwzg58vk';

/**
 * 
 * @param {*} moduleOptions 
 *  moduleOptions example:
 *      isTrackingChanges: false,
 *      onAddCommentButtonClickCallback: () => {},
 *      showTrackChangesControls: false,
 *      footnoteStartIndex: 5
 *       
 *      // Everything under modules is true by default.
 *      // Defines which modules to add to the editor.
 *      // E.g. when we are editing a claim project report's title we dont want to have the styles formatter.
 *      // Because the project report titles always have a predefined style which does not match what the styles formatter is doing for the normal text.
 *      modules: { 
 *       footnotes: true,
 *       track_changes: false,
 *       comments: false,
 *       styles_formatter: false,
 *       resize_image_when_added: false
 *      }
 * 
 * @returns 
 */
export function getDefaultConfig() {
  let moduleOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const fullModuleOptions = getFullModuleOptions(moduleOptions);
  const tinyMceConfig = {
    ...defaultConfig
  };
  tinyMceConfig.content_style = getContentStyle(fullModuleOptions.isTrackingChanges);
  tinyMceConfig.setup = getDefaultSetup(fullModuleOptions);
  const prefix = moduleOptions.modules?.styles_formatter ? 'REPORT_TEMPLATE' : 'DEFAULT';
  const trackChangesSuffix = fullModuleOptions.isTrackingChanges ? 'TRACK_CHANGES' : 'CONFIG';
  let toolbar = TOOLBARS[`${prefix}_${trackChangesSuffix}_TOOLBAR`];
  if (moduleOptions.modules?.hideWordCount) {
    tinyMceConfig.toolbar = [toolbar[0].replace(/wordcount/g, '')];
    tinyMceConfig.plugins = defaultConfig.plugins.filter(p => p !== 'wordcount');
  } else {
    tinyMceConfig.toolbar = toolbar;
  }
  addTinyBootstrapModalObserver();
  return tinyMceConfig;
}

/**
 * 
 * @param {Object} reportTemplate 
 * @param {Array} marketClaimGroupFields 
 * @param {Array} marketClaimSpecificFields 
 * @param {Object} moduleOptions  - {}
 * 
 *  moduleOptions example:
 *      isTrackingChanges: false,
 *      onAddCommentButtonClickCallback: () => {},
 *      showTrackChangesControls: false,
 *      footnoteStartIndex: 5
 * @returns 
 */
export function getReportTemplateOptions(reportTemplate, marketClaimGroupFields, marketClaimSpecificFields) {
  let moduleOptions = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  const fullModuleOptions = getFullModuleOptions(moduleOptions);
  const claimGroupFields = getClaimGroupEditorFields(marketClaimGroupFields);
  const perClaimEditorFields = getPerClaimEditorFields(reportTemplate, marketClaimSpecificFields);
  const tinyMceConfig = {
    ...defaultConfig
  };
  tinyMceConfig.content_style = getContentStyle(fullModuleOptions.isTrackingChanges);
  tinyMceConfig.setup = getReportTemplateSetup(claimGroupFields, perClaimEditorFields, fullModuleOptions);
  tinyMceConfig.toolbar = fullModuleOptions.isTrackingChanges ? TOOLBARS.REPORT_TEMPLATE_TRACK_CHANGES_TOOLBAR : TOOLBARS.REPORT_TEMPLATE_CONFIG_TOOLBAR;
  addTinyBootstrapModalObserver();
  return tinyMceConfig;
}
export function getEmailTemplateOptions(templateType, emailTemplateDynamicFileds) {
  const tinyMceConfig = {
    ...defaultConfig
  };
  tinyMceConfig.setup = getEmailTemplateSetup(templateType, emailTemplateDynamicFileds);
  tinyMceConfig.toolbar = TOOLBARS.DEFAULT_CONFIG_TOOLBAR;
  if (process.env.PUBLIC_URL === '/uk') {
    tinyMceConfig.toolbar += ' code';
  }
  addTinyBootstrapModalObserver();
  return tinyMceConfig;
}