import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import SidebarPortal from '@rd-web-markets/shared/dist/util/SidebarPortal';
import { useCallback, useEffect, useState } from 'react';
import { Breadcrumb, Card, Col, Form, Row } from 'react-bootstrap';
import { Link, useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { t } from 'i18next';
import claimGroupService from '@rd-web-markets/shared/dist/services/claim_group.service';
import useConsultantRole from '@rd-web-markets/shared/dist/hooks/useConsultantRole';
import { CancelButton, SaveButton } from '@rd-web-markets/shared/dist/util/buttons';
import claimProjectReportService from '@rd-web-markets/shared/dist/services/project_report/claim_project_report.service';

const EditClaimProjectReportPage = ({handleToaster}) => {
  const { claim_group_id, claim_project_report_id } = useParams();
  const [ getConsultantRole ] = useConsultantRole();
  const history = useHistory();
  const projectListPath = `/admin/time_tracking/claim_groups/${claim_group_id}/claim_project_reports`

  const [claimGroup] = claimGroupService.useGetClaimGroup(claim_group_id);
  const [claimProjectReport, setClaimProjectReport] = claimProjectReportService.useGet(claim_group_id, claim_project_report_id)
  const [assignee, setAssignee] = useState(claimGroup?.consultant_roles.find((u) => u.id == claimProjectReport?.users?.[0]?.id));
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState();
  
  useEffect(() => {
    setUsers(claimGroup?.consultant_roles);
  }, [claimGroup]);

  useEffect(() => {
    if (claimProjectReport?.users) {
      setAssignee(users?.find((u) => u.id == claimProjectReport?.users[0]?.id));
    }
  }, [claimProjectReport, users]);

  const updateClaimProjectFn = claimProjectReportService.useUpdateClaimProjectReport(claim_group_id, claimProjectReport);

  function findAndSetUser(e) {
    setAssignee(users.find((u) => u.id == e.target.value));
  }

  const success = () => {
    handleToaster('User assigned successfully');
    history.push(projectListPath);
  };

  const submit = (e) => {
    e.preventDefault();

    const params = {
      user_ids: [
        assignee.id
      ]
    };

    updateClaimProjectFn(params, success);
  };

  return(
    <>
      <SidebarPortal headerItem={{link: `time_tracking/claim_groups/${claim_group_id}/claim_project_reports`, text: 'List of Projects'}} />
      <BreadcrumbsPortal>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: '/admin'}}>{t('home')}</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: projectListPath}}>{t('list_of_projects')}</Breadcrumb.Item>
          <Breadcrumb.Item active>{t('edit_project')}</Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbsPortal>
      <Card>
        <Card.Header className='bg-primary bg-gradient text-white'>{t('edit_project')}</Card.Header>
        <Card.Body>
        <Form  onSubmit={submit}>
          <Row>
            <Col md={12}>
              <Form.Group className='mb-2' as={Row}>
                <Form.Label column='md' md={3}>
                  {t('company')}
                </Form.Label>
                <Col md={{ span: 5, offset: 4 }}>
                  <Form.Control 
                    as='select'
                    data-testid='company-select'
                    value={claimGroup?.company_id}
                    disabled={true}
                    >
                      <option value={claimGroup?.company_id}>{claimGroup?.company.abbreviated_name}</option>
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group className='mb-2' as={Row}>
                <Form.Label column='md' md={3}>
                  {t('claim_group')}
                </Form.Label>
                <Col md={{ span: 5, offset: 4 }}>
                  <Form.Control 
                    as='select'
                    data-testid='claim-group-select'
                    value={claimGroup?.id}
                    disabled={true}
                    >
                      <option value={claimGroup?.id}>{claimGroup?.name}</option>
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group className='mb-2' as={Row}>
                <Form.Label column='md' md={3}>
                  {t('project')}
                </Form.Label>
                <Col md={{ span: 5, offset: 4 }}>
                  <Form.Control 
                    as='select'
                    data-testid='project-select'
                    value={claimProjectReport?.id}
                    disabled={true}
                    >
                      <option value={claimProjectReport?.id}>{claimProjectReport?.project_name}</option>
                    </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group className='mb-2' as={Row}>
                <Form.Label column='md' md={3}>
                  {t('assignee')}
                </Form.Label>
                <Col md={{ span: 5, offset: 4 }}>
                  <Form.Control 
                    as='select'
                    data-testid='user-select'
                    value={assignee?.id}
                    onChange={(e) => findAndSetUser(e)}
                    >
                      <option></option>
                      {users?.map((u) => <option data-testid='claim-group-user-option' key={u.id} value={u.id}>{`${u.name} ${getConsultantRole(u.role)}`}</option>)}
                    </Form.Control>
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col className='text-end'>
              <CancelButton
                as={Link}
                to={projectListPath}
                className='me-2'
              />
              <SaveButton loading={loading} text={t('save')}/>
            </Col>
          </Row>
        </Form>
        </Card.Body>
      </Card>
    </>
  )
};

export default EditClaimProjectReportPage;
