/**
 * Receives an array, a function that finds an element, and a new item.
 * Finds the FIRST matching element in the array and replaces it with the provided new item.
 * Does NOT modify the original array.
 * 
 * Returns a NEW array where the matched item is replaced with the passed newItem.
 * 
 * @param {*} array The original array.
 * @param {*} matcher Function to find the first matching element and remove it.
 * @param {*} newItem New element to place at the index of the removed element.
 * @returns 
 */
export function replaceArrayItem(array, matcher, newItem) {
  const indexofExistingItem = array.findIndex(matcher);

  // ignore if there is nothing to replace
  if (indexofExistingItem < 0) {
    return array;
  }
  const updatedArray = [...array.slice(0, indexofExistingItem), newItem, ...array.slice(indexofExistingItem + 1, array.length)];
  return updatedArray;
}

/**
 * Higher order function.
 * Receives a matched function to match the first item in an array, and a newItem (to replace the matched item).
 * Returns a function that accepts an array - and uses the matcher and newItem - to replace the first matching item in it with the newItem.
 * E.g. if we have a react state variable that is an array, and we want to replace one of its elements we can call use it like so:
 * 
 * // Assume:
 * const [stateArray, setStateArray] = useState([{ id: 3 },  { id: 4 },  { id: 5 },  { id: 6 }])
 * // then call
 * setStateArray(replaceInArray(item => item.id === 4), { id: 7 })
 * // Results in stateArray being equal to:
 * [{ id: 3 },  { id: 7 },  { id: 5 },  { id: 6 }]
 * 
 * @param {*} matcher Function to find an index of an element (only the first matching element)
 * @param {*} newItem Item to replace the found element with.
 * @returns 
 */
export function replaceInArray(matcher, newItem) {
  return stateArray => replaceArrayItem(stateArray, matcher, newItem);
}