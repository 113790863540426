import { buildService } from '@rd-web-markets/shared/dist/services/service';
import serviceMethods from '@rd-web-markets/shared/dist/services/service_methods';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
const api = buildService('/project_questionnaire_documents', {}, {
  only: ['postFormData']
});
const useUpload = _ref => {
  let {
    setLoading,
    handleToaster
  } = _ref;
  const {
    t
  } = useTranslation();
  //accepts an object with the formDataJson field and any other fields.
  const postFormData = serviceMethods.usePostFormData({
    setLoading,
    onError: useCallback(() => handleToaster('error', t('upload_failed')), [handleToaster, t]),
    callback: useCallback(async _ref2 => {
      let {
        formData
      } = _ref2;
      const result = await api.postFormData(formData);
      handleToaster('success', t('upload_success'));
      window.location.reload();
      return result;
    }, [handleToaster, t])
  });
  return postFormData;
};
const ProjectQuestionnaireTemplateService = {
  useUpload,
  download: params => fetch(`/api/project_questionnaire_documents?${params}`).then(response => {
    if (!response.ok) {
      throw new Error('Failed to fetch template document');
    }
    return response.blob();
  })
};
export default ProjectQuestionnaireTemplateService;