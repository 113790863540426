import { AddUserButton } from '@rd-web-markets/shared/dist/util/buttons'
import claimScheduleService from '@rd-web-markets/shared/dist/services/claim_schedule.service'
import React, { useCallback, useMemo, useState } from 'react'
import { ListGroup, Form, Col } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice'
import { useTranslation } from 'react-i18next';
import ContactCard from '@rd-web-markets/shared/dist/claim_group/claim_schedule/ContactCard';
import userAccessService from '@rd-web-markets/shared/dist/services/user_access.service'
import claimGroupRolesService from '@rd-web-markets/shared/dist/services/claim_groups/claimGroupRoles.service'

export default function EmailPreviewModalRecipients({ claimGroup, claimSchedule, onClaimGroupRolesupdate, emailType, setClaimGroup }) {
  const isCostEmail = emailType === 'cost'
  const [clientEmailToInclude, setClientEmailToInclude] = useState(null);
  const [consultantEmailToInclude, setConsultantEmailToInclude] = useState(null);
  const dispatch = useDispatch()
  const { t } = useTranslation();
  const clientContacts = useMemo(() => 
    [...claimGroup.user_accesses], 
    [claimGroup.user_accesses]
  )

  const excludeClientField = useMemo(() => ({
    'cost': 'exclude_from_cost_template_email',
    'kickoff': 'exclude_from_introduction_email',
  }), [])

  const excludeManagerField = useMemo(() => ({
    'cost': 'exclude_manager_from_cost_template_email',
    'kickoff': 'exclude_manager_from_introduction_email',
  }), [])

  const isManagerExcluded = useCallback((claimSchedule) => {
    return claimSchedule[excludeManagerField[emailType]]
  }, [emailType, excludeManagerField])

  const updateEmailParticipations = useCallback(async (allParticipations, updatedParticipation) => {
    try {
      await userAccessService.update(claimGroup.id, updatedParticipation);
      const clientToUpdate = claimGroup.user_accesses.find(c => c.id === updatedParticipation.id)
      clientToUpdate[excludeClientField[emailType]] = updatedParticipation[excludeClientField[emailType]]
      setClaimGroup({ ...claimGroup })

    } catch (error) {
      dispatch(handleError(error));
    }
  }, [claimGroup.id, dispatch]);

  const toggleClientExcludeFromEmail = useCallback((clientEmail, isExcluded) => {
    if (!clientEmail) {
      return;
    }

    const updatedParticipation = clientContacts.find(p => p.user.email === clientEmail)
    updatedParticipation[excludeClientField[emailType]] = isExcluded
    
    updateEmailParticipations(clientContacts, updatedParticipation);
    setClientEmailToInclude(null);
  }, [clientContacts, emailType, excludeClientField, updateEmailParticipations]);

  const toggleConsultantExcludeFromEmail = useCallback(async (consultant, isExcluded) => {
    try {
      await claimGroupRolesService.toggleConsultantExcludeFromEmail(claimGroup.id, consultant.id, isExcluded, emailType)
      setConsultantEmailToInclude(null);
      onClaimGroupRolesupdate()
    } catch (error) {
      dispatch(handleError(error));
    }
  }, [claimGroup.id, dispatch, emailType, onClaimGroupRolesupdate])

  function getClientContactsForEmail(excluded = false) {
    if (!excluded) {
      return clientContacts.filter(participation => !participation[excludeClientField[emailType]])
    } else {
      return clientContacts.filter(participation => participation[excludeClientField[emailType]])
    }
  }

  function getConsultantsForEmail(excluded = false) {
    const consultantEmailRoles = claimGroup.consultant_email_roles || []
    const emailConsultants = consultantEmailRoles.filter(r => r.role === claimGroupRolesService.emailRoles[emailType])
    const claimGroupConsultants = claimGroup.consultant_roles.filter(c => c.id !== claimGroup.admin.id)
    if (!excluded) {
      return emailConsultants
    } else {
      return claimGroupConsultants.filter(c => !(emailConsultants.find(cc => cc.id === c.id)))
    }
  }

  const updateEmailManagerExcluded = async (isExcluded, isCostEmail) => {
    const claimScheduleResult = await claimScheduleService.updateEmailManagerIncluded(claimGroup.id, claimSchedule, isExcluded, isCostEmail)

    claimGroup.claim_schedule[excludeManagerField[emailType]] = isExcluded
    setClaimGroup({ ...claimGroup })
  }

  const clientContactsForEmail = getClientContactsForEmail()
  const consultantsForEmail = getConsultantsForEmail()

  return (
    <>
      <h4>Recipients:</h4>
      <h5 className='pt-2 bm-2'>{t('client_contacts')}</h5>
      <div className='d-flex mb-3'>
        <Col md={5}>
          <select className="form-control" onChange={e => setClientEmailToInclude(e.target.value)}>
            <option value={null}></option>
            {getClientContactsForEmail(true).map((contact) => {
              return (
                <option key={contact.user.id} value={contact.user.email}>
                  {contact.user.name} - {contact.user.email}
                </option>
              );
            })
            }
          </select>
        </Col>
        <Col md={3}>
          <AddUserButton onClick={() => toggleClientExcludeFromEmail(clientEmailToInclude, false)} emailToInclude={clientEmailToInclude} />
        </Col>
      </div>
      <div className='d-flex mb-3'>
        {
          clientContactsForEmail.map((contact) =>
            <Col md={3}>
              <ContactCard
                user={contact.user}
                roles={contact.roles}
                handleExclude={() => toggleClientExcludeFromEmail(contact.user.email, true)}
              />
            </Col>
          )
        }
      </div>
      <hr />
      <h5>{t('ayming_contacts')}</h5>
      <div className='d-flex'>
        <Col md={3}>
          <ContactCard
            showAymingLogo
            user={{
              name: claimGroup.admin.name,
              email: claimGroup.admin.email,
            }}
            roles={['Manager']}
          />
        </Col>
        <Form.Check
          type='checkbox'
          name='include_manager'
          checked={isManagerExcluded(claimSchedule)}
          value={isManagerExcluded(claimSchedule)}
          onChange={() => updateEmailManagerExcluded(!isManagerExcluded(claimSchedule), isCostEmail)}
          label={t('exclude_from_welcome_email')}
        />
      </div>

      <ListGroup variant='flush'>
        {isManagerExcluded(claimSchedule) && isCostEmail && (
          <ListGroup.Item className='d-flex align-items-center justify-content-end'>
            <div className='me-2'>Add manager to recipients</div>
            <AddUserButton onClick={() => updateEmailManagerExcluded(false, isCostEmail)} />
          </ListGroup.Item>
        )}
      </ListGroup>
      <hr />

      <div className='d-flex mb-3'>
        <Col md={5}>
          <select className="form-control" onChange={e => setConsultantEmailToInclude(e.target.value)}>
            <option value={null}></option>
            {getConsultantsForEmail(true).map((c) => {
              return (
                <option key={c.id} value={c.email}>
                  {c.name} - {c.email}
                </option>
              );
            })
            }
          </select>
        </Col>
        <Col md={3}>
          <AddUserButton
            emailToInclude={consultantEmailToInclude}
            onClick={() => toggleConsultantExcludeFromEmail(
              getConsultantsForEmail(true).find(c => c.email === consultantEmailToInclude),
              false
            )}
          />
        </Col>
      </div>

      <div className='d-flex mb-3'>
        {
          consultantsForEmail.map((consultant) =>
            <Col md={3}>
              <ContactCard
                showAymingLogo
                user={{
                  name: consultant.name,
                  email: consultant.email,
                }}
                roles={['Consultant']}
                handleExclude={() => toggleConsultantExcludeFromEmail(consultant, true)}
              />
            </Col>
          )
        }
      </div>
    </>
  );
}
